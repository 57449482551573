import { createComponentPreviewMapperModel } from '@wix/editor-elements-integrations';
import { composeMapper } from '@wix/editor-elements-preview-utils';
import type { PreviewWrapperProps } from '@wix/editor-elements-types/thunderboltPreview';
import { getSocialCompBlockingLayerProps } from '../../../preview-utils/socialComponents';
import { IResponsivePopupContainerProps } from '../ResponsivePopupContainer.types';
import { props as viewerPropsMapper } from './ResponsivePopupContainer.mapper';

const props = composeMapper<
  PreviewWrapperProps<IResponsivePopupContainerProps>,
  any,
  IResponsivePopupContainerProps
>()(
  viewerPropsMapper,
  { add: ['isSocialInteractionAllowed', 'componentViewMode'] },
  (resolver, deps, carmiData) => {
    const viewerProps = resolver(deps, carmiData);

    const { componentViewMode, isSocialInteractionAllowed } = deps;

    return {
      ...viewerProps,
      previewWrapperProps: {
        ...getSocialCompBlockingLayerProps(
          componentViewMode === 'editor' ? isSocialInteractionAllowed : true,
          false,
        ),
      },
    };
  },
);

export default createComponentPreviewMapperModel({
  props,
});
