import { withCompController } from '@wix/editor-elements-integrations';
import {
  WTwitterTweetMapperProps,
  WTwitterTweetControllerProps,
  IWTwitterTweetProps,
  WTwitterTweetStateRefs,
} from '../WTwitterTweet.types';
import { getSocialUrl } from '../../../core/commons/controllerUtils';

const compController = withCompController<
  WTwitterTweetMapperProps,
  WTwitterTweetControllerProps,
  IWTwitterTweetProps,
  WTwitterTweetStateRefs
>(({ stateValues, mapperProps }) => {
  const {
    urlFormat,
    isMasterPage,
    externalBaseUrl,
    isHttpsEnabled,
    ...restProps
  } = mapperProps;

  const { pageId, relativeUrl, currentUrlWithQueryParams, currentUrl } =
    stateValues;

  const socialUrl = getSocialUrl({
    urlFormat,
    isMasterPage,
    pageId,
    relativeUrl,
    externalBaseUrl,
    isHttpsEnabled,
    currentUrl: isMasterPage ? externalBaseUrl : currentUrlWithQueryParams,
  });

  return {
    ...restProps,
    socialUrl,
    currentUrl,
  };
});

export default compController;
