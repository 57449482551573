import { withCompController } from '@wix/editor-elements-integrations';
import { getValidationControllerProps } from '../../../core/commons/controllerUtils';
import {
  IRadioGroupMapperProps,
  IRadioGroupControllerProps,
  IRadioGroupProps,
} from '../RadioGroup.types';

export default withCompController<
  IRadioGroupMapperProps,
  IRadioGroupControllerProps,
  IRadioGroupProps
>(({ controllerUtils, mapperProps }) => {
  const { setValidityIndication, onValueChange } = getValidationControllerProps(
    controllerUtils.updateProps,
  );

  return {
    ...mapperProps,
    setValidityIndication,
    setValue: onValueChange,
  };
});
