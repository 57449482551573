import type { IComponentPreviewWrapper } from '@wix/editor-elements-types/thunderboltPreview';
import { IScrollableImperativeAPI } from '@wix/thunderbolt-becky-types';
import { ResponsiveContainerProps } from '@wix/thunderbolt-components';
import React, { useRef } from 'react';

/**
 * Return a getter to the element that we can listen to scroll on
 * @param containerProps The props for the resopnsive container
 * @param props The entire props of the component
 * @param customOverflowWrapperGetter An optional function to return an element without
 * the default way
 * @returns Getter to the element that we should listen for scroll
 */
const memoizedOverflowWrapperProvider = (
  containerProps?: ResponsiveContainerProps,
  props?: ScrollableContainerTypes,
  customOverflowWrapperGetter?: (
    props: ScrollableContainerTypes,
  ) => Element | null | undefined,
) => {
  let overFlowWrapper: Element | null | undefined;

  return () => {
    if (!overFlowWrapper) {
      overFlowWrapper = customOverflowWrapperGetter
        ? customOverflowWrapperGetter(props!)
        : document.querySelector(`.${containerProps!.overlowWrapperClassName}`);
    }
    return overFlowWrapper;
  };
};

const isResponsiveContainerProps = (props: ScrollableContainerTypes): boolean =>
  !!props.responsiveContainerProps;

const isClassicContainerProps = (props: ScrollableContainerTypes): boolean =>
  !!props.containerProps;

const getContainerProps = (props: ScrollableContainerTypes) => {
  let containerProps: ResponsiveContainerProps | undefined;

  if (isClassicContainerProps(props)) {
    containerProps = props.containerProps;
  } else if (isResponsiveContainerProps(props)) {
    containerProps = props.responsiveContainerProps;
  }

  return containerProps;
};

export const getScrollApi: (
  overflowWrapperGetter: () => Element | null | undefined,
) => IScrollableImperativeAPI = (
  overflowWrapperGetter: () => Element | null | undefined,
) => ({
  scrollApi: {
    getScrollTop: () => overflowWrapperGetter()?.scrollTop || 0,
    getScrollLeft: () => overflowWrapperGetter()?.scrollLeft || 0,

    scroll: (x: number, y: number) => {
      overflowWrapperGetter()?.scrollTo(x, y);
    },
    scrollBy: (x: number, y: number) => {
      overflowWrapperGetter()?.scrollBy(x, y);
    },
    onScroll: (cb: (event: Event) => void) => {
      overflowWrapperGetter()?.addEventListener('scroll', cb);

      return () => {
        overflowWrapperGetter()?.removeEventListener('scroll', cb);
      };
    },
  },
});

export type ScrollableContainerTypes = {
  containerProps?: ResponsiveContainerProps;
  responsiveContainerProps?: ResponsiveContainerProps;
  ref?: any;
  id: string;
};

export type ScrollAPIOptions = {
  alwaysHasOverflow: boolean;
  overflowWrapperGetter?: (
    props: ScrollableContainerTypes,
  ) => Element | null | undefined;
};

export function withScrollAPIForResponsiveContainer(
  ViewerComponent: React.ComponentType<ScrollableContainerTypes>,
  scrollAPIOptions: ScrollAPIOptions = {
    alwaysHasOverflow: false,
  },
): IComponentPreviewWrapper<
  ScrollableContainerTypes,
  IScrollableImperativeAPI
> {
  return React.forwardRef<IScrollableImperativeAPI, ScrollableContainerTypes>(
    (props: ScrollableContainerTypes, ref) => {
      const containerProps: ResponsiveContainerProps | undefined =
        getContainerProps(props);

      const compRef = useRef<any>();

      React.useImperativeHandle(
        ref,
        () => {
          if (
            (scrollAPIOptions.alwaysHasOverflow &&
              scrollAPIOptions.overflowWrapperGetter) ||
            (containerProps && containerProps.hasOverflow)
          ) {
            return {
              ...compRef.current,
              ...getScrollApi(
                memoizedOverflowWrapperProvider(
                  containerProps,
                  props,
                  scrollAPIOptions.overflowWrapperGetter,
                ),
              ),
            };
          }

          return { ...compRef.current };
        },
        [props, containerProps],
      );

      return <ViewerComponent {...props} ref={compRef} />;
    },
  );
}
