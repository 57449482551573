import { withCompController } from '@wix/editor-elements-integrations';
import type {
  ICollapsibleTextControllerProps,
  ICollapsibleTextMapperProps,
  ICollapsibleTextCompProps,
} from '../CollapsibleText.types';

export default withCompController<
  ICollapsibleTextMapperProps,
  ICollapsibleTextControllerProps,
  ICollapsibleTextCompProps
>(({ mapperProps, controllerUtils }) => {
  return {
    ...mapperProps,
    setIsExpanded: isExpanded => {
      controllerUtils.updateProps({ isExpanded });
    },
  };
});
