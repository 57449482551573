export const PREVIEW_STATE = {
  MENU_TAB: 'menuTabOpened',
  SUBMENU_TAB: 'submenuTabOpened',
  SUBMENUCONTAINER_TAB: 'submenuContainerTabOpened',
  FREEZE_MEGA_MENU: 'freezeMegaMenu',
  UNFREEZE_MEGA_MENU: 'unfreezeMegaMenu',
} as const;

export const PREVIEW_STATE_DELIMETER = '::';

export const Selectors = {
  scrollButton: '.root::scrollButton',
  submenu: '.root::columnsLayout',
};
