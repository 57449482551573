import * as React from 'react';
import classNames from 'classnames';
import { IFacebookLikeBoxProps } from '../FacebookLikeBox.types';
import { TestIds } from '../constants';
import { useFacebookLikeBox } from '../../../providers/useFacebook/useFacebook';
import { getDataAttributes } from '../../../core/commons/utils';
import style from './style/FacebookLikeBox.scss';

const FacebookLikeBox: React.FC<IFacebookLikeBoxProps> = props => {
  const {
    id,
    className,
    href,
    currentHeight,
    width,
    showHeader,
    showStream,
    showFaces,
    language,
    onMouseEnter,
    onMouseLeave,
  } = props;

  const ref = useFacebookLikeBox({ language });

  return (
    <div
      {...getDataAttributes(props)}
      key={href}
      id={id}
      className={classNames(style.container, className)}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      ref={ref}
    >
      <div
        className="fb-page"
        data-href={href}
        data-width={width}
        data-height={currentHeight}
        data-show-posts={showStream}
        data-hide-cover={!showHeader}
        data-show-facepile={showFaces}
        data-adapt-container-width={true}
        data-testid={TestIds.fbPageEl}
      />
    </div>
  );
};

export default FacebookLikeBox;
