import * as React from 'react';
import { IPinterestPinItProps } from '../PinterestPinIt.types';
import { constants } from '../constants';
import { getDataAttributes } from '../../../core/commons/utils';
import pinterestAboveLarge from './assets/pinterestDisabled-above-large.png?resource';
import pinterestAboveSmall from './assets/pinterestDisabled-above-small.png?resource';
import pinterestNoneLarge from './assets/pinterestDisabled-none-large.png?resource';
import pinterestNoneSmall from './assets/pinterestDisabled-none-small.png?resource';
import pinterestHtml from './assets/pinterestPinIt.html?resource';

const pngMap: Record<string, Record<string, string>> = {
  none: {
    small: pinterestNoneSmall,
    large: pinterestNoneLarge,
  },
  above: {
    small: pinterestAboveSmall,
    large: pinterestAboveLarge,
  },
};

const addProtocolIfMissing = (url: string) => {
  const beginsWithProtocol = /^(ftps|ftp|http|https):.*$/.test(url);
  const beginsWithDoubleSlash = /^\/\//.test(url);

  if (beginsWithProtocol) {
    return url;
  } else if (beginsWithDoubleSlash) {
    return `https:${url}`;
  }

  return `https://${url}`;
};

const getIframeParams = (props: IPinterestPinItProps) => {
  return {
    media: addProtocolIfMissing(`${props.staticMediaUrl}/${props.uri}`),
    url: props.socialUrl,
    description: props.description,
    'data-pin-do': 'buttonBookmark',
    'data-pin-config': props.counterPosition,
    'data-pin-color': props.color,
    'data-pin-height': props.size === 'small' ? '20' : '28',
  };
};

const getIframeSrc = (props: IPinterestPinItProps) => {
  const iframeSrc = `${pinterestHtml}?`;

  if (!props.uri || !props.description) {
    const counterPosition: string = pngMap[props.counterPosition]
      ? props.counterPosition
      : 'none';
    const gagPath =
      pngMap[counterPosition][props.size] || pngMap[counterPosition].small;
    return `${iframeSrc}${new URLSearchParams({ gagPath })}`;
  }
  return `${iframeSrc}${new URLSearchParams(getIframeParams(props))}`;
};

const PinterestPinIt: React.FC<IPinterestPinItProps> = props => {
  const { id, width, height, className, onMouseEnter, onMouseLeave } = props;
  const iframeSrc = getIframeSrc(props);

  return (
    <wix-iframe
      id={id}
      {...getDataAttributes(props)}
      className={className}
      title={constants.a11yTitle}
      aria-label={constants.a11yTitle}
      data-src={iframeSrc}
      style={{ width, height }}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <iframe
        title={constants.a11yTitle}
        aria-label={constants.a11yTitle}
        data-src={iframeSrc}
        scrolling="no"
        style={{ width, height }}
      />
    </wix-iframe>
  );
};

export default PinterestPinIt;
