import * as React from 'react';
import { CoBrandingMobileProps } from '../CoBrandingBannerMobile.types';
import { getAllTranslations } from '../utils';
import { TEST_IDS } from '../constants';
import BannerWrapper from '../../CoBrandingBannerDesktop/viewer/BannerWrapper';
import { getDataAttributes } from '../../../../core/commons/utils';
import defaultStyle from './styles/CoBrandingBannerMobile.scss';

const CoBrandingBannerMobile: React.FC<CoBrandingMobileProps> = props => {
  const {
    id = 'WIX_ADS',
    translate,
    brandLogoUrl,
    brandSiteUrl,
    direction,
    className,
    brandName,
    style = defaultStyle,
  } = props;

  const translations = React.useMemo(
    () =>
      getAllTranslations(
        translate,
        brandName === 'Dayful' ? 'dayful' : 'mobile',
      ),
    [brandName, translate],
  );

  const titleTextWithLogo = translations.titleText.replace(
    '{Logo}',
    `<img data-testid="${TEST_IDS.logo}" src="${brandLogoUrl}"/>`,
  );

  return (
    <BannerWrapper
      id={id}
      {...getDataAttributes(props)}
      className={className}
      brandSiteUrl={brandSiteUrl}
      direction={direction}
      style={style}
    >
      <span
        data-testid={TEST_IDS.logoWrapper}
        className={style.logoWrapper}
        dangerouslySetInnerHTML={{ __html: titleTextWithLogo }}
      />
    </BannerWrapper>
  );
};

export default CoBrandingBannerMobile;
