import {
  withCompInfo,
  createComponentMapperModel,
  withStateRefsValues,
} from '@wix/editor-elements-integrations';
import { getLanguage } from '../../WTwitter/common/utils';
import {
  WTwitterTweetDefinition,
  WTwitterTweetMapperProps,
  WTwitterTweetStateRefs,
} from '../WTwitterTweet.types';

export const props = withCompInfo<
  WTwitterTweetMapperProps,
  WTwitterTweetDefinition
>()(
  [
    'compData',
    'compProps',
    'compLayout',
    'language',
    'isMobileView',
    'isMasterPage',
    'externalBaseUrl',
  ],
  ({
    compData: { defaultText, accountToFollow, urlFormat, isHttpsEnabled },
    compProps,
    compLayout,
    language,
    isMobileView,
    isMasterPage,
    externalBaseUrl,
  }) => {
    return {
      defaultText,
      accountToFollow,
      isMobileView,
      lang: getLanguage(compProps.dataLang, language),
      width: compLayout.width || 0,
      height: compLayout.height || 0,
      urlFormat,
      isMasterPage,
      externalBaseUrl,
      isHttpsEnabled,
    };
  },
);

export const stateRefs = withStateRefsValues<keyof WTwitterTweetStateRefs>([
  'pageId',
  'relativeUrl',
  'currentUrlWithQueryParams',
  'currentUrl',
]);

export default createComponentMapperModel({ props, stateRefs });
