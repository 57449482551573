import {
  withCompInfo,
  createComponentPreviewMapperModel,
} from '@wix/editor-elements-integrations';
import {
  MenuContainerDefinition,
  MenuContainerCarmiCssVars,
  MenuContainerCssVars,
} from '../MenuContainer.types';

const css = withCompInfo<
  MenuContainerCssVars,
  MenuContainerDefinition,
  MenuContainerCarmiCssVars
>()([], (_, carmiData) => ({
  ...carmiData,
  '--screen-width': '100vw',
}));

export default createComponentPreviewMapperModel({ css });
