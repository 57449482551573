import { withCompController } from '@wix/editor-elements-integrations';
import {
  IPaginatedGridGalleryControllerProps,
  IPaginatedGridGalleryMapperProps,
  IPaginatedGridGalleryProps,
} from '../PaginatedGridGallery.types';

export default withCompController<
  IPaginatedGridGalleryMapperProps,
  IPaginatedGridGalleryControllerProps,
  IPaginatedGridGalleryProps
>(({ mapperProps, controllerUtils }) => {
  return {
    ...mapperProps,
    setCurrentPage: (pageIndex: number) => {
      controllerUtils.updateProps({
        currentPage: pageIndex,
      });
    },
    setAutoPlay: (autoPlay: boolean) => {
      controllerUtils.updateProps({
        autoPlay,
      });
    },
  };
});
