import React, { useRef } from 'react';
import classnames from 'classnames';

import { ISearchBoxFormProps } from '../searchBoxForm';
import { HAS_CUSTOM_FOCUS_CLASSNAME } from '../../../../core/commons/a11y';
import { SearchInput } from './components/searchInput';
import { SearchSymbol } from './components/searchSymbol';

import style from './SearchBoxLayout.scss';

export type ISearchBoxLayoutProps = Pick<
  ISearchBoxFormProps,
  | 'autocompleteValue'
  | 'changeValue'
  | 'clearButtonLabel'
  | 'clearValue'
  | 'id'
  | 'inputRef'
  | 'isDisabled'
  | 'onAutocomplete'
  | 'onBlur'
  | 'onChange'
  | 'onClick'
  | 'onDblClick'
  | 'onFocus'
  | 'onKeyPress'
  | 'placeholder'
  | 'value'
  | 'isModalSuggestionsShown'
> & {
  searchButtonLabel: string;
};

export const SearchBoxLayout: React.FC<ISearchBoxLayoutProps> = ({
  autocompleteValue,
  changeValue,
  clearButtonLabel,
  clearValue,
  id,
  inputRef,
  isDisabled,
  onAutocomplete,
  onBlur,
  onChange,
  onClick,
  onDblClick,
  onFocus,
  onKeyPress,
  placeholder,
  searchButtonLabel,
  value,
  isModalSuggestionsShown,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);

  return (
    <div className={style.container} ref={containerRef}>
      <div className={style.iconSpacer} />
      <div className={style.icon} data-testid="search-icon">
        <SearchSymbol />
      </div>
      <SearchInput
        containerRef={containerRef}
        clearButtonLabel={clearButtonLabel}
        isDisabled={isDisabled}
        id={id}
        inputRef={inputRef}
        onBlur={onBlur}
        clearValue={clearValue}
        onClick={onClick}
        onDblClick={onDblClick}
        onFocus={onFocus}
        onChange={onChange}
        onKeyPress={onKeyPress}
        onAutocomplete={onAutocomplete}
        placeholder={placeholder}
        autocompleteValue={autocompleteValue}
        changeValue={changeValue}
        value={value}
        isModalSuggestionsShown={isModalSuggestionsShown}
      />
      <button
        aria-label={searchButtonLabel}
        className={classnames(style.button, HAS_CUSTOM_FOCUS_CLASSNAME)}
        onClick={e => {
          e.stopPropagation();
        }}
        type="submit"
      >
        <SearchSymbol />
      </button>
    </div>
  );
};
