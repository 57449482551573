export const TestHooks = {
  player: 'player',
  coverLayout: 'coverLayout',
  playLayout: 'playLayout',
  titleLayout: 'titleLayout',
  progressLayout: 'progressLayout',
  artistName: 'artistName',
  trackName: 'trackName',
  cover: 'cover',
  play: 'play',
  progressBar: 'progressBar',
  timeStamp: 'timeStamp',
} as const;
