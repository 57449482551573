import type { Language } from '@wix/thunderbolt-becky-types';
import {
  withCompInfo,
  createComponentMapperModel,
  withStateRefsValues,
} from '@wix/editor-elements-integrations';
import {
  PayPalButtonDefinition,
  PayPalButtonMapperProps,
  PayPalButtonStateRefs,
} from '../PayPalButton.types';
import { BUY_BUTTON_LABEL, DONATE_BUTTON_LABEL, LOCALES } from '../constants';

const getLocale = (propLang: string, userLang: Language) => {
  const lang = (propLang === 'userLang' ? userLang : propLang).toLowerCase();
  return LOCALES[lang] || LOCALES.en;
};

const getParamsBasedOnButtonType = (
  compProps: PayPalButtonDefinition['property'],
) => {
  return {
    buy: {
      cmdType: '_xclick',
      buildNotation: 'Wix_BuyNow_WPS_IL',
      itemName: compProps.itemName,
      itemNumber: compProps.itemID,
      submitImageAriaLabel: BUY_BUTTON_LABEL,
    },
    donate: {
      cmdType: '_donations',
      buildNotation: 'Wix_Donate_WPS_IL',
      itemName: compProps.organizationName,
      itemNumber: compProps.organizationID,
      submitImageAriaLabel: DONATE_BUTTON_LABEL,
    },
  }[compProps.buttonType];
};

const getAmount = (amount: string) => {
  if (amount && Number(amount) > 0) {
    return amount;
  }
  return '';
};

const getSubmitImgSrc = (
  locale: { image: string; cc: string; pixel: string },
  compProps: PayPalButtonDefinition['property'],
) => {
  const buttonType = compProps.buttonType === 'buy' ? 'buynow' : 'donate';
  const showCreditCards =
    compProps.showCreditCards && !compProps.smallButton ? 'CC' : '';
  const buttonSize = compProps.smallButton ? '_SM' : '_LG';
  const localeString =
    compProps.showCreditCards && !compProps.smallButton
      ? locale.image + locale.cc
      : locale.image;
  return `https://www.paypalobjects.com/${localeString}/i/btn/btn_${buttonType}${showCreditCards}${buttonSize}.gif`;
};

export const props = withCompInfo<
  PayPalButtonMapperProps,
  PayPalButtonDefinition
>()(
  ['compData', 'compProps', 'language', 'metaSiteId'],
  ({ compData, compProps, language, metaSiteId }) => {
    const locale = getLocale(compProps.language, language);
    const {
      cmdType,
      buildNotation,
      itemName,
      itemNumber,
      submitImageAriaLabel,
    } = getParamsBasedOnButtonType(compProps);

    return {
      formTarget: compProps.target,
      cmdType,
      itemName,
      itemNumber,
      buildNotation,
      business: compData.merchantID,
      currencyCode: compProps.currencyCode,
      amount: getAmount(compProps.amount),
      trackingPixelImgSrc: `https://www.paypalobjects.com/${locale.pixel}/i/scr/pixel.gif`,
      submitImageSrc: getSubmitImgSrc(locale, compProps),
      submitImageAriaLabel,
      metaSiteId,
    };
  },
);

export const stateRefs = withStateRefsValues<keyof PayPalButtonStateRefs>([
  'getVisitorId',
  'userId',
  'currentUrlWithQueryParams',
]);

export default createComponentMapperModel({ props, stateRefs });
