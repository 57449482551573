import {
  SliderTypes,
  StepValueTypes,
  DirectionTypes,
  MarkTicksTypes,
  IRangeSliderProps,
} from './RangeSlider.types';

export const continuousStepValue = 0.1;

export const ariaLabels = {
  ariaNamespace: 'RangeSlider',
  ariaDefaultMinValueLabel: 'Select minimum value',
  ariaDefaultMaxValueLabel: 'Select maximum value',
};

export const helpIds = {
  gfpp: '29c06294-9150-4622-b43f-266e24c70a00',
  settingsPanel: '2c54321d-26ed-4570-bf82-bb1ee5182e56',
  layoutPanel: 'b0de68dc-90c7-4032-8609-1aa35dc50b57',
};

export const TranslationKeys = {
  layoutPanel: {
    thumbShapeKey: 'slider_layout_handle_shapes_label',
    thumbSizeKey: 'slider_layout_handle_size_label',
    railSizeKey: 'slider_layout_track_size_label',
    directionKey: 'wixui_RangeSlider_layout_direction_label',
    titleFieldDividerKey: 'wixui_RangeSlider_layout_fieldTitleDivider_label',
    titleAlignmentKey: 'wixui_RangeSlider_layout_titleAlignment_label',
    titleOffsetKey: 'wixui_RangeSlider_layout_titleOffset_label',
    titleSpaceKey: 'wixui_RangeSlider_layout_titleSpaceToField_label',
    tickMarkTitleKey: 'tickMarksPositionTitle',
    stepSectionTitleKey: 'slider_layout_steps_section_title',
    stepShapeKey: 'slider_layout_steps_show_label',
  },
  settingsPanel: {
    stepValueKey: 'slider_settings_set_steps_value_step_value_label',
    stepNumberKey: 'slider_settings_set_steps_number_number_of_steps_label',
    titleFieldLabelKey: 'wixui_RangeSlider_label_inputSingleLine_label',
    titleFieldPlaceholderKey:
      'wixui_RangeSlider_label_inputSingleLine_placeholder',
    sliderTypeTooltipKey: 'slider_settings_slider_type_tooltip',
    sliderTypeLabelKey: 'slider_settings_slider_type_label',
    stepTooltipKey: 'slider_settings_set_steps_tooltip',
    stepLabelKey: 'slider_settings_set_steps_label',
    rangeSectionTitleKey: 'slider_settings_value_range_section_title',
    rangeMinimumKey: 'slider_settings_value_range_minimum_value_label',
    rangeMaximumKey: 'slider_settings_value_range_maximum_value_label',
    selectedMinimumKey: 'wixui_RangeSlider_minValue_numericInput_label',
    selectedMaximumKey: 'wixui_RangeSlider_maxValue_numericInput_label',
    tooltipSectionKey: 'slider_range_settings_tooltip_section_title',
    prefixInfoKey: 'Slider_Layout_Tooltip_Prefix_Info',
    prefixLabelKey: 'slider_layout_value_label_prefix_label',
    prefixPlaceholderKey: 'Slider_layout_value_label_prefix_placeholder',
    suffixInfoKey: 'Slider_Layout_Tooltip_Suffix_Info',
    suffixLabelKey: 'slider_layout_value_label_suffix_label',
    suffixPlaceholderKey: 'slider_layout_value_label_suffix_placeholder',
    inputValuePlaceholderKey: 'Input_Controls_Value_Placeholder',
  },
  stylablePanel: {
    labelKey: 'wixui_RangeSlider_fieldTitle_designPanel_dropdown_label',
    handlesKey: 'wixui_RangeSlider_handles_designPanel_dropdown_label',
    stepMarksKey: 'wixui_RangeSlider_stepes_designPanel_dropdown_label',
    railKey: 'wixui_RangeSlider_rail_designPanel_dropdown_label',
    trackKey: 'wixui_RangeSlider_selectedRange_designPanel_dropdown_label',
  },
  minValueAriaLabel: 'slider_range_min_input_value_aria_label',
  maxValueAriaLabel: 'slider_range_max_input_value_aria_label',
};

export const TestIds = {
  settingsPanel: {
    root: 'settingsPanelRoot',
    labelInput: 'settingsLabelInput',
    sliderTypeThumbnails: 'settingsSliderTypeThumbnails',
    stepTypeDropDown: 'settingsStepTypeDropDown',
    stepValueNumInput: 'settingsStepValueNumInput',
    minNumInput: 'settingsMinNumInput',
    maxNumInput: 'settingsMaxNumInput',
    minValueNumInput: 'settingsMinValueNumInput',
    maxValueNumInput: 'settingsMaxValueNumInput',
    prefixTextInput: 'settingsPrefixTextInput',
    suffixTextInput: 'settingsSuffixTextInput',
    readOnlyCheckbox: 'settingsPanelReadOnlyCheckbox',
  },
  layoutPanel: {
    root: 'layoutPanelRoot',
    thumbShapeThumbnails: 'layoutThumbShapeThumbnails',
    directionThumbnails: 'layoutDirectionThumbnails',
    markTicksShapeThumbnails: 'layoutMarkTicksShapeThumbnails',
    thumbSizeSlider: 'layoutThumbSizeSlider',
    railPercentageSlider: 'layoutRailPercentageSlider',
    labelOffsetSlider: 'layoutLabelOffsetSlider',
    labelPaddingSlider: 'layoutLabelPaddingSlider',
    labelAlignmentThumbnails: 'layoutLabelAlignmentThumbnails',
  },
  baseSlider: 'base-slider',
};

export const compDataConsts: Omit<
  IRangeSliderProps,
  | 'thumbSize'
  | 'thumbShape'
  | 'onChange'
  | 'updateComponentPropsInViewer'
  | 'width'
  | 'isDisabled'
> = {
  id: 'RangeSlider1',
  label: 'Range Slider',
  readOnly: false,
  direction: DirectionTypes.ltr,
  marksTickShape: MarkTicksTypes.Lines,
  sliderType: SliderTypes.Continuous,
  min: 0,
  max: 100,
  stepType: StepValueTypes.Number,
  step: 10,
  prefix: '',
  suffix: '',
  minValue: 10,
  maxValue: 90,
};

export const compDataAndPropsConsts: Omit<
  IRangeSliderProps,
  'onChange' | 'updateComponentPropsInViewer'
> = {
  ...compDataConsts,
  isDisabled: false,
  width: 354,
};

export const Selectors = {
  root: '.root',
  label: '.root::label',
  thumb: '.root::sliderThumb',
};
export const CssLabels = {
  labelAlignment: 'label-alignment',
  railPercentage: 'rail-percentage',
  labelBottomPadding: 'label-bottom-padding',
  labelLeftPadding: 'label-left-padding',
  labelRightPadding: 'label-right-padding',
  thumbHeight: 'thumbHeight',
  thumbWidth: 'thumbWidth',
};
export const CssPrefixes = {
  globalPrefix: '--',
  scopedPrefix: '--RangeSlider-',
};

export const CssVariables = {
  global: {
    labelAlignment: [`${CssPrefixes.globalPrefix}${CssLabels.labelAlignment}`],
    railPercentage: [`${CssPrefixes.globalPrefix}${CssLabels.railPercentage}`],
    labelBottomPadding: [
      `${CssPrefixes.globalPrefix}${CssLabels.labelBottomPadding}`,
    ],
    labelLeftPadding: [
      `${CssPrefixes.globalPrefix}${CssLabels.labelLeftPadding}`,
    ],
    labelRightPadding: [
      `${CssPrefixes.globalPrefix}${CssLabels.labelRightPadding}`,
    ],
    thumbHeight: [`${CssPrefixes.globalPrefix}${CssLabels.thumbHeight}`],
    thumbWidth: [`${CssPrefixes.globalPrefix}${CssLabels.thumbWidth}`],
  },
  scoped: {
    labelAlignment: [`${CssPrefixes.scopedPrefix}${CssLabels.labelAlignment}`],
    railPercentage: [`${CssPrefixes.scopedPrefix}${CssLabels.railPercentage}`],
    labelBottomPadding: [
      `${CssPrefixes.scopedPrefix}${CssLabels.labelBottomPadding}`,
    ],
    labelLeftPadding: [
      `${CssPrefixes.scopedPrefix}${CssLabels.labelLeftPadding}`,
    ],
    labelRightPadding: [
      `${CssPrefixes.scopedPrefix}${CssLabels.labelRightPadding}`,
    ],
    thumbHeight: [`${CssPrefixes.scopedPrefix}${CssLabels.thumbHeight}`],
    thumbWidth: [`${CssPrefixes.scopedPrefix}${CssLabels.thumbWidth}`],
  },
};
