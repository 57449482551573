import type {
  CompDefinition,
  Component,
  StyleProperties,
} from '@wix/editor-elements-types/thunderbolt';

type AnyCompDef = CompDefinition<any, any, any, any, any, any>;

export const getInputHeightInPx = <T extends AnyCompDef>({
  compProps,
  compLayout,
  isMobileView,
}: {
  compProps: T['property'];
  compLayout: Component['layout'];
  isMobileView: boolean;
}) => {
  const { inputHeightMobile, inputHeight } = compProps;
  const { height } = compLayout;
  const actualHeight = isMobileView
    ? inputHeightMobile || inputHeight || height
    : inputHeight || height;
  return `${actualHeight}px`;
};

const labelPaddingLimit = 20;

export const getLabelPadding = <T extends AnyCompDef>(
  compProps: T['property'],
): string | undefined => {
  const { labelPadding, textAlignment, filesAlignment, alignment } = compProps;
  const align = textAlignment || filesAlignment || alignment;
  switch (align) {
    case 'left':
      return `0 ${labelPaddingLimit}px 0 ${labelPadding}px`;
    case 'right':
      return `0 ${labelPadding}px 0 ${labelPaddingLimit}px`;
    default:
      return undefined;
  }
};

export const getRequiredIndicationDisplay = (
  styleProperties: StyleProperties,
): 'none' | 'inline' => {
  const { txtlblrq } = styleProperties;
  return !txtlblrq || txtlblrq === 'transparent' ? 'none' : 'inline';
};
