import {
  withCompInfo,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';
import {
  SpotifyPlayerDefinition,
  SpotifyPlayerProps,
  SpotifyPlayerCSSVars,
  SpotifyPlayerSize,
} from '../SpotifyPlayer.types';

export const props = withCompInfo<
  SpotifyPlayerProps,
  SpotifyPlayerDefinition
>()(
  ['compData', 'compProps', 'compLayout'],
  ({ compData, compProps, compLayout }) => {
    const sizeLimits: Record<
      SpotifyPlayerSize,
      Record<'minW' | 'minH', number>
    > = {
      compact: { minW: 250, minH: 80 },
      large: { minW: 250, minH: 330 },
    };

    let iframeSrc = null;
    const width = compLayout.width || 0;
    let height = sizeLimits[compProps.size].minH;

    if (compProps.size === 'large') {
      height += width - sizeLimits[compProps.size].minW;
    }

    const { uri } = compData;
    const { color, style } = compProps;
    if (uri !== '') {
      iframeSrc = `https://embed.spotify.com/?${new URLSearchParams({
        uri,
        color,
        style,
      })}`;
    }

    return { iframeSrc, width, height };
  },
);

export const css = withCompInfo<
  SpotifyPlayerCSSVars,
  SpotifyPlayerDefinition
>()(['compData'], ({ compData }) => {
  let placeholderDisplay = 'none';

  if (compData.uri === '') {
    placeholderDisplay = 'block';
  }

  return {
    '--placeholder-display': placeholderDisplay,
  };
});

export default createComponentMapperModel({ props, css });
