import {
  createComponentPreviewMapperModel,
  withCompPreviewProps,
} from '@wix/editor-elements-integrations';
import type { PreviewWrapperProps } from '@wix/editor-elements-types/thunderboltPreview';
import {
  WFacebookCommentDefinition,
  WFacebookCommentPlatformProps,
} from '../WFacebookComment.types';
import { getSocialCompBlockingLayerProps } from '../../../preview-utils/socialComponents';
import { getLanguage } from '../common/utils';

const props = withCompPreviewProps<
  PreviewWrapperProps<WFacebookCommentPlatformProps>,
  WFacebookCommentDefinition
>()(
  [
    'componentViewMode',
    'isSocialInteractionAllowed',
    'compProps',
    'isMobileView',
    'language',
  ],
  ({
    componentViewMode,
    isSocialInteractionAllowed,
    compProps,
    isMobileView,
    language,
  }) => {
    return {
      ...compProps,
      width: isMobileView ? 320 : compProps.width,
      language: getLanguage(language),
      previewWrapperProps: {
        componentViewMode,
        ...getSocialCompBlockingLayerProps(isSocialInteractionAllowed),
      },
    };
  },
);

export default createComponentPreviewMapperModel({
  props,
});
