export const TestIds = {
  backToSiteButton: 'backToSiteBtn',
  switchAccountLinkBtn: 'switchAccountLinkBtn',
} as const;

export const NoPermissionsToPageTranslations = {
  NAMESPACE: 'SM',
  TITLE_KEY: 'SMNoPermissions',
  TITLE_DEFAULT: "You don't have permissions to access this page.",
  BACK_TO_SITE_BTN_KEY: 'SMWelcome_Back',
  BACK_TO_SITE_BTN_DEFAULT: 'Back to site',
  SWITCH_ACCOUNT_LINK_KEY: 'SMSwitchAccount',
  SWITCH_ACCOUNT_LINK_DEFAULT: 'Switch Account',
};
