import * as React from 'react';
import { ILineShareButtonProps } from '../LineShareButton.types';
import { getScriptOrLoad } from '../../../providers/ScriptLoader/ScriptLoader';
import { getDataAttributes } from '../../../core/commons/utils';

const LineShareButton: React.FC<ILineShareButtonProps> = props => {
  const { id, className, language, buttonType, currentUrl } = props;

  React.useEffect(() => {
    getScriptOrLoad('lineShare').then(sdk => {
      sdk.loadButton();
    });
  }, [language, buttonType]);

  return (
    <div
      id={id}
      {...getDataAttributes(props)}
      className={className}
      key={`${buttonType}-${language}`}
    >
      <div
        className="line-it-button"
        data-lang={language}
        data-type={buttonType}
        data-ver="2"
        data-url={currentUrl}
        style={{ display: 'none' }}
      ></div>
    </div>
  );
};

export default LineShareButton;
