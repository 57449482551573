import {
  withCompInfo,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';
import { getCompStyles } from '../../../tpaGallery';
import { TPAGallerySdkData } from '../../../tpaGallery/types';
import {
  HoneycombDefinition,
  HoneycombPlatformProps,
  HoneycombCarmiData,
  HoneycombCSSVars,
} from '../Honeycomb.types';
import {
  propsDependencies,
  propsResolver,
} from '../../TPAGallery/viewer/TPAGalleryMapperUtil';

const compStyleKeys = {
  textColor: 'color1',
  descriptionColor: 'color2',
  textBackgroundColor: 'color3',
  backgroundMouseoverColor: 'color4',
  holesColor: 'color5',
};

export const props = withCompInfo<
  HoneycombPlatformProps,
  HoneycombDefinition,
  HoneycombCarmiData
>()(
  [...propsDependencies, 'styleProperties', 'siteColors'],
  (data, carmiProps) => ({
    ...propsResolver<HoneycombDefinition>(
      data,
      carmiProps,
      'Honeycomb/Honeycomb.html',
    ),
    compStyles: getCompStyles(
      compStyleKeys,
      data.styleProperties,
      data.siteColors,
    ),
  }),
);

const sdkData = withCompInfo<TPAGallerySdkData, HoneycombDefinition>()(
  ['compData'],
  ({ compData }) => {
    return {
      dataId: compData.id,
    };
  },
);

const css = withCompInfo<HoneycombCSSVars, HoneycombDefinition>()(
  ['compLayout'],
  ({ compLayout }) => ({
    '--height': `${compLayout.height}px`,
  }),
);

export default createComponentMapperModel({ css, props, sdkData });
