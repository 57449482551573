import React, { useContext } from 'react';
import classnames from 'classnames';
import { ToolbarChildrenProps } from 'draft-js-inline-toolbar-plugin';
import LinkModal from '../../../../../../RichTextBoxLinkModal/viewer/RichTextBoxLinkModal';
import styles from '../../button.scss';
import LinkIcon from '../LinkIcon';
import { hasEntity } from '../../../../utils/draftUtils';
import StylesContext from '../../../../StylesContext';

export default (props: ToolbarChildrenProps) => {
  const { setEditorState, getEditorState, onOverrideContent } = props;
  const stylesContext = useContext(StylesContext);

  const onClose = () => {
    // @ts-expect-error
    onOverrideContent(undefined);
  };

  const openLinkModal = () => {
    const content = () => (
      <LinkModal
        getEditorState={getEditorState}
        setEditorState={setEditorState}
        onClose={onClose}
      />
    );
    onOverrideContent(content);
  };

  const preventBubblingUp = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const selected = hasEntity(getEditorState(), 'LINK');
  return (
    <div className={styles.buttonContainer}>
      <button
        className={classnames(styles.button, {
          [styles.hovered]: stylesContext.hovered,
          [styles.focused]: stylesContext.focused,
          [styles.error]: stylesContext.error,
        })}
        onMouseDown={preventBubblingUp}
        onClick={openLinkModal}
        type="button"
      >
        <div
          className={classnames(
            styles.buttonContent,
            selected && styles.buttonContentSelected,
          )}
        >
          <LinkIcon />
        </div>
      </button>
    </div>
  );
};
