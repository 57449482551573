import * as React from 'react';
import classNames from 'classnames';
import { LoginButtonProps } from '../LoginButton.types';
import { getDataAttributes } from '../../../core/commons/utils';
import style from './style/LoginButton.scss';
import { translationFeature, LoginButtonKeys, TestIds } from './constants';

const LoginButton: React.FC<LoginButtonProps> = props => {
  const {
    id,
    className,
    isLoggedIn,
    userName,
    onLogin,
    onLogout,
    translate,
    onMouseEnter,
    onMouseLeave,
  } = props;

  const handleClick = (event: React.MouseEvent<Element, MouseEvent>) => {
    if (isLoggedIn) {
      onLogout(event);
    } else {
      onLogin();
    }
  };

  const actionKey = isLoggedIn
    ? LoginButtonKeys.signOut
    : LoginButtonKeys.signIn;
  const actionTitle = translate!(translationFeature, actionKey, actionKey);
  const memberTitle = `${translate!(
    translationFeature,
    LoginButtonKeys.hello,
    LoginButtonKeys.hello,
  )} ${userName}`;

  return (
    <div
      id={id}
      {...getDataAttributes(props)}
      className={classNames(className, style.root, {
        [style.hidden]: isLoggedIn && !userName.length,
      })}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {isLoggedIn && (
        <div className={style.memberTitle} data-testid={TestIds.memberTitle}>
          {userName ? memberTitle : ''}
        </div>
      )}
      <button
        className={style.actionTitle}
        onClick={handleClick}
        data-testid={TestIds.actionTitle}
      >
        {actionTitle}
      </button>
    </div>
  );
};

export default LoginButton;
