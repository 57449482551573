export const CONTINUOUS_STEP = 0.01;

export const TestIds = {
  root: 'root',
  track: 'track',
  ticksWrapper: 'ticks-wrapper',
  tick: 'tick',
  tooltip: 'tooltip',
  thumb: 'thumb',
} as const;

export const Sizes = {
  tickLineHeight: 10,
  tickDotSize: 3,
  tickMarksGap: 12,
  tickLineWidth: 1,
} as const;
