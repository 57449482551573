import classNames from 'classnames';
import React, { ComponentType } from 'react';
import { MenuToggleProps } from '../../MenuToggle.types';
import styles from './inlinePopupToggleSkin1.scss';

type InlinePopupProps = Pick<MenuToggleProps, 'isOpen'>;

const InlinePopupToggleSkin1: ComponentType<InlinePopupProps> = ({
  isOpen,
}) => {
  return (
    <span
      className={classNames(styles.toggleButton, isOpen && styles.toggleOpen)}
    />
  );
};

export default InlinePopupToggleSkin1;
