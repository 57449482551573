export const DEFAULT_SHAPE_SIZE = 25;
export const DEFAULT_SHAPE_SPACING = 8;

export const experimentNames = {
  INPUT_ELEMENTS_A11Y: 'sv_inputElementsA11y',
};

export const TestIds = {
  ratingsIcon: 'ratingsIcon',
  a11yLabel: 'a11yLabel',
} as const;

export const DataHooks = {
  ratingInputTitle: 'rating-input-title',
} as const;
