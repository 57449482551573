import React, { ReactNode, useContext } from 'react';
import classnames from 'classnames';
import { DraftBlockType, RichUtils } from 'draft-js';
import { ToolbarChildrenProps } from 'draft-js-inline-toolbar-plugin';
import styles from '../button.scss';
import StylesContext from '../../../StylesContext';

export type BlockStyleButtonProps = ToolbarChildrenProps & {
  children: ReactNode;
  blockType: DraftBlockType;
  dataTestId?: string;
};
export default (props: BlockStyleButtonProps) => {
  const { blockType, children, setEditorState, getEditorState, dataTestId } =
    props;
  const stylesContext = useContext(StylesContext);

  const toggleStyle = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setEditorState(RichUtils.toggleBlockType(getEditorState(), blockType));
  };

  const preventBubblingUp = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const selected =
    RichUtils.getCurrentBlockType(getEditorState()) === blockType;

  return (
    <div className={styles.buttonContainer}>
      <button
        onMouseDown={preventBubblingUp}
        className={classnames(styles.button, {
          [styles.hovered]: stylesContext.hovered,
          [styles.focused]: stylesContext.focused,
          [styles.error]: stylesContext.error,
        })}
        onClick={toggleStyle}
        data-testid={dataTestId}
        type="button"
      >
        <div
          className={classnames(
            styles.buttonContent,
            selected && styles.buttonContentSelected,
          )}
        >
          {children}
        </div>
      </button>
    </div>
  );
};
