import { createComponentPreviewMapperModel } from '@wix/editor-elements-integrations';
import { composeMapper } from '@wix/editor-elements-preview-utils';
import type { PreviewWrapperProps } from '@wix/editor-elements-types/thunderboltPreview';
import { getSocialCompBlockingLayerProps } from '../../../preview-utils/socialComponents';
import {
  ItunesButtonDefinition,
  ItunesButtonPlatformProps,
} from '../ItunesButton.types';
import { props as viewerPropsMapper } from './ItunesButton.mapper';

const props = composeMapper<
  PreviewWrapperProps<ItunesButtonPlatformProps>,
  ItunesButtonDefinition,
  ItunesButtonPlatformProps
>()(
  viewerPropsMapper,
  { add: ['isSocialInteractionAllowed'] },
  (resolver, deps, carmiData) => {
    const viewerProps = resolver(deps, carmiData);

    return {
      ...viewerProps,
      previewWrapperProps: {
        ...getSocialCompBlockingLayerProps(deps.isSocialInteractionAllowed),
      },
    };
  },
);

export default createComponentPreviewMapperModel({
  props,
});
