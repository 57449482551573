import type {
  IStylableHorizontalMenuUITypeProps,
  ISubmenuProps,
} from '../../../../StylableHorizontalMenu.types';
import { getClasses_flyout } from '../styles/getClasses_flyout';
import { depth0PositionUpdaters_flyout } from '../positionUpdaters/itemDepth0_flyout';
import { depth1PositionUpdaters_flyout } from '../positionUpdaters/itemDepth1_flyout';
import { createInjectCssVars_flyout } from '../styles/injectCssVars_flyout';

export const createSubmenuProps_flyout = ({
  stylableClassName: className,
}: IStylableHorizontalMenuUITypeProps): ISubmenuProps => ({
  flyout: {
    positionUpdaters: [
      depth0PositionUpdaters_flyout,
      depth1PositionUpdaters_flyout,
    ],
    getClasses: getClasses_flyout,
    injectCssVars: createInjectCssVars_flyout(className),
  },
});
