import { createComponentPreviewMapperModel } from '@wix/editor-elements-integrations';
import { composeMapper } from '@wix/editor-elements-preview-utils';
import { RSSButtonDefinition } from '@wix/thunderbolt-components-native';
import type { DefaultCompPlatformProps } from '@wix/editor-elements-types/thunderbolt';
import type { PreviewWrapperProps } from '@wix/editor-elements-types/thunderboltPreview';
import { RSSButtonPropsCarmiData } from '@wix/thunderbolt-components';

import { props as viewerPropsMapper } from './RSSButton.mapper';

const props = composeMapper<
  PreviewWrapperProps<DefaultCompPlatformProps>,
  RSSButtonDefinition,
  RSSButtonPropsCarmiData
>()(viewerPropsMapper, {}, (resolver, deps, carmiData) => {
  const viewerProps = resolver(deps, carmiData);
  return {
    ...viewerProps,
    link: {
      ...viewerProps.link,
      href: `https://editor.wix.com/feed.xml`,
    },
  };
});

export default createComponentPreviewMapperModel({ props });
