import * as React from 'react';
import classNames from 'classnames';
import {
  ISlideShowGalleryImperativeActions,
  ISlideShowGalleryProps,
} from '../../../SlideShowGallery.types';
import SlideShowGallery from '../../SlideShowGallery';
import skinsItemStyle from '../../../../SlideShowGalleryItem/viewer/style/SlideShowIronItem.scss';
import skinsStyle from './styles/SlideShowIron.scss';

type IOverlayProps = {
  showNavigationBtnsOverlay: boolean;
};

const Overlay: React.FC<IOverlayProps> = ({ showNavigationBtnsOverlay }) => (
  <div className={skinsStyle.overlay}>
    <div className={skinsStyle.border} />
    {showNavigationBtnsOverlay && (
      <div
        className={classNames(
          skinsStyle.screwBtn_TL,
          skinsStyle.screwBtn,
          skinsStyle.screw,
        )}
      />
    )}
    {showNavigationBtnsOverlay && (
      <div
        className={classNames(
          skinsStyle.screwBtn_BL,
          skinsStyle.screwBtn,
          skinsStyle.screw,
        )}
      />
    )}
    {showNavigationBtnsOverlay && (
      <div
        className={classNames(
          skinsStyle.screwBtn_TR,
          skinsStyle.screwBtn,
          skinsStyle.screw,
        )}
      />
    )}
    {showNavigationBtnsOverlay && (
      <div
        className={classNames(
          skinsStyle.screwBtn_BR,
          skinsStyle.screwBtn,
          skinsStyle.screw,
        )}
      />
    )}
  </div>
);

const SlideShowIron: React.ForwardRefRenderFunction<
  ISlideShowGalleryImperativeActions,
  Omit<ISlideShowGalleryProps, 'skin'>
> = (props, ref) => (
  <SlideShowGallery
    ref={ref}
    {...props}
    skinsStyle={skinsStyle}
    skinsItemStyle={skinsItemStyle}
    overlay={<Overlay showNavigationBtnsOverlay={props.showNavigation} />}
  />
);

export default React.forwardRef(SlideShowIron);
