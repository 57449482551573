import { withCompController } from '@wix/editor-elements-integrations';
import { ITinyMenuControllerActions, ITinyMenuProps } from '../TinyMenu.types';

export default withCompController<
  Record<string, any>,
  ITinyMenuControllerActions,
  ITinyMenuProps
>(({ controllerUtils, mapperProps }) => {
  return {
    ...mapperProps,
    setIsOpen: isOpen => {
      controllerUtils.updateProps({ isOpen });
    },
  };
});
