import {
  withCompInfo,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';
import {
  SiteButtonDefinition,
  SiteButtonProps,
} from '@wix/thunderbolt-components';

export const props = withCompInfo<
  SiteButtonProps,
  SiteButtonDefinition,
  SiteButtonProps
>()(
  ['isQaMode', 'fullNameCompType'],
  ({ isQaMode, fullNameCompType }, carmiData) => {
    return {
      ...carmiData,
      isQaMode,
      fullNameCompType,
    };
  },
);

export default createComponentMapperModel({ props });
