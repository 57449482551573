import { withCompController } from '@wix/editor-elements-integrations';
import {
  IQuickActionBarControllerProps,
  IQuickActionBarMapperProps,
  IQuickActionBarProps,
  IQuickActionBarStateValues,
} from '../QuickActionBar.types';

export default withCompController<
  IQuickActionBarMapperProps,
  IQuickActionBarControllerProps,
  IQuickActionBarProps,
  IQuickActionBarStateValues
>(({ mapperProps, controllerUtils, stateValues }) => {
  const { trackEvent } = stateValues;
  const { updateProps } = controllerUtils;

  return {
    ...mapperProps,
    onTrackEvent(eventName: string, params: Record<string, string>) {
      if (trackEvent) {
        trackEvent({ eventName, params });
      }
    },
    openActionBar: () => {
      updateProps({
        isOpen: true,
      });
    },
    closeActionBar: () => {
      updateProps({
        isOpen: false,
      });
    },
  };
});
