import * as React from 'react';
import classNames from 'classnames';
import SiteButtonContent from '../../SiteButtonContent';
import { SkinButtonProps } from '../SkinButton.types';
import SiteButton from '../../SiteButton';
import {
  ISiteButtonImperativeActions,
  ISiteButtonProps,
} from '../../../SiteButton.types';
import skinsStyle from './styles/skins.scss';

const SloopyButtonSkin = React.forwardRef<
  ISiteButtonImperativeActions,
  SkinButtonProps
>(
  (
    { wrapperProps, linkProps, a11yProps, elementType, label, onFocus, onBlur },
    ref,
  ) => {
    return (
      <div
        {...wrapperProps}
        className={classNames(skinsStyle.SloopyButton, wrapperProps.className)}
      >
        <div className={`${skinsStyle.brd} ${skinsStyle.top}`} />
        <div className={`${skinsStyle.brd} ${skinsStyle.bot}`} />
        <SiteButtonContent
          linkProps={linkProps}
          a11yProps={a11yProps}
          elementType={elementType}
          className={skinsStyle.link}
          onFocus={onFocus}
          onBlur={onBlur}
          ref={ref}
        >
          <span className={skinsStyle.label}>{label}</span>
        </SiteButtonContent>
      </div>
    );
  },
);

const SloopyButton: React.ForwardRefRenderFunction<
  ISiteButtonImperativeActions,
  Omit<ISiteButtonProps, 'skin'>
> = (props, ref) => <SiteButton {...props} skin={SloopyButtonSkin} ref={ref} />;

export default React.forwardRef(SloopyButton);
