import { withCompController } from '@wix/editor-elements-integrations/src/thunderbolt';
import type {
  IVideoPlayerControllerProps,
  IVideoPlayerMapperProps,
  IVideoPlayerStateRefValues,
  IVideoPlayerProps,
} from '../VideoPlayer.types';

export default withCompController<
  IVideoPlayerMapperProps,
  IVideoPlayerControllerProps,
  IVideoPlayerProps,
  IVideoPlayerStateRefValues
>(({ stateValues, mapperProps }) => {
  const { reducedMotion } = stateValues;
  return { ...mapperProps, reducedMotion };
});
