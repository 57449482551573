import {
  withCompInfo,
  createComponentMapperModel,
  withStateRefsValues,
} from '@wix/editor-elements-integrations';
import { getLanguage } from '../common/utils';
import {
  WFacebookCommentPlatformProps,
  WFacebookCommentDefinition,
  WFacebookCommentStateRefs,
} from '../WFacebookComment.types';

export const props = withCompInfo<
  WFacebookCommentPlatformProps,
  WFacebookCommentDefinition
>()(
  ['compData', 'compProps', 'isMobileView', 'language', 'externalBaseUrl'],
  ({
    compData: { urlFormat, isHttpsEnabled },
    compProps,
    isMobileView,
    language,
    externalBaseUrl,
  }) => ({
    ...compProps,
    width: isMobileView ? 320 : compProps.width,
    language: getLanguage(language),
    urlFormat,
    externalBaseUrl,
    isHttpsEnabled,
  }),
);

export const css = withCompInfo<any, WFacebookCommentDefinition>()(
  ['hasResponsiveLayout', 'compLayout'],
  ({ hasResponsiveLayout, compLayout: { height } }) =>
    hasResponsiveLayout
      ? undefined
      : {
          height: 'auto',
          'min-height': `${height}px`,
        },
);

export const stateRefs = withStateRefsValues<keyof WFacebookCommentStateRefs>([
  'pageId',
  'relativeUrl',
  'currentUrlWithQueryParams',
]);

export default createComponentMapperModel({ props, css, stateRefs });
