import { getDataAttributes } from '@wix/thunderbolt-elements/commons/utils';
import * as React from 'react';
import { useEffect, useState } from 'react';
import {
  IBreadcrumbsProps,
  IBreadcrumbProps,
  ISeparator,
  IBreadcrumb,
} from '../Breadcrumbs.types';
import {
  canRenderEllipsis,
  getHeadIndex,
  getTailIndex,
} from '../Breadcrumbs.utils';
import { st, classes } from './style/Breadcrumbs.component.st.css';

type Separator = ISeparator | 'smaller-than' | 'reverse-slash';

interface IBreadcrumbItemProps {
  ariaAttributes?: Record<string, any>;
  className: string;
}

const separatorsHash: Record<Separator, string> = {
  'greater-than': '>',
  'smaller-than': '<',
  slash: '/',
  'reverse-slash': '\\',
};

const getAriaCurrentAttribute = (isCurrent?: boolean) =>
  isCurrent
    ? { 'aria-current': 'page' as React.AriaAttributes['aria-current'] }
    : {};

const selectDisplayedSeparator = (
  separator: IBreadcrumbsProps['separator'],
  direction: IBreadcrumbsProps['direction'],
) => {
  if (direction === 'rtl') {
    if (separator === 'greater-than') {
      return 'smaller-than';
    } else {
      return 'reverse-slash';
    }
  }

  return separator;
};

const Icon: React.FC<{ src: string }> = ({ src }) => {
  return React.createElement('span', {
    dangerouslySetInnerHTML: {
      __html: src,
    },
    className: classes.icon,
  });
};

const SeparatorElement: React.FC<{ separator: Separator }> = ({
  separator,
}) => (
  <span aria-hidden="true" className={classes.separator}>
    {separatorsHash[separator] || separator}
  </span>
);

const SpanBreadcrumb: React.FC<
  IBreadcrumbItemProps & { onClick?: () => void }
> = ({ children, ariaAttributes, className, onClick }) => (
  <span className={className} {...ariaAttributes} onClick={onClick}>
    {children}
  </span>
);

const AnchorBreadcrumb: React.FC<
  IBreadcrumbItemProps & {
    url: string;
    label?: string;
  }
> = ({ url, ariaAttributes, className, label, children }) => {
  return (
    <span className={className} {...ariaAttributes}>
      <a href={url} target="_self" title={label} className={classes.anchor}>
        {children}
      </a>
    </span>
  );
};

const Ellipsis: React.FC<{ separator: Separator; onClick: () => void }> = ({
  separator,
  onClick,
}) => {
  const enterKey = 13;
  const spaceKey = 32;

  return (
    <li className={classes.breadcrumb}>
      <SpanBreadcrumb
        className={classes['breadcrumb-content']}
        onClick={onClick}
      >
        <span
          title="Ellipsis"
          className={classes.ellipsis}
          tabIndex={0}
          onKeyDown={ev => {
            if (ev.keyCode === enterKey || ev.keyCode === spaceKey) {
              onClick();
            }
          }}
        >
          ...
        </span>
      </SpanBreadcrumb>
      <SeparatorElement separator={separator} />
    </li>
  );
};

const getIconOrLabel = (label?: string, icon?: string) =>
  icon ? (
    <Icon src={icon} />
  ) : (
    <span tabIndex={0} title={label} className={classes.label}>
      {label}
    </span>
  );

const Breadcrumb: React.FC<
  Omit<IBreadcrumbProps, 'separator'> & {
    separator: Separator;
  }
> = props => {
  const { icon, label, link: url, separator, isCurrent } = props;

  const sharedProps = {
    className: st(classes['breadcrumb-content'], {
      isCurrent: Boolean(isCurrent),
    }),
    ariaAttributes: getAriaCurrentAttribute(isCurrent),
  };

  return (
    <li
      className={st(classes.breadcrumb, icon ? classes['breadcrumb-icon'] : '')}
    >
      {url ? (
        <AnchorBreadcrumb {...sharedProps} url={url} label={label}>
          {getIconOrLabel(label, icon)}
        </AnchorBreadcrumb>
      ) : (
        <SpanBreadcrumb {...sharedProps}>
          {getIconOrLabel(label, icon)}
        </SpanBreadcrumb>
      )}
      <SeparatorElement separator={separator} />
    </li>
  );
};

const PreviousPage: React.FC<{
  breadcrumbs: IBreadcrumbsProps['breadcrumbs'];
}> = ({ breadcrumbs }) => {
  const previousPage = React.useMemo(() => {
    const currentPageIndex = breadcrumbs.findIndex(item => item.isCurrent);

    for (let i = currentPageIndex - 1; i >= 0; i--) {
      if (breadcrumbs[i].link) {
        return breadcrumbs[i];
      }
    }

    return breadcrumbs[0];
  }, [breadcrumbs]);

  return previousPage ? (
    <Breadcrumb {...previousPage} separator="smaller-than" />
  ) : null;
};

type BreadcrumbsContentProps = Pick<
  IBreadcrumbsProps,
  | 'itemsAfterCollapse'
  | 'itemsBeforeCollapse'
  | 'breadcrumbs'
  | 'showHomePage'
  | 'showCurrentPage'
> & {
  displayedSeparator: Separator;
  showEllipsis: boolean;
  onEllipsisClick: () => void;
};

const BreadcrumbsContent: React.FC<BreadcrumbsContentProps> = props => {
  const {
    breadcrumbs,
    showEllipsis,
    itemsBeforeCollapse,
    itemsAfterCollapse,
    displayedSeparator,
    onEllipsisClick,
    showCurrentPage,
    showHomePage,
  } = props;

  const getBreadcrumb = (item: IBreadcrumb, key: number) => (
    <Breadcrumb key={key} {...item} separator={displayedSeparator} />
  );

  if (showEllipsis && canRenderEllipsis(props)) {
    const headIndex = getHeadIndex(itemsBeforeCollapse, showHomePage);
    const tailIndex = getTailIndex(itemsAfterCollapse, showCurrentPage);

    const head = breadcrumbs.slice(0, headIndex).map(getBreadcrumb);
    const tail = tailIndex
      ? breadcrumbs.slice(-tailIndex).map(getBreadcrumb)
      : [];

    return (
      <>
        {head}
        <Ellipsis
          key="ellipsisItem"
          separator={displayedSeparator}
          onClick={onEllipsisClick}
        />
        {tail}
      </>
    );
  } else {
    return <>{breadcrumbs.map(getBreadcrumb)}</>;
  }
};

// https://www.w3.org/TR/wai-aria-practices-1.1/examples/breadcrumb/index.html
const Breadcrumbs: React.ForwardRefRenderFunction<
  HTMLDivElement,
  IBreadcrumbsProps
> = (props, ref) => {
  const {
    id,
    direction,
    breadcrumbs,
    itemsBeforeCollapse,
    itemsAfterCollapse,
    separator,
    shouldWrap,
    showOnlyPreviousPageOnMobile,
    className,
    stylableClassName,
    onMouseEnter,
    onMouseLeave,
    showCurrentPage,
    showHomePage,
    onEllipsisVisibilityChange,
  } = props;

  const displayedSeparator = selectDisplayedSeparator(separator, direction);
  const [showEllipsis, setShowEllipsis] = useState<boolean>(!shouldWrap);

  useEffect(() => {
    setShowEllipsis(!shouldWrap);
  }, [shouldWrap]);

  const handleEllipsisClick = () => {
    onEllipsisVisibilityChange(false);
    setShowEllipsis(false);
  };

  const contentClassName = showOnlyPreviousPageOnMobile
    ? classes['only-previous']
    : '';

  return (
    <div
      id={id}
      className={className}
      {...getDataAttributes(props)}
      ref={ref}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <nav
        aria-label="breadcrumbs"
        className={st(classes.root, stylableClassName)}
      >
        <ol className={st(classes.list, contentClassName)}>
          {showOnlyPreviousPageOnMobile ? (
            <PreviousPage breadcrumbs={breadcrumbs} />
          ) : (
            <BreadcrumbsContent
              showEllipsis={showEllipsis}
              itemsBeforeCollapse={itemsBeforeCollapse}
              itemsAfterCollapse={itemsAfterCollapse}
              breadcrumbs={breadcrumbs}
              displayedSeparator={displayedSeparator}
              onEllipsisClick={handleEllipsisClick}
              showCurrentPage={showCurrentPage}
              showHomePage={showHomePage}
            />
          )}
        </ol>
      </nav>
    </div>
  );
};

export default React.forwardRef(Breadcrumbs);
