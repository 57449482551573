import { withCompController } from '@wix/editor-elements-integrations';
import type {
  ISearchBoxControllerProps,
  ISearchBoxMapperProps,
  ISearchBoxProps,
} from '../SearchBox.types';

export default withCompController<
  ISearchBoxMapperProps,
  ISearchBoxControllerProps,
  ISearchBoxProps
>(({ mapperProps, controllerUtils }) => {
  return {
    ...mapperProps,
    closeSuggestions() {
      controllerUtils.updateProps({
        isSuggestionsOpen: false,
        closeParentContainer: false,
        suggestionsLoading: false,
      });
    },
    clearSuggestions() {
      controllerUtils.updateProps({
        suggestions: null,
      });
    },
    changeValue(value) {
      controllerUtils.updateProps({ value });
    },
  };
});
