import {
  createComponentPreviewMapperModel,
  withCompPreviewProps,
} from '@wix/editor-elements-integrations';
import {
  FreemiumBannerDesktopDefinition,
  FreemiumBannerDesktopPlatformProps,
} from '../FreemiumBannerDesktop.types';
import previewTranslations from './constants.preview';

const getPreviewUrl = (metaSiteId?: string) =>
  `//premium.wix.com/wix/api/premiumStart?siteGuid=${metaSiteId}&referralAdditionalInfo=edhtml_PREVIEW_FREE_SITE_BANNER_CLICK`;

export const props = withCompPreviewProps<
  FreemiumBannerDesktopPlatformProps,
  FreemiumBannerDesktopDefinition
>()(['direction', 'metaSiteId'], ({ direction, metaSiteId }) => {
  return {
    useOverlay: false,
    direction,
    href: getPreviewUrl(metaSiteId),
    classNames: [direction],
    translations: previewTranslations,
  };
});

export default createComponentPreviewMapperModel({
  props,
});
