import React from 'react';

import { createComponentPreviewEntry } from '@wix/editor-elements-integrations';
import type {
  IComponentPreviewWrapper,
  PreviewWrapperProps,
} from '@wix/editor-elements-types/thunderboltPreview';
import type { IComponentCustomMeasureImperativeAPI } from '@wix/thunderbolt-becky-types';

import { withComponentPreview as withMediaContainerPreviewWrapper } from '../../MediaContainers/MediaContainer/viewer/MediaContainer.previewWrapper';
import { ColumnProps, ColumnWrapperProps } from '../Column.types';
import { MediaContainerVideoAPI } from '../../MediaContainers/MediaContainer/MediaContainer.types';
import { useCustomMeasuresDependency } from '../../../preview-utils/useCustomMeasuresDependency';

function withComponentPreview(
  ViewerComponent: React.ComponentType<
    ColumnProps & { ref: React.Ref<MediaContainerVideoAPI> }
  >,
): IComponentPreviewWrapper<ColumnProps, ColumnWrapperProps> {
  return React.forwardRef<
    IComponentCustomMeasureImperativeAPI,
    PreviewWrapperProps<ColumnProps, ColumnWrapperProps>
  >((props, ref) => {
    const { previewWrapperProps = { isMobileView: false } } = props;

    const mediaContainerRef = React.useRef<MediaContainerVideoAPI | null>(null);

    const contentAreaRef = useCustomMeasuresDependency(
      props.columnOverrides.contentArea,
    );

    const isMobileRef = useCustomMeasuresDependency(
      previewWrapperProps.isMobileView,
    );

    React.useImperativeHandle(ref, () => ({
      getCustomMeasures: () => ({
        relativeToScreenOverrides: {
          [props.id]: () => {
            const column = document.getElementById(props.id);
            if (!column) {
              return;
            }
            const { x, width } = column.getBoundingClientRect();
            return { x, width };
          },
        },
        left: {
          [props.id]: siteMeasureMap => {
            if (isMobileRef.current) {
              return 0;
            }
            const column = document.getElementById(props.id);
            if (!column) {
              return;
            }
            const { width: columnWidth, left: columnLeft } =
              column.getBoundingClientRect();

            const { width: contentWidth, alignment } = contentAreaRef.current;

            /**
             * We need to understand how many pixels from left side
             * of the screen to area with content inside Column.
             *
             * Width of this area is calculated inside viewer via
             * getColumnOverrides and passed here via carmi mapper
             */
            const contentAreaLeft = Math.floor(
              columnLeft + (columnWidth - contentWidth) * alignment,
            );

            return contentAreaLeft - siteMeasureMap.left.site;
          },
        },
        width: {
          [props.id]: () => {
            const { width: contentWidth } = contentAreaRef.current;
            return contentWidth;
          },
        },
      }),
    }));

    return <ViewerComponent {...props} ref={mediaContainerRef} />;
  });
}

export default (ViewerComponent: React.ComponentType<ColumnProps>) =>
  createComponentPreviewEntry(
    withComponentPreview(withMediaContainerPreviewWrapper(ViewerComponent)),
  );
