import * as React from 'react';
import { MenuItemProps } from '../../StylableHorizontalMenu.types';

export const populateWithChildren = (
  items: Array<MenuItemProps>,
  slots: Record<string, JSX.Element>,
): Array<MenuItemProps & { children?: React.ReactNode }> => {
  return items.map(item => {
    const slotId = item.slot;
    const slotContentElement = slots[slotId!];
    if (!slotId || !slotContentElement) {
      return item;
    }

    return {
      ...item,
      children: slotContentElement,
    };
  });
};
