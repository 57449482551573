import { MenuItemProps } from '../../StylableHorizontalMenu.types';

const removeQueryFromUrl = (url: string): string => url.split('?')[0];

export const getIsCurrentPage = (
  link: MenuItemProps['link'],
  currentPagePath?: string,
) => {
  if (!link) {
    return false;
  }
  const isAnchorLink = link.anchorDataId || link.anchorCompId;
  return (
    !isAnchorLink && removeQueryFromUrl(link.href || '') === currentPagePath
  );
};
