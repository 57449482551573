import * as React from 'react';
import classNames from 'classnames';
import { IAppWidgetLoaderProps } from '../../../AppWidget.types';
import style from './styles/Loader.scss';

const AppWidgetLoader: React.FC<IAppWidgetLoaderProps> = props => {
  const { id } = props;

  return (
    <div id={id} className={classNames(style.root, style.loading)}>
      <div role="alert" aria-busy="true" className={style.preloader} />
    </div>
  );
};

export default AppWidgetLoader;
