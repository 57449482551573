import * as React from 'react';
import classNames from 'classnames';
import type { TranslationGetter } from '@wix/editor-elements-types/thunderbolt';
import { customCssClasses } from '@wix/editor-elements-common-utils';
import {
  IResponsiveBoxProps,
  IContainerImperativeActions,
} from '../../../Container.types';
import commonStyles from '../../shared/common.scss';
import {
  ARIA_LABEL_DEFAULT,
  ARIA_LABEL_KEY,
  ARIA_LABEL_NAMESPACE,
} from '../../shared/constants';
import { getAriaAttributes } from '../../../../../core/commons/a11y';
import { getDataAttributes } from '../../../../../core/commons/utils';
import styles from './ResponsiveBox.scss';
import semanticClassNames from './ResponsiveBox.semanticClassNames';

const getAriaLabel = (translate: TranslationGetter | undefined) => {
  return translate
    ? translate(ARIA_LABEL_NAMESPACE, ARIA_LABEL_KEY, ARIA_LABEL_DEFAULT)
    : ARIA_LABEL_DEFAULT;
};

// This container is used in responsive site
const ResponsiveBox: React.ForwardRefRenderFunction<
  IContainerImperativeActions,
  IResponsiveBoxProps
> = (props, ref) => {
  const {
    id,
    className,
    containerRootClassName = '',
    customClassNames = [],
    children,
    role,
    onClick,
    onDblClick,
    onFocus,
    onBlur,
    onMouseEnter,
    onMouseLeave,
    hasPlatformClickHandler,
    translate,
    a11y = {},
    ariaAttributes = {},
    tabIndex,
  } = props;
  const { 'aria-label-interactions': ariaLabelInteractions, ...a11yAttr } =
    a11y;

  if (ariaLabelInteractions) {
    a11yAttr['aria-label'] = getAriaLabel(translate);
  }

  const rootElementRef = React.useRef<HTMLDivElement>(null);

  React.useImperativeHandle(ref, () => {
    return {
      focus: () => {
        rootElementRef.current?.focus();
      },
      blur: () => {
        rootElementRef.current?.blur();
      },
    };
  });

  return (
    <div
      id={id}
      {...getDataAttributes(props)}
      ref={rootElementRef}
      {...a11yAttr}
      {...getAriaAttributes({ ...ariaAttributes, tabIndex, role })}
      className={classNames(
        styles.root,
        containerRootClassName,
        className,
        customCssClasses(semanticClassNames.root, ...customClassNames),
        {
          [commonStyles.clickable]: hasPlatformClickHandler,
        },
      )}
      onDoubleClick={onDblClick}
      onClick={onClick}
      onFocus={onFocus}
      onBlur={onBlur}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <div className={styles.bg} />
      {
        children() // contains: (1) ResponsiveContainer with relative children, (2) DynamicStructureContainer with pinned children
      }
    </div>
  );
};

export default React.forwardRef(ResponsiveBox);
