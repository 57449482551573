import { createComponentPreviewMapperModel } from '@wix/editor-elements-integrations';
import { composeMapper } from '@wix/editor-elements-preview-utils';
import type { PreviewWrapperProps } from '@wix/editor-elements-types/thunderboltPreview';
import {
  VideoPlayerDefinition,
  IVideoPlayerMapperProps,
} from '../VideoPlayer.types';
import { getPlayerName } from './utils';
import { props as viewerPropsMapper } from './VideoPlayer.mapper';

const props = composeMapper<
  PreviewWrapperProps<IVideoPlayerMapperProps>,
  VideoPlayerDefinition
>()(
  viewerPropsMapper,
  { add: ['isPlayingAllowed', 'shouldResetComponent'] },
  (resolver, deps, carmiData) => {
    const viewerProps = viewerPropsMapper.resolver(deps, carmiData);
    const { isPlayingAllowed, shouldResetComponent } = deps;
    const isTwitch = getPlayerName(viewerProps.src) === 'twitch';
    return {
      ...viewerProps,
      // Allows twitch iframe to load from `editor.wix.com` instead of `wix.com`
      twitchParentDomains: isTwitch ? [window.location.hostname] : undefined,
      previewWrapperProps: {
        isPlayingAllowed,
        shouldResetComponent,
      },
    };
  },
);

export default createComponentPreviewMapperModel({
  props,
});
