import * as React from 'react';
import { IRSSButtonProps } from '../RSSButton.types';
import Image from '../../Image/viewer/Image';
import Link from '../../Link/viewer/Link';
import { getDataAttributes } from '../../../core/commons/utils';
import style from './style/RSSButton.scss';

const RSSButton: React.FC<IRSSButtonProps> = props => {
  const { id, className, image, link, onMouseEnter, onMouseLeave } = props;

  // TODO: diff vs current Bolt implmenentation: "rel" attribute of a tag incorrect for premium sites
  // https://github.com/wix-private/santa/blob/master/packages/coreUtils/src/main/core/linkRenderer.js#L42
  if (image && link) {
    return (
      <div
        id={id}
        {...getDataAttributes(props)}
        title={image.alt}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        className={className}
      >
        <Link {...{ rel: 'noopener', ...link }} className={style.link}>
          <Image id={`img_${id}`} {...image} />
        </Link>
      </div>
    );
  }
  return <div id={id} />;
};

export default RSSButton;
