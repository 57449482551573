import React from 'react';
import { TestIds } from '../constants';

type TinyMenuButtonProps = {
  className: string;
  isExpanded: boolean;
  onClick: () => void;
  children?: React.ReactNode;
};

const TinyMenuButton: React.ForwardRefRenderFunction<
  HTMLButtonElement,
  TinyMenuButtonProps
> = ({ className, isExpanded, children, onClick }, ref) => (
  <button
    ref={ref}
    type="button"
    aria-haspopup="true"
    aria-expanded={isExpanded}
    data-testid={TestIds.menuButton}
    className={className}
    onClick={onClick}
  >
    {children}
  </button>
);

export default React.forwardRef(TinyMenuButton);
