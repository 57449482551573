import MeshContainer from '@wix/thunderbolt-elements/components/MeshContainer';
import { getDataAttributes } from '@wix/thunderbolt-elements/commons/utils';
import ResponsiveContainer from '@wix/thunderbolt-elements/components/ResponsiveContainer';
import React from 'react';
import cn from 'classnames';
import { SingleTabProps } from '../SingleTab.types';

const getDefaultContainerProps = (id: string) => ({
  // TODO - wire correctly
  containerLayoutClassName: `${id}-container`,
  // TODO - wire correctly
  overlowWrapperClassName: `${id}-overflow-wrapper`,
  // TODO - wire correctly
  hasOverflow: true,
});

const SingleTab: React.FC<SingleTabProps> = props => {
  const {
    children,
    meshProps,
    id,
    className,
    hasResponsiveLayout,
    containerProps,
    containerRootClassName,
  } = props;
  const childrenToRender =
    typeof children === 'function' ? children : () => children;

  return hasResponsiveLayout ? (
    <div
      id={id}
      {...getDataAttributes(props)}
      className={cn(containerRootClassName, className)}
    >
      <ResponsiveContainer
        {...(containerProps || getDefaultContainerProps(id))}
      >
        {children}
      </ResponsiveContainer>
    </div>
  ) : (
    <div id={id} className={className} {...getDataAttributes(props)}>
      <MeshContainer {...meshProps} id={id}>
        {childrenToRender}
      </MeshContainer>
    </div>
  );
};

export default SingleTab;
