import * as React from 'react';
import classnames from 'classnames';
import { MenuButtonBackgroundLabel } from '../../MenuButtonBackground';
import { MenuButtonProps } from '../../../MenuButton.types';
import MenuButtonRoot from '../../MenuButtonRoot';
import skinsStyle from './styles/skins.scss';

const CirclesMenuButtonSkin: React.FC<MenuButtonProps> = props => {
  const { id, dir, textAlign } = props;

  return (
    <MenuButtonRoot
      {...props}
      className={classnames(props.className, skinsStyle.CirclesMenuButtonSkin)}
    >
      {label => (
        <>
          <div className={skinsStyle.bg} />
          <MenuButtonBackgroundLabel
            dir={dir}
            textAlign={textAlign}
            className={skinsStyle.label}
            tagName="div"
            id={id}
          >
            {label}
          </MenuButtonBackgroundLabel>
        </>
      )}
    </MenuButtonRoot>
  );
};

export default CirclesMenuButtonSkin;
