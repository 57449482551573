import {
  GetPreferredAppPreferencesResponse,
  OneAppPreferredApps,
} from '@wix/ambassador-one-app-preferred-apps/http';
import { withCompController } from '@wix/editor-elements-integrations';
import {
  FreemiumBannerMobileMapperProps,
  FreemiumBannerMobileProps,
  IFreemiumBannerRefState,
} from '../FreemiumBannerMobile.types';
import { biEvents } from './biEvents';

const metasiteAppDefinitionId = '22bef345-3c5b-4c18-b782-74d4085112ff';

const componentController = withCompController<
  FreemiumBannerMobileMapperProps,
  FreemiumBannerMobileProps,
  never,
  IFreemiumBannerRefState
>(({ mapperProps, stateValues }) => {
  const { getAppInstanceByAppDefId, reportBi } = stateValues;

  const getPreferredApp = (): Promise<GetPreferredAppPreferencesResponse> => {
    const metaSiteInstance = getAppInstanceByAppDefId(metasiteAppDefinitionId);

    return OneAppPreferredApps('')
      .OneAppPreferredAppsService()({ Authorization: metaSiteInstance })
      .getPreferredAppPreferences({});
  };

  return {
    ...mapperProps,
    getPreferredApp,
    onBannerLoad: () => {
      reportBi(biEvents.ONE_APP_FREE_BANNER_SHOWN, { endpoint: 'ugc-viewer' });
    },
    onBannerClicked: () => {
      reportBi(biEvents.ONE_APP_FREE_BANNER_CLICK, { endpoint: 'ugc-viewer' });
    },
  };
});

export default componentController;
