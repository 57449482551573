import * as React from 'react';
import type { IShareButtonProps } from '../types';
import {
  COPY_CONFIRMATION_TIMEOUT,
  shareButtonsBaseId,
  toastTextKey,
} from '../constants';
import ShareButtonIcon from './ShareButtonIcon';
import { classes, st } from './style/ShareButton.component.st.css';
import ButtonContentWrapper from './ButtonContentWrapper';

const ShareButton: React.FC<IShareButtonProps> = ({
  id,
  label,
  value,
  illustration,
  url = '',
  displayLabel,
  displayIcon,
  iconPosition,
  translate,
}) => {
  const [buttonLabel, setButtonLabel] = React.useState(label);
  const translateFn = React.useCallback(
    (key: string, fallbackValue: string) => {
      return translate
        ? translate(shareButtonsBaseId, key, fallbackValue)
        : () => fallbackValue;
    },
    [translate],
  );

  const onCopyButtonClicked = () => {
    setButtonLabel(translateFn(toastTextKey, 'Link Copied!'));
    setTimeout(() => {
      setButtonLabel(label);
    }, COPY_CONFIRMATION_TIMEOUT);
  };
  return (
    <ButtonContentWrapper
      id={id}
      mediaId={value}
      iconPosition={iconPosition}
      url={url}
      onCopyButtonClicked={onCopyButtonClicked}
      ariaLabel={buttonLabel}
    >
      {displayIcon && (
        <ShareButtonIcon
          className={st(classes.icon, {
            iconPosition,
            hasLabelSpacing: displayLabel,
          })}
          svg={illustration?.svgId ?? ''}
        />
      )}
      {displayLabel && <span className={st(classes.label)}>{buttonLabel}</span>}
    </ButtonContentWrapper>
  );
};

export default ShareButton;
