import classnames from 'classnames';
import * as React from 'react';
import {
  IContainerProps,
  IContainerImperativeActions,
} from '../../../Container.types';
import { ContainerLogic } from '../../shared/ContainerLogic';
import styles from './LiftedTopAreaSkin.scss';

const LiftedTopAreaSkin: React.ForwardRefRenderFunction<
  IContainerImperativeActions,
  IContainerProps
> = (props, ref) => {
  return (
    <ContainerLogic
      {...props}
      ref={ref}
      className={classnames(styles.root, props.className)}
      renderSlot={({ containerChildren }) => (
        <>
          <div className={styles.leftTop} />
          <div className={styles.centerTop} />
          <div className={styles.rightTop} />
          <div className={styles.bg} />
          {containerChildren}
        </>
      )}
    />
  );
};

export default React.forwardRef(LiftedTopAreaSkin);
