
  var rt = require("@stylable/runtime/dist/css-runtime-stylesheet.js");

  module.exports = rt.create(
      "Tabscomponent1176390459",
      {"classes":{"root":"Tabscomponent1176390459__root","tabsList":"Tabscomponent1176390459__tabsList","multiStateBoxWrapper":"Tabscomponent1176390459__multiStateBoxWrapper","tabPanelWrapper":"Tabscomponent1176390459__tabPanelWrapper"},"vars":{"display":"--display"},"stVars":{"tabsListContainerGap":"var(--tabs-list-container-gap, 0)"},"keyframes":{},"layers":{}},
      "",
      -1,
      module.id
  );
  