import {
  withCompInfo,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';
import {
  MenuToggleDefinition,
  MenuTogglePlatformProps,
  MenuToggleCarmiProps,
  MenuToggleCSS,
  MenuToggleCarmiCSS,
} from '../MenuToggle.types';

export const props = withCompInfo<
  MenuTogglePlatformProps,
  MenuToggleDefinition,
  MenuToggleCarmiProps
>()([], (_, carmiData) => {
  return carmiData;
});

export const css = withCompInfo<
  MenuToggleCSS,
  MenuToggleDefinition,
  MenuToggleCarmiCSS
>()([], (_, carmiCss) => {
  return carmiCss;
});

export default createComponentMapperModel({ props, css });
