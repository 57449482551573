import * as React from 'react';
import classNames from 'classnames';
import { IResponsivePopupPageProps } from '../ResponsivePopupPage.types';
import ResponsiveContainer from '../../../thunderbolt-core-components/ResponsiveContainer/viewer/ResponsiveContainer';
import { TestIds } from '../constants';
import { getDataAttributes } from '../../../core/commons/utils';
import style from './style/ResponsivePopupPage.scss';

const PopupPage: React.FC<IResponsivePopupPageProps> = props => {
  const { id, className, pageDidMount, onClick, children, containerProps } =
    props;
  return (
    <div
      id={id}
      {...getDataAttributes(props)}
      className={classNames(className, style.root, {
        [style.clickable]: onClick,
      })}
      ref={pageDidMount}
    >
      <div
        className={style.bg}
        onClick={onClick}
        data-testid={TestIds.ResponsivePopupPageBg}
      />
      <ResponsiveContainer
        {...containerProps}
        extraRootClass={style.responsiveContainer}
      >
        {() => <div className={style.lightboxLayer}>{children()}</div>}
      </ResponsiveContainer>
    </div>
  );
};

export default PopupPage;
