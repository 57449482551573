import { useRef } from 'react';
import { IAddressInputProps } from '../../components/AddressInput/AddressInput.types';
import {
  InternalAddress,
  CommonAddress,
  Suggestion,
  Predictions,
  Prediction,
} from './types';

export const translationKeys = {
  noResults: 'address_input_states_no_results',
  generalError: 'address_input_states_error_general',
} as const;

type ErrorTranslationKey =
  (typeof translationKeys)[keyof typeof translationKeys];

const translationFallbacks: Record<ErrorTranslationKey, string> = {
  [translationKeys.noResults]: 'No result was found for this request.',
  [translationKeys.generalError]:
    'The PlacesService request could not be processed due to a server error. The request may succeed if you try again.',
};

const resolveToEmptyObject = () => Promise.resolve({});

const omitFalsyValues = (
  address: InternalAddress,
): Partial<InternalAddress> => {
  return Object.entries(address).reduce(
    (acc: InternalAddress, [key, value]: [string, any]) => {
      if (value) {
        return { ...acc, [key]: value };
      }
      return acc;
    },
    {},
  );
};

const toSuggestions = (predictions: Predictions): Array<Suggestion> =>
  predictions.map((prediction: Prediction) => ({
    id: prediction.searchId || '',
    value: prediction.description || '',
  }));

const convertToPartialAddress = (
  atlasResponse: CommonAddress = {},
): Partial<InternalAddress> => {
  const address: InternalAddress = {
    formatted: atlasResponse.formattedAddress,
    streetAddress: atlasResponse.streetAddress,
    subdivision: atlasResponse.subdivision,
    city: atlasResponse.city,
    country: atlasResponse.country,
    postalCode: atlasResponse.postalCode,
    ...(atlasResponse.geocode
      ? {
          location: {
            latitude: atlasResponse.geocode.latitude || 0,
            longitude: atlasResponse.geocode.longitude || 0,
          },
        }
      : {}),
  };

  return omitFalsyValues(address);
};

export const useAtlasHandler = ({
  country,
  getPlace = resolveToEmptyObject,
  predict = resolveToEmptyObject,
  translate = (key: string) => key,
  onSuggestionsUpdate,
}: Pick<
  IAddressInputProps,
  'country' | 'getPlace' | 'predict' | 'onSuggestionsUpdate'
> & {
  translate: (key: string, fallbackValue: string) => string;
}) => {
  const lastRequestIdRef = useRef(0);

  const getGeocode = async ({
    placeId,
    rawInputValue,
  }: {
    placeId: string;
    rawInputValue: string;
  }) => {
    const newRequest = { searchId: placeId?.toString() };
    try {
      const result = await getPlace(newRequest);
      return result && result.place
        ? convertToPartialAddress(result.place.address)
        : { formatted: rawInputValue };
    } catch (e) {
      return { formatted: rawInputValue };
    }
  };

  const autoComplete = async (value: string) => {
    const requestId = ++lastRequestIdRef.current;

    const predictRequest = {
      input: value,
      ...(country
        ? {
            country_codes: [country],
          }
        : {}),
    };

    try {
      const predictResponse = await predict(predictRequest);

      if (requestId === lastRequestIdRef.current) {
        if (predictResponse.predictions && predictResponse.predictions.length) {
          onSuggestionsUpdate(toSuggestions(predictResponse.predictions || []));
        } else {
          onAutoCompleteError(translationKeys.noResults);
        }
      }
    } catch (errorStatus) {
      if (requestId === lastRequestIdRef.current) {
        onAutoCompleteError(translationKeys.generalError);
      }
    }
  };

  const onAutoCompleteError = (
    errorTranslationKey: ErrorTranslationKey,
  ): void => {
    onSuggestionsUpdate([
      {
        id: '',
        value: translate(
          errorTranslationKey,
          translationFallbacks[errorTranslationKey],
        ),
      },
    ]);
  };

  return {
    getGeocode,
    autoComplete,
  };
};
