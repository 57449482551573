import {
  createComponentMapperModel,
  withCompInfo,
  withStateRefsValues,
} from '@wix/editor-elements-integrations';
import { getLanguage } from '../../WTwitter/common/utils';
import {
  WTwitterFollowDefinition,
  WTwitterFollowMapperProps,
  WTwitterFollowStateRefs,
} from '../WTwitterFollow.types';

export const props = withCompInfo<
  WTwitterFollowMapperProps,
  WTwitterFollowDefinition
>()(
  ['compData', 'compProps', 'compLayout', 'language'],
  ({ compData, compProps, compLayout, language }) => {
    return {
      showCount: compProps.showCount.toString(),
      showScreenName: compProps.showScreenName.toString(),
      accountToFollow: compData.accountToFollow.replace('@', ''),
      lang: getLanguage(compProps.dataLang, language),
      width: compLayout.width || 0,
      height: compLayout.height || 0,
    };
  },
);

export const stateRefs = withStateRefsValues<keyof WTwitterFollowStateRefs>([
  'currentUrl',
]);

export default createComponentMapperModel({ props, stateRefs });
