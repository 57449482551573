import { withCompController } from '@wix/editor-elements-integrations';
import {
  ITabsMapperProps,
  ITabControllerProps,
  ITabsProps,
} from '../Tabs.types';

export default withCompController<
  ITabsMapperProps,
  ITabControllerProps,
  ITabsProps
>(({ mapperProps, controllerUtils }) => {
  return {
    ...mapperProps,
    setCurrentTabId: currentTabId => {
      controllerUtils.updateProps({ currentTabId });
    },
  };
});
