import * as React from 'react';
import { cloneDeep, set } from 'lodash';
import { IExternalComponentProps } from '../ExternalComponent.types';

const ExternalComponent: React.FC<IExternalComponentProps> = props => {
  const { id, Component, compProps, events, handleEvents } = props;

  const newCompProps = React.useMemo(() => {
    if (!handleEvents || !events || events.length === 0) {
      return compProps;
    }

    const processedProps = cloneDeep(compProps);

    for (const path of events) {
      set(processedProps, path, () => handleEvents(JSON.stringify(path)));
    }

    return processedProps;
  }, [events, handleEvents, compProps]);

  // Remove this after TB-7042 in TB is merged
  const isWithRuiProps = ['receiver', 'controller'].every(x =>
    compProps.hasOwnProperty(x),
  );

  return (
    <div id={id}>
      {isWithRuiProps
        ? Component && <Component {...compProps}></Component>
        : Component && <Component compProps={newCompProps}></Component>}
    </div>
  );
};

export default ExternalComponent;
