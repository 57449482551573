import { withCompController } from '@wix/editor-elements-integrations';
import {
  VerticalMenuControllerProps,
  VerticalMenuProps,
  VerticalMenuStateRefs,
} from '../VerticalMenu.types';

const compController = withCompController<
  VerticalMenuProps,
  VerticalMenuControllerProps,
  VerticalMenuStateRefs
>(({ stateValues, mapperProps }) => {
  const { currentUrl } = stateValues;

  return {
    ...mapperProps,
    currentUrl,
  };
});

export default compController;
