import {
  withCompInfo,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';
import {
  HtmlComponentProps,
  HtmlComponentDefinition,
} from '../HtmlComponent.types';

export const props = withCompInfo<
  HtmlComponentProps,
  HtmlComponentDefinition,
  HtmlComponentProps
>()([], (_, carmiData) => {
  return carmiData;
});

export default createComponentMapperModel({ props });
