import { createComponentPreviewEntry } from '@wix/editor-elements-integrations';
import { useResetComponent } from '@wix/editor-elements-preview-utils';
import React from 'react';
import type { PreviewWrapperProps } from '@wix/editor-elements-types/thunderboltPreview';
import { IVideoPlayerProps, IVideoPlayerHandles } from '../VideoPlayer.types';

export default (ViewerComponent: React.ComponentType<IVideoPlayerProps>) =>
  createComponentPreviewEntry(
    React.forwardRef<IVideoPlayerHandles, IVideoPlayerProps>(function (
      {
        previewWrapperProps = {},
        ...viewerProps
      }: PreviewWrapperProps<
        IVideoPlayerProps,
        { isPlayingAllowed?: boolean; shouldResetComponent?: boolean }
      >,
      ref,
    ) {
      const { id } = viewerProps;
      const { shouldResetComponent } = previewWrapperProps;
      const key = useResetComponent({
        id,
        shouldResetComponent,
      });
      return (
        <ViewerComponent
          {...viewerProps}
          reducedMotion={!previewWrapperProps.isPlayingAllowed}
          key={key}
          ref={ref}
        />
      );
    }),
  );
