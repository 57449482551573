import classNames from 'classnames';
import * as React from 'react';
import { customCssClasses } from '@wix/editor-elements-common-utils';
import { getDataAttributes } from '../../../../../core/commons/utils';
import { VerticalLineProps } from '../VerticalLine';
import semanticClassNames from '../../../VerticalLine.semanticClassNames';

type BaseButtonSkinProps = VerticalLineProps & {
  skinsStyle: Record<string, string>;
  className?: string;
};

export const BaseVerticalLine: React.FC<BaseButtonSkinProps> = props => {
  const {
    skinsStyle,
    id,
    className,
    customClassNames = [],
    onMouseEnter,
    onMouseLeave,
  } = props;
  return (
    <div
      id={id}
      {...getDataAttributes(props)}
      className={classNames(
        skinsStyle.root,
        className,
        customCssClasses(semanticClassNames.root, ...customClassNames),
      )}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {skinsStyle.line && <div className={skinsStyle.line} />}
    </div>
  );
};
