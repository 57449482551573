import React, { useState } from 'react';
import { DefaultContainerProps } from '@wix/editor-elements-types/thunderbolt';
import { useDidUpdate } from '../../../providers/useDidUpdate';

const HiddenState: React.FC<{ id?: string }> = ({ children, id }) => (
  <span style={{ display: 'none' }} id={id}>
    {children}
  </span>
);

export const useMSBStates = ({
  children,
  getStateId,
  selectedStateId,
  onChange,
}: {
  children: React.ReactNode | DefaultContainerProps['children'];
  getStateId: (state: React.ReactElement) => string;
  selectedStateId: string;
  onChange?: (event: React.ChangeEvent) => void;
}) => {
  /**
   * We keep state memory to preserve DOM state (like inputs)
   */
  const [pastStateIds, setPastStateIds] = useState<Array<string>>([]);

  useDidUpdate(() => {
    onChange?.({ type: 'change' } as React.ChangeEvent);

    if (!pastStateIds.includes(selectedStateId)) {
      setPastStateIds([...pastStateIds, selectedStateId]);
    }
  }, [selectedStateId]);

  /**
   * TB Containers pass children as a function
   */
  const stateElements = React.Children.toArray(
    typeof children === 'function' ? (children as Function)() : children,
  );

  const currentOrPastStates = stateElements.filter(state => {
    if (!React.isValidElement(state)) {
      return false;
    }
    const itemId = getStateId(state);
    return selectedStateId === itemId || pastStateIds.includes(itemId);
  }) as Array<React.ReactElement>;

  if (currentOrPastStates.length) {
    return currentOrPastStates.map(state =>
      selectedStateId === getStateId(state) ? (
        state
      ) : (
        // If we rendered this state in the past - render it hidden, to maintain DOM state
        <HiddenState id={state.props.id}>{state}</HiddenState>
      ),
    );
  }

  return stateElements.slice(0, 1);
};
