import React, { memo } from 'react';
import type { IAccordionProps } from '../../types';
import { useHeightCssVars } from '../hooks/useHeightCssVars';
import { useHideContentAfterTransitionEnds } from '../hooks/useHideContentAfterTransitionEnds';
import { AccordionLayout } from './AccordionLayout';

export const AccordionAnimated: React.FC<IAccordionProps> = memo(props => (
  <AccordionLayout
    {...props}
    animationProps={{
      ...useHeightCssVars(),
      ...useHideContentAfterTransitionEnds(props.isOpened),
    }}
  />
));
