import * as React from 'react';
import { IGridProps } from '../Grid.types';
import { ColumnLayout } from '../constants';
import styles from './styles/Grid.scss';

export interface ITableHead {
  setScrollLeft(scrollLeft: number): void;
}

type ITableHeadProps = Pick<IGridProps, 'columns' | 'columnLayout'> & {
  tableWidth?: number;
};

const TableHead: React.ForwardRefRenderFunction<ITableHead, ITableHeadProps> = (
  { columns, columnLayout, tableWidth },
  ref,
) => {
  const theadRef = React.useRef<HTMLTableSectionElement>(null);

  React.useImperativeHandle(ref, () => ({
    setScrollLeft(scrollLeft) {
      const theadElement = theadRef.current;
      if (theadElement) {
        theadElement.scrollLeft = scrollLeft;
      }
    },
  }));

  return (
    <thead ref={theadRef} className={styles.head}>
      <tr className={styles.headRow} style={{ width: tableWidth }}>
        {columns.map(({ label, width }, columnIndex) => (
          <th
            key={columnIndex}
            className={styles.headCell}
            style={columnLayout !== ColumnLayout.Equal ? { width } : undefined}
          >
            <div className={styles.cellContent}>
              {!!label && <div dangerouslySetInnerHTML={{ __html: label }} />}
            </div>
          </th>
        ))}
      </tr>
    </thead>
  );
};

export default React.forwardRef(TableHead);
