import React, { FC } from 'react';
import { Avatar as AvatarWUT } from 'wix-ui-tpa/dist/src/components/Avatar';

import { AvatarState } from '../../../LoginSocialBar.types';
import VectorImage from '../../../../VectorImage/viewer/VectorImage';
import Image from '../../../../Image/viewer/Image';

interface AvatarProps {
  id: string;
  defaultAvatarSvgContent: string;
  size: number;
  name: string;
  className?: string;
  url?: string;
  state?: AvatarState;
}

export const Avatar: FC<AvatarProps> = ({
  state,
  url,
  defaultAvatarSvgContent,
  id,
  size,
  name,
  className,
}) => {
  if (state === AvatarState.Initials) {
    return <AvatarWUT className={className} name={name} />;
  }

  if (state === AvatarState.Image && url) {
    return (
      <Image
        id={`customAvatar-${id}`}
        containerId={id}
        width={size}
        height={size}
        displayMode="fill"
        uri={url}
        name=""
        alt=""
        className={className}
      />
    );
  }

  return (
    <VectorImage
      id={`defaultAvatar-${id}`}
      className={className}
      svgContent={defaultAvatarSvgContent}
      shouldScaleStroke={false}
      withShadow={false}
    />
  );
};
