import { withCompController } from '@wix/editor-elements-integrations';
import {
  WFacebookCommentMapperProps,
  WFacebookCommentControllerProps,
  WFacebookCommentProps,
  WFacebookCommentStateRefs,
} from '../WFacebookComment.types';
import { getSocialUrl } from '../../../core/commons/controllerUtils';

const compController = withCompController<
  WFacebookCommentMapperProps,
  WFacebookCommentControllerProps,
  WFacebookCommentProps,
  WFacebookCommentStateRefs
>(({ stateValues, mapperProps }) => {
  const { urlFormat, externalBaseUrl, isHttpsEnabled, ...restProps } =
    mapperProps;

  const { pageId, relativeUrl, currentUrlWithQueryParams } = stateValues;

  const socialUrl = getSocialUrl({
    urlFormat,
    isMasterPage: false,
    pageId,
    relativeUrl,
    externalBaseUrl,
    isHttpsEnabled,
    currentUrl: currentUrlWithQueryParams,
  });

  return {
    ...restProps,
    socialUrl,
  };
});

export default compController;
