import {
  withCompInfo,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';
import {
  RadioGroupProps as RadioGroupPlatformProps,
  RadioGroupDefinition,
} from '@wix/thunderbolt-components';

export const props = withCompInfo<
  RadioGroupPlatformProps,
  RadioGroupDefinition,
  RadioGroupPlatformProps
>()([], (_deps, carmiData) => {
  return carmiData;
});

export default createComponentMapperModel({ props });
