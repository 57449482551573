import { withCompController } from '@wix/editor-elements-integrations';
import { getValidationControllerProps } from '../../../core/commons/controllerUtils';
import type {
  ICheckboxGroupMapperProps,
  ICheckboxGroupControllerProps,
  ICheckboxGroupProps,
} from '../CheckboxGroup.types';

export default withCompController<
  ICheckboxGroupMapperProps,
  ICheckboxGroupControllerProps,
  ICheckboxGroupProps
>(({ mapperProps, controllerUtils }) => {
  const { updateProps } = controllerUtils;
  const { setValidityIndication } = getValidationControllerProps(updateProps);

  return {
    ...mapperProps,
    onOptionsChange: options => {
      updateProps({
        options,
      });
    },
    setValidityIndication,
  };
});
