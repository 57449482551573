import {
  createComponentMapperModel,
  withCompInfo,
} from '@wix/editor-elements-integrations';
import {
  IAccordionContainerDefinition,
  IAccordionContainerMapperProps,
  IAccordionContainerCarmiProps,
  IRawItem,
} from '../types';
import { DefaultOpened } from '../constants';

const calcOpened = (items: Array<IRawItem>, defaultOpened: DefaultOpened) => {
  const ids = items.map(({ id }) => id);

  switch (defaultOpened) {
    case DefaultOpened.OPEN_ALL:
      return ids;
    case DefaultOpened.OPEN_FIRST:
      return [ids[0]];
    case DefaultOpened.CLOSE_ALL:
    default:
      return [];
  }
};

export const props = withCompInfo<
  IAccordionContainerMapperProps,
  IAccordionContainerDefinition,
  IAccordionContainerCarmiProps
>()(
  ['compData', 'compProps', 'compStylableClass'],
  ({ compData, compProps, compStylableClass }, { containerRootClassName }) => {
    return {
      ...compData,
      ...compProps,
      multiple:
        compProps.defaultOpened === DefaultOpened.OPEN_ALL ||
        compProps.multiple,
      opened: calcOpened(compData.items, compProps.defaultOpened),
      stylableClassName: compStylableClass,
      containerRootClassName,
    } as IAccordionContainerMapperProps;
  },
);

export const css = withCompInfo<any, IAccordionContainerDefinition>()(
  ['compProps'],
  ({ compProps: { animationTime } }) => ({
    height: 'auto',
    'min-width': 'min-content',
    '--animation-time': animationTime,
  }),
);

export default createComponentMapperModel({ props, css });
