import React from 'react';
import { IWTwitterTweetProps } from '../WTwitterTweet.types';
import { WTwitterContent } from '../../WTwitterContent/viewer/WTwitterContent';
import { WidgetType } from '../../WTwitterContent/constants';
import { toQueryString } from '../../../core/commons/urlUtils';
import { getDataAttributes } from '../../../core/commons/utils';
import { COMPONENT_LABEL, TITLE_NAME, TWITTER_URL } from './constants';

const WTwitterTweet: React.FC<IWTwitterTweetProps> = props => {
  const { id, className, translate, onMouseEnter, onMouseLeave } = props;

  const title = translate!(COMPONENT_LABEL, TITLE_NAME, TITLE_NAME);
  const queryString = toQueryString({
    href: TWITTER_URL,
    lang: props.lang,
    url: props.socialUrl,
    text: props.defaultText,
    related: props.accountToFollow,
    compId: props.id,
    origin: props.currentUrl,
    widgetType: WidgetType.TWEET,
    ...(props.isMobileView ? { size: 'l' } : {}),
  });

  return (
    <WTwitterContent
      {...{
        id,
        ...getDataAttributes(props),
        className,
        title,
        widgetType: WidgetType.TWEET,
        queryString,
        width: props.width,
        height: props.height,
        onMouseEnter,
        onMouseLeave,
      }}
    />
  );
};

export default WTwitterTweet;
