import {
  createComponentMapperModel,
  withCompInfo,
} from '@wix/editor-elements-integrations';
import {
  Tpa3DGalleryDefinition,
  TPA3DGalleryPropsPlatformProps,
  Tpa3DGalleryPropsCarmiData,
  Tpa3DGalleryCSSVars,
} from '../TPA3DGallery.types';
import {
  generateCss,
  propsResolver,
  generateSdkData,
  propsDependencies,
} from '../../TPAGallery/viewer/TPAGalleryMapperUtil';

export const props = withCompInfo<
  TPA3DGalleryPropsPlatformProps,
  Tpa3DGalleryDefinition,
  Tpa3DGalleryPropsCarmiData
>()(propsDependencies, (data, carmiProps) => ({
  ...propsResolver<Tpa3DGalleryDefinition>(
    data,
    carmiProps,
    'Slicebox/Slicebox.html',
  ),
  currentIndex: 0,
}));

const sdkData = generateSdkData<Tpa3DGalleryDefinition>();

const css = generateCss<Tpa3DGalleryCSSVars, Tpa3DGalleryDefinition>();

export default createComponentMapperModel({ css, props, sdkData });
