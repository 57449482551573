import scopedObjBuilder from '../../scopedObjBuilder';
import { ThumbnailData } from './types';

export const shareButtonsBaseId = 'wixui_ShareButtons';
const designPanelBaseId = 'StylablePanel';

const shareButtonsScopedObj = scopedObjBuilder(shareButtonsBaseId);
const designPanelScopedObj = scopedObjBuilder(designPanelBaseId);

export const TranslationKeys = {
  ...shareButtonsScopedObj('gfpp', ['manage', 'settings']),
  ...shareButtonsScopedObj('layout', [
    'panel_header',
    'arrangement_title',
    'horizontal_thumbnail_label',
    'vertical_thumbnail_label',
    'horizontal_thumbnail_tooltip',
    'vertical_thumbnail_tooltip',
    'buttons_show_title',
    'icon_position_title',
    'show_heading_title',
    'spacing_section_divider',
    'spacing_heading_buttons_title',
    'spacing_icon_text_title',
    'spacing_buttons_title',
    'spacing_rows',
    'icon_left_tooltip',
    'icon_right_tooltip',
    'icon_above_tooltip',
    'icon_below_tooltip',
    'buttons_show_dropdown_text_icon',
    'buttons_show_dropdown_text',
    'buttons_show_dropdown_icon',
    'buttons_show_dropdown_nothing',
    'direction_label',
    'direction_LTR',
    'direction_RTL',
    'direction_tooltip',
  ]),
  ...shareButtonsScopedObj('settings', [
    'empty_state_title',
    'empty_state_body',
    'panel_header',
    'share_page_title',
    'homepage_label',
    'current_label',
  ]),
  ...designPanelScopedObj('Design', [
    'Fills_Tab_Title',
    'Borders_Tab_Title',
    'Corners_Tab_Title',
    'Shadows_Tab_Title',
    'Text_Tab_Title',
    'Layout_Tab_Title',
    'Icon_Tab_Title',
    'State_Dropdown_Regular',
    'State_Dropdown_Hover',
  ]),
};

export const toastTextKey = 'toast_link_copied';

export const Ids = {
  facebook: `${shareButtonsBaseId}_facebook`,
  twitter: `${shareButtonsBaseId}_twitter`,
  linkedin: `${shareButtonsBaseId}_linkedin`,
  pinterest: `${shareButtonsBaseId}_pinterest`,
  whatsapp: `${shareButtonsBaseId}_whatsapp`,
  copyButton: `${shareButtonsBaseId}_copyButton`,
};

export const mediaIds = {
  facebook: 'Shares on Facebook',
  twitter: 'Shares on Twitter',
  whatsapp: 'Shares on Whatsapp',
  linkedin: 'Shares on LinkedIn',
  pinterest: 'Shares on Pinterest',
  copyButton: 'Copies Link',
};

export const HelpIds = {
  // TODO: fix to the correct id
  gfpp: '4136a3fa-caa3-4f0f-9ace-8d08c4ecd893',
};

export const TestIds = {
  buttonsContainer: `${shareButtonsBaseId}-buttonsContainer`,
  buttonElement: `${shareButtonsBaseId}-buttonElement`,
};

export const defaultLabels: { [key: string]: string } = {
  [Ids.facebook]: 'Facebook',
  [Ids.twitter]: 'Twitter',
  [Ids.linkedin]: 'LinkedIn',
  [Ids.pinterest]: 'Pinterest',
  [Ids.whatsapp]: 'WhatsApp',
};

export const shareUrlPrefixes: { [key: string]: string } = {
  [mediaIds.facebook]: 'https://facebook.com/sharer/sharer.php?u=',
  [mediaIds.twitter]: 'https://twitter.com/intent/tweet?url=',
  [mediaIds.linkedin]: 'https://www.linkedin.com/shareArticle?mini=true&url=',
  [mediaIds.pinterest]: 'https://pinterest.com/pin/create/button/?url=',
  [mediaIds.whatsapp]: 'https://api.whatsapp.com/send/?phone&text=',
  [mediaIds.copyButton]: '',
};

// Todo: Remove once the presets are ready
export const shareIcons: { [key: string]: string } = {
  [Ids.facebook]:
    '<svg viewBox="0 0 20 20"><path fill-rule="evenodd" clip-rule="evenodd" d="M19.2857 9.64286C19.2857 4.31759 14.9681 0 9.64286 0C4.31759 0 0 4.31759 0 9.64286C0 14.4558 3.52688 18.4456 8.13616 19.1688V12.4308H5.68808V9.64286H8.13616V7.51902C8.13616 5.10228 9.57536 3.76674 11.7788 3.76674C12.8334 3.76674 13.9375 3.95478 13.9375 3.95478V6.32813H12.7213C11.5232 6.32813 11.1496 7.07183 11.1496 7.83482V9.64286H13.8242L13.3963 12.4308H11.1496V19.1688C15.76 18.4456 19.2857 14.4558 19.2857 9.64286Z" fill="black"/></svg>',
  [Ids.twitter]:
    '<svg viewBox="0 -3 20 20"><path fill-rule="evenodd" clip-rule="evenodd" d="M17.0233 2.50875C17.8465 2.00612 18.4613 1.22022 18.7578 0.293303C17.9827 0.759776 17.1354 1.08884 16.2506 1.26723C15.1393 0.0666959 13.4168 -0.323839 11.9065 0.28125C10.395 0.885134 9.40179 2.3629 9.39817 4.01062C9.40058 4.31679 9.43433 4.62295 9.50183 4.92187C6.32089 4.76036 3.35692 3.23679 1.35 0.730848C0.993214 1.34196 0.807589 2.03746 0.81 2.74741C0.696696 4.03955 1.24513 5.29915 2.26246 6.08625C1.55129 5.98138 0.849777 5.81384 0.167545 5.58362V5.63424C0.30375 7.59897 1.73812 9.21897 3.65103 9.56611C3.36415 9.66013 3.06522 9.70835 2.76388 9.70714C2.53848 9.70835 2.31549 9.68303 2.09491 9.63482C2.62647 11.2729 4.12353 12.3915 5.82429 12.4204C4.42969 13.5366 2.70241 14.1417 0.925714 14.1356C0.615938 14.1368 0.307366 14.1187 0 14.0802C3.4654 16.3294 7.86496 16.4837 11.475 14.4828C15.0863 12.4807 17.3306 8.64281 17.3318 4.47228C17.3318 4.2987 17.3186 4.12634 17.3186 3.95156C18.0912 3.38504 18.7566 2.68232 19.2857 1.87835C18.5661 2.20259 17.8031 2.41473 17.0233 2.50875Z" fill="black"/></svg>',
  [Ids.linkedin]:
    '<svg viewBox="-2 -2 20 20"><path fill-rule="evenodd" clip-rule="evenodd" d="M14.816 0H1.18C0.529 0 0 0.517 0 1.154V14.846C0 15.482 0.529 16 1.18 16H14.816C15.469 16 16 15.482 16 14.846V1.154C16 0.517 15.469 0 14.816 0ZM2.371 13.634H4.745V5.998H2.371V13.634ZM3.558 4.955C2.797 4.955 2.183 4.338 2.183 3.579C2.183 2.819 2.797 2.203 3.558 2.203C4.316 2.203 4.933 2.819 4.933 3.579C4.933 4.338 4.316 4.955 3.558 4.955ZM13.634 13.634H11.262V9.92C11.262 9.034 11.247 7.896 10.029 7.896C8.794 7.896 8.606 8.861 8.606 9.856V13.634H6.233V5.998H8.509V7.042H8.542C8.859 6.442 9.633 5.809 10.788 5.809C13.191 5.809 13.634 7.39 13.634 9.446V13.634Z" fill="black"/></svg>',
  [Ids.pinterest]:
    '<svg viewBox="-2 -2 20 20"><path d="M8.00057 0C3.5821 0 0 3.58173 0 7.99927C0 11.2759 1.96953 14.0899 4.78892 15.3271C4.76506 14.7681 4.7837 14.0982 4.9269 13.4906C5.08155 12.8408 5.95737 9.13064 5.95737 9.13064C5.95737 9.13064 5.70209 8.61983 5.70209 7.86538C5.70209 6.67955 6.38904 5.79434 7.24405 5.79434C7.97254 5.79434 8.32325 6.34124 8.32325 6.99526C8.32325 7.72634 7.85736 8.82113 7.61763 9.83419C7.41738 10.683 8.04307 11.3755 8.87945 11.3755C10.3955 11.3755 11.4166 9.42791 11.4166 7.12185C11.4166 5.36889 10.2357 4.05642 8.08771 4.05642C5.66055 4.05642 4.14968 5.86595 4.14968 7.88715C4.14968 8.58451 4.35413 9.07561 4.67684 9.45593C4.82424 9.6313 4.84498 9.70187 4.79102 9.90217C4.75261 10.0495 4.66543 10.4039 4.62808 10.544C4.57509 10.7469 4.41118 10.819 4.22753 10.7448C3.10998 10.2876 2.59007 9.0647 2.59007 7.68845C2.59007 5.41556 4.50668 2.69103 8.30667 2.69103C11.3615 2.69103 13.3726 4.90062 13.3726 7.27331C13.3726 10.4116 11.6272 12.7567 9.05484 12.7567C8.19147 12.7567 7.37896 12.2891 7.10085 11.7589C7.10085 11.7589 6.63599 13.6027 6.5385 13.9581C6.3683 14.5745 6.03622 15.1917 5.73318 15.6721C6.45235 15.8843 7.21293 16 8.00057 16C12.419 16 16 12.4183 16 7.9992C16 3.58173 12.419 0 8.00057 0Z" fill="black"/></svg>',
  [Ids.whatsapp]:
    '<svg viewBox="-2 -2 20 20"><path d="M16 7.79418C16 12.0985 12.4835 15.5878 8.14478 15.5878C6.76793 15.5878 5.47455 15.2357 4.34865 14.619L0 16L1.41755 11.8173C0.702775 10.6434 0.291127 9.26494 0.291127 7.79365C0.291649 3.48935 3.80709 0 8.14582 0C12.484 0.00104347 16 3.48988 16 7.79418ZM8.14426 1.24277C4.50308 1.24277 1.5412 4.18222 1.5412 7.79574C1.5412 9.22947 2.00867 10.5568 2.7991 11.6367L1.97476 14.0696L4.51143 13.2635C5.5549 13.948 6.8034 14.3466 8.14426 14.3466C11.7854 14.3466 14.7484 11.4077 14.7484 7.79418C14.7494 4.18222 11.786 1.24277 8.14426 1.24277ZM12.1115 9.58946C12.0625 9.51068 11.9341 9.46268 11.7421 9.36668C11.5507 9.27068 10.6027 8.80895 10.4268 8.74634C10.2495 8.68217 10.1211 8.64982 9.99276 8.8413C9.86598 9.03277 9.49659 9.46268 9.38338 9.59103C9.2712 9.71885 9.15903 9.73503 8.96651 9.64059C8.77399 9.54355 8.15313 9.3432 7.41748 8.6926C6.84514 8.18548 6.45854 7.56096 6.34689 7.36949C6.23367 7.17853 6.33541 7.07523 6.43089 6.98027C6.51697 6.89419 6.62341 6.75697 6.71993 6.6448C6.81645 6.53367 6.84827 6.45489 6.91245 6.32706C6.97558 6.19924 6.94375 6.08811 6.89627 5.99159C6.84827 5.89559 6.46376 4.95647 6.30254 4.57404C6.14237 4.19213 5.9822 4.25526 5.8695 4.25526C5.75733 4.25526 5.62898 4.23961 5.50064 4.23961C5.37229 4.23961 5.1636 4.28604 4.98777 4.47752C4.81195 4.66899 4.31421 5.13073 4.31421 6.07089C4.31421 7.0121 5.00342 7.91939 5.09994 8.0467C5.19699 8.17348 6.43245 10.166 8.38947 10.9308C10.3475 11.6952 10.3475 11.44 10.7002 11.4077C11.054 11.3754 11.8397 10.946 11.9993 10.5004C12.16 10.0528 12.16 9.66929 12.1115 9.58946Z" fill="black"/></svg>',
  [Ids.copyButton]:
    '<svg viewBox="2 2 20 20"><path fill-rule="evenodd" clip-rule="evenodd" d="M7.786 10.3592C9.4 8.7462 12.026 8.7462 13.64 10.3592L14.069 10.7892L13.362 11.4962L12.933 11.0662C11.709 9.8452 9.718 9.8432 8.493 11.0662L5.92 13.6412C5.327 14.2332 5 15.0212 5 15.8602C5 16.6992 5.327 17.4872 5.92 18.0802C7.106 19.2662 9.173 19.2662 10.36 18.0802L11.943 16.4982L12.65 17.2052L11.067 18.7872C10.285 19.5692 9.246 20.0002 8.14 20.0002C7.034 20.0002 5.994 19.5692 5.213 18.7872C4.431 18.0062 4 16.9662 4 15.8602C4 14.7552 4.431 13.7152 5.213 12.9342L7.786 10.3592ZM15.8607 4C16.9667 4 18.0057 4.431 18.7877 5.213C19.5697 5.994 19.9997 7.034 19.9997 8.14C19.9997 9.245 19.5697 10.285 18.7877 11.067L16.2137 13.641C15.4067 14.447 14.3467 14.851 13.2867 14.851C12.2267 14.851 11.1667 14.447 10.3597 13.641L9.9307 13.211L10.6377 12.504L11.0667 12.934C12.2927 14.157 14.2837 14.158 15.5067 12.934L18.0807 10.359C18.6737 9.767 18.9997 8.979 18.9997 8.14C18.9997 7.301 18.6737 6.513 18.0807 5.92C16.8937 4.734 14.8267 4.734 13.6407 5.92L12.0577 7.502L11.3507 6.795L12.9337 5.213C13.7147 4.431 14.7547 4 15.8607 4Z" fill="black"/></svg>',
};

export const noButtonsErrorMessage =
  'ShareButtons: `buttons` prop must contain at least one button.';

export const designPanelIds = {
  fill: `${shareButtonsBaseId}_fill`,
  borders: `${shareButtonsBaseId}_borders`,
  corners: `${shareButtonsBaseId}_corners`,
  shadow: `${shareButtonsBaseId}_shadow`,
  text: `${shareButtonsBaseId}_text`,
  layout: `${shareButtonsBaseId}_layout`,
  icon: `${shareButtonsBaseId}_icon`,
};

export const designPanelSelectors = {
  button: '.root::button',
  buttonIcon: '.root::button::icon',
  buttonIconHover: '.root::button:hover::icon',
  buttonLabel: '.root::button::label',
  buttonLabelHover: '.root::button:hover::label',
};

export const designPanelStateSelectors = {
  button: {
    regular: designPanelSelectors.button,
    hover: `${designPanelSelectors.button}:hover`,
  },
};

export const buttonsAlignmentThumbnails: Array<ThumbnailData> = [
  {
    illustration: 'Thumbnails_Vertically',
    value: 'horizontal',
    label: TranslationKeys.layout.horizontal_thumbnail_label,
    tooltipText: TranslationKeys.layout.horizontal_thumbnail_tooltip,
  },
  {
    illustration: 'Thumbnails_Horizontally',
    value: 'vertical',
    label: TranslationKeys.layout.vertical_thumbnail_label,
    tooltipText: TranslationKeys.layout.vertical_thumbnail_tooltip,
  },
];

export const iconPositionThumbnails: Array<ThumbnailData> = [
  {
    illustration: 'ButtonDirectionRowReverse',
    value: 'left',
    tooltipText: TranslationKeys.layout.icon_left_tooltip,
  },
  {
    illustration: 'ButtonDirectionRow',
    value: 'right',
    tooltipText: TranslationKeys.layout.icon_right_tooltip,
  },
  {
    illustration: 'ButtonDirectionColumnReverse',
    value: 'top',
    tooltipText: TranslationKeys.layout.icon_above_tooltip,
  },
  {
    illustration: 'ButtonDirectionColumn',
    value: 'bottom',
    tooltipText: TranslationKeys.layout.icon_below_tooltip,
  },
];

export const horizontalDirectionThumbnails: Array<ThumbnailData> = [
  {
    illustration: 'leftToRight38',
    value: 'ltr',
    label: TranslationKeys.layout.direction_LTR,
  },
  {
    illustration: 'rightToLeft38',
    value: 'rtl',
    label: TranslationKeys.layout.direction_RTL,
  },
];

export const sharerData = {
  name: 'wix_share_button',
  params: 'width = 635, height = 346',
};

export enum PagesToShare {
  home = 'home',
  current = 'current',
}

export const COPY_CONFIRMATION_TIMEOUT = 5000;
