export const translationFeature = 'dialogMixinTranslations';

export const ResetPasswordDialogTranslationKeys = {
  title: 'SMResetPassMail_title',
  subtitle: 'dialogMixinTranslations_RESET_PASSWORD_TEXT',
  buttonText: 'dialogMixinTranslations_RESET_PASSWORD_BUTTON',
  passwordInputText: 'SMResetPass_New_Password',
  retypePasswordInputText: 'SMResetPass_Retype_Password',
  policies: {
    termsOfUseLink: 'SMForm_Policies_TermsOfUse_new',
    privacyLink: 'SMForm_Policies_PrivacyPolicy_new',
    and: 'SMForm_Policies_And_new',
  },
};

export const testIds = {
  headline: 'resetPassword.headline',
  reset: 'resetPassword.reset',
  policies: {
    privacyPolicyLink: 'resetPassword.privacyPolicyLink',
    and: 'resetPassword.policiesAnd',
    termsOfUseLink: 'resetPassword.termsOfUseLink',
  },
};
