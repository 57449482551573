import { MenuMode } from '../../../../menuModeConstants';
import type { PositionUpdaters } from './types';
import { getCss, setCss, clearCss } from './helpers';
import { setVerticalPosition } from './setVerticalPosition';

export const createDepth0PositionUpdaters_column =
  (menuMode: MenuMode): PositionUpdaters =>
  ({ label, positionBox, isStretched }) => ({
    onEnter: () => {
      const labelMarginToCompensate =
        menuMode === 'wrap' ? getCss(label, 'margin-bottom') : '0';

      setVerticalPosition(
        { label, positionBox },
        'column',
        parseInt(labelMarginToCompensate, 10),
      );

      if (isStretched) {
        const { left = 0, right = 0 } =
          label.closest('nav')?.getBoundingClientRect() || {};
        setCss(positionBox, 'left', -left, 'px');
        setCss(positionBox, 'right', -(window.innerWidth - right), 'px');
      }
    },
    onLeave: () => {
      clearCss(positionBox);
    },
  });
