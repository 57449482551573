import { createComponentPreviewEntry } from '@wix/editor-elements-integrations';
import { withComponentPreview } from '@wix/editor-elements-preview-utils';
import React from 'react';
import {
  ScrollableContainerTypes,
  withScrollAPIForResponsiveContainer,
} from '../../../preview-utils/scrollablePreviewWrapper';

export default (
  ViewerComponent: React.ComponentType<ScrollableContainerTypes>,
) =>
  createComponentPreviewEntry(
    withComponentPreview(withScrollAPIForResponsiveContainer(ViewerComponent)),
  );
