import { withCompController } from '@wix/editor-elements-integrations';
import type {
  ISingleTabProps,
  ISingleTabMapperProps,
  ISingleTabControllerProps,
  ISingleTabStateValues,
} from '../../../components/SingleTab/SingleTab.props';

export default withCompController<
  ISingleTabMapperProps,
  ISingleTabControllerProps,
  ISingleTabProps,
  ISingleTabStateValues
>(({ mapperProps, controllerUtils, stateValues }) => {
  const { editorType } = stateValues;
  return {
    ...mapperProps,
    editorType,
    updateComponentPropsInViewer: props => {
      controllerUtils.updateProps(props);
    },
  };
});
