import {
  withCompInfo,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';
import { RSSButtonPropsCarmiData } from '@wix/thunderbolt-components';
import { RSSButtonDefinition } from '@wix/thunderbolt-components-native';

export const props = withCompInfo<
  any,
  RSSButtonDefinition,
  RSSButtonPropsCarmiData
>()([], (_, carmiData) => {
  const link = carmiData.link;
  if (carmiData.image && link) {
    link.href = `./feed.xml`;
  }
  return {
    ...carmiData,
    link,
  };
});

export default createComponentMapperModel({
  props,
});
