import {
  withCompInfo,
  createComponentMapperModel,
  withStateRefsValues,
} from '@wix/editor-elements-integrations';

import {
  RichTextBoxDefinition,
  IRichTextBoxMapperProps,
  RichTextBoxCSSVars,
  RichTextCarmiData,
  IRichTextStateRefs,
} from '../RichTextBox.types';
import { getLabelPadding } from '../../../core/commons/inputUtils';

const getToolbarPosition = (
  toolbarPosition: IRichTextBoxMapperProps['toolbarPosition'],
  isMobileView: boolean,
): IRichTextBoxMapperProps['toolbarPosition'] => {
  if (isMobileView) {
    return 'bottom';
  }

  return toolbarPosition;
};

export const props = withCompInfo<
  IRichTextBoxMapperProps,
  RichTextBoxDefinition
>()(
  ['compData', 'compProps', 'isMobileView', 'compId'],
  ({ compData, compProps, isMobileView, compId }) => {
    const toolbarPosition = getToolbarPosition(
      compProps.toolbarPosition,
      isMobileView,
    );
    const result: IRichTextBoxMapperProps = {
      compId,
      linkModalId: `linkModal_${compId}`,
      isMobileView,
      label: compProps.label,
      required: compProps.required,
      allowLinks: compProps.allowLinks,
      placeholder: compProps.placeholder,
      value: compData.value,
      textAlignment: compProps.textAlignment,
      toolbarPosition,
      readOnly: compProps.readOnly || false,
      isDisabled: compProps.isDisabled || false,
      maxLength: compData.maxLength || undefined,
    };

    return result;
  },
);

export const css = withCompInfo<
  RichTextBoxCSSVars,
  RichTextBoxDefinition,
  RichTextCarmiData
>()(
  ['compProps', 'styleProperties'],
  ({ compProps, styleProperties }, carmiData) => {
    const textPadding =
      compProps.textPadding && compProps.textPadding > 0
        ? compProps.textPadding
        : 0;
    const borderRadius = styleProperties.rd.split(' ');
    // santa-editor default value doesn't include px, so we have to add it :(
    // https://github.com/wix-private/santa-editor/blob/master/editor-add-panel/addPanelSectionsEN/sections/developer/richTextBoxSection.js#L89
    const borderWidth = styleProperties.brw.includes('px')
      ? styleProperties.brw
      : `${styleProperties.brw}px`;

    return {
      '--fontSize': `${carmiData.font.size}px`,
      '--dir': compProps.textAlignment === 'right' ? 'rtl' : 'ltr',
      '--textAlign': compProps.textAlignment,
      '--labelPadding': getLabelPadding(compProps),
      '--labelMarginBottom': `${compProps.labelMargin}px`,
      '--textPadding': `${textPadding}px`,
      '--textPaddingTop': `${textPadding / 2}px`,
      '--toolbar-top-radius': `${borderRadius[0]} ${borderRadius[1]} 0 0`,
      '--toolbar-bottom-radius': `0 0 ${borderRadius[2]} ${borderRadius[3]}`,
      '--brw': borderWidth,
    };
  },
);

export const stateRefs = withStateRefsValues<keyof IRichTextStateRefs>([
  'setSiteScrollingBlocked',
  'enableCyclicTabbing',
  'disableCyclicTabbing',
]);

export default createComponentMapperModel({ props, css, stateRefs });
