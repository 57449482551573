import {
  withCompInfo,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';
import {
  CheckboxGroupProps as CheckboxGroupPlatformProps,
  CheckboxGroupDefinition,
} from '@wix/thunderbolt-components';

export const props = withCompInfo<
  CheckboxGroupPlatformProps,
  CheckboxGroupDefinition,
  CheckboxGroupPlatformProps
>()([], (_deps, carmiData) => {
  return carmiData;
});

export default createComponentMapperModel({ props });
