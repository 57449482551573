import { withCompController } from '@wix/editor-elements-integrations';
import {
  WFacebookLikeMapperProps,
  WFacebookLikeControllerProps,
  IWFacebookLikeProps,
  WFacebookLikeStateRefs,
} from '../WFacebookLike.types';
import { getSocialUrl } from '../../../core/commons/controllerUtils';

const compController = withCompController<
  WFacebookLikeMapperProps,
  WFacebookLikeControllerProps,
  IWFacebookLikeProps,
  WFacebookLikeStateRefs
>(({ stateValues, mapperProps }) => {
  const {
    urlFormat,
    isMasterPage,
    externalBaseUrl,
    isHttpsEnabled,
    ...restProps
  } = mapperProps;

  const { pageId, relativeUrl, currentUrlWithQueryParams } = stateValues;

  const socialUrl = getSocialUrl({
    urlFormat,
    isMasterPage,
    pageId,
    relativeUrl,
    externalBaseUrl,
    isHttpsEnabled,
    currentUrl: isMasterPage ? externalBaseUrl : currentUrlWithQueryParams,
  });

  return {
    ...restProps,
    socialUrl,
  };
});

export default compController;
