import {
  withCompInfo,
  createComponentMapperModel,
  withStateRefsValues,
} from '@wix/editor-elements-integrations';
import { VerticalMenuDefinition } from '@wix/thunderbolt-components';
import {
  VerticalMenuProps,
  VerticalMenuStateRefs,
} from '../VerticalMenu.types';

export const props = withCompInfo<
  VerticalMenuProps,
  VerticalMenuDefinition,
  any
>()([], (_deps, carmiData) => {
  return carmiData;
});

export const stateRefs = withStateRefsValues<keyof VerticalMenuStateRefs>([
  'currentUrl',
]);

export default createComponentMapperModel({ props, stateRefs });
