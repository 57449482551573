import { withCompController } from '@wix/editor-elements-integrations';
import {
  DisqusCommentsMapperProps,
  DisqusCommentsStateRefs,
  DisqusCommentsControllerProps,
} from '../DisqusComments.types';

const compController = withCompController<
  DisqusCommentsControllerProps,
  DisqusCommentsMapperProps,
  never,
  DisqusCommentsStateRefs
>(({ stateValues, mapperProps }) => {
  return {
    ...mapperProps,
    currentUrl: stateValues.currentUrlWithQueryParams,
  };
});

export default compController;
