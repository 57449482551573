
  var rt = require("@stylable/runtime/dist/css-runtime-stylesheet.js");

  module.exports = rt.create(
      "ShareButtonscomponent2477460758",
      {"classes":{"root":"ShareButtonscomponent2477460758__root","container":"ShareButtonscomponent2477460758__container","button":"ShareButtonscomponent2477460758__button"},"vars":{},"stVars":{"buttons-spacing":"var(--buttons-spacing)","rows-spacing":"var(--rows-spacing)"},"keyframes":{},"layers":{}},
      "",
      -1,
      module.id
  );
  