import { createComponentPreviewMapperModel } from '@wix/editor-elements-integrations';
import { composeMapper } from '@wix/editor-elements-preview-utils';
import type { DefaultCompPlatformProps } from '@wix/editor-elements-types/thunderbolt';
import type { PreviewWrapperProps } from '@wix/editor-elements-types/thunderboltPreview';
import {
  VerticalMenuDefinition,
  VerticalMenuProps as VerticalMenuCarmiData,
} from '@wix/thunderbolt-components';

import { VerticalMenuProps } from '../VerticalMenu.types';
import { props as viewerMapperProps } from './VerticalMenu.mapper';

const props = composeMapper<
  PreviewWrapperProps<VerticalMenuProps>,
  VerticalMenuDefinition,
  VerticalMenuCarmiData & DefaultCompPlatformProps
>()(
  viewerMapperProps,
  { add: ['compPreviewState'] },
  (resolver, dependencies, carmiData) => {
    const { compPreviewState } = dependencies;
    const viewerProps = resolver(dependencies, carmiData);

    return {
      ...viewerProps,
      previewWrapperProps: { compPreviewState },
    };
  },
);

export default createComponentPreviewMapperModel({
  props,
});
