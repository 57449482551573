import type {
  IStylableHorizontalMenuUITypeProps,
  ISubmenuProps,
} from '../../../../StylableHorizontalMenu.types';
import { createSubmenuProps_flyout } from './submenuProps_flyout';
import { createSubmenuProps_column } from './submenuProps_column';

export const createSubmenuProps_flyoutAndColumn = (
  props: IStylableHorizontalMenuUITypeProps,
): ISubmenuProps => ({
  ...createSubmenuProps_column(props),
  ...createSubmenuProps_flyout(props),
});
