import React from 'react';
import { mediaIds, TestIds, sharerData } from '../constants';
import { ButtonContentWrapperProps } from '../types';
import CopyButtonWrapper from './CopyButtonWrapper';
import { classes, st } from './style/ShareButton.component.st.css';
import { classes as shareButtonsClasses } from './style/ShareButtons.component.st.css';

const ButtonContentWrapper: React.FC<ButtonContentWrapperProps> = ({
  id,
  children,
  mediaId,
  iconPosition,
  url,
  ariaLabel,
  onCopyButtonClicked,
}) => (
  <>
    {mediaId === mediaIds.copyButton ? (
      <CopyButtonWrapper
        id={id}
        iconPosition={iconPosition}
        url={url}
        ariaLabel={ariaLabel}
        onCopyButtonClicked={onCopyButtonClicked}
      >
        {children}
      </CopyButtonWrapper>
    ) : (
      <a
        id={id}
        className={st(
          classes.root,
          { iconPosition },
          shareButtonsClasses.button,
        )}
        href={url}
        target="popup"
        onClick={() => window.open(url, sharerData.name, sharerData.params)}
        rel="noreferrer"
        data-testid={TestIds.buttonElement}
        aria-label={ariaLabel}
      >
        {children}
      </a>
    )}
  </>
);

export default ButtonContentWrapper;
