import * as React from 'react';
import classNames from 'classnames';
import MediaContainerContent from '../../MediaContainer/viewer/MediaContainerContent';
import mediaContainerStyles from '../../MediaContainer/viewer/styles/MediaContainer.scss';
import { HoverBoxCompProps } from '../HoverBox.types';
import { useIsomorphicLayoutEffect } from '../../../../providers/useIsomorphicLayoutEffect';
import { useVideoAPI } from '../../../../core/useVideoAPI';
import { MediaContainerVideoAPI } from '../../MediaContainer/MediaContainer.types';
import { getDataAttributes } from '../../../../core/commons/utils';
import styles from './styles/HoverBox.scss';

const HoverBox: React.ForwardRefRenderFunction<
  MediaContainerVideoAPI,
  HoverBoxCompProps
> = (props: HoverBoxCompProps, compRef) => {
  const {
    id,
    className,
    children,
    mode = 'default',
    modeDidUpdate,
    onClick,
    onDblClick: onDoubleClick,
    onMouseEnter,
    onMouseLeave,
    fillLayers,
    onStop,
  } = props;

  useIsomorphicLayoutEffect(() => {
    modeDidUpdate?.(props.mode);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.mode]);

  const hasVideo = !!fillLayers.video;
  const videoRef = useVideoAPI(compRef, hasVideo, onStop);

  return (
    <div
      id={id}
      {...getDataAttributes(props)}
      data-mode={mode}
      className={classNames(className, mediaContainerStyles.mediaContainer)}
      onClick={onClick}
      onDoubleClick={onDoubleClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <div className={styles.container}>
        <MediaContainerContent {...props} videoRef={videoRef}>
          {children}
        </MediaContainerContent>
      </div>
    </div>
  );
};

export default React.forwardRef(HoverBox);
