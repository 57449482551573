import * as React from 'react';
import classNames from 'classnames';
import SiteButtonContent from '../../SiteButtonContent';
import { SkinButtonProps } from '../SkinButton.types';
import {
  ISiteButtonImperativeActions,
  ISiteButtonProps,
} from '../../../SiteButton.types';
import SiteButton from '../../SiteButton';
import skinsStyle from './styles/skins.scss';

const ScotchTapeButtonSkin = React.forwardRef<
  ISiteButtonImperativeActions,
  SkinButtonProps
>(
  (
    { wrapperProps, linkProps, a11yProps, elementType, label, onFocus, onBlur },
    ref,
  ) => {
    return (
      <div
        {...wrapperProps}
        className={classNames(
          skinsStyle.ScotchTapeButton,
          wrapperProps.className,
        )}
      >
        <div className={skinsStyle.left} />
        <SiteButtonContent
          linkProps={linkProps}
          a11yProps={a11yProps}
          elementType={elementType}
          className={skinsStyle.link}
          onFocus={onFocus}
          onBlur={onBlur}
          ref={ref}
        >
          <span className={skinsStyle.label}>{label}</span>
        </SiteButtonContent>
        <div className={skinsStyle.right} />
      </div>
    );
  },
);

const ScotchTapeButton: React.ForwardRefRenderFunction<
  ISiteButtonImperativeActions,
  Omit<ISiteButtonProps, 'skin'>
> = (props, ref) => (
  <SiteButton {...props} skin={ScotchTapeButtonSkin} ref={ref} />
);

export default React.forwardRef(ScotchTapeButton);
