import React, { forwardRef } from 'react';
import { createComponentPreviewEntry } from '@wix/editor-elements-integrations';
import type {
  PreviewWrapperProps,
  IComponentPreviewWrapper,
} from '@wix/editor-elements-types/thunderboltPreview';
import { usePreviewState } from '@wix/editor-elements-preview-utils';
import type {
  IStylableHorizontalMenuUITypeProps,
  IStylableHorizontalMenuImperativeActions,
  IStylableHorizontalMenuPreviewWrapperProps,
} from '../StylableHorizontalMenu.types';
import { Selectors } from './previewWrapperUtils/constants';
import {
  forceOpenIfNeeded,
  useFlyoutAlignmentUpdate,
  useForceHeightAuto,
  useEditSlotId,
  useMinSize,
} from './previewWrapperUtils';
import { classes } from './StylableHorizontalMenu.component.st.css';

export const previewStateMap = {
  [Selectors.scrollButton]: {
    selectors: `.${classes.scrollButton}`,
    type: 'plural',
    state: 'isVisible',
  },
};

const forceHeightAutoStyle = { height: 'auto' };

type IStylableHorizontalMenuUITypePreviewProps =
  IStylableHorizontalMenuUITypeProps & {
    forceState?: { selectionSelector: string };
  };

function withComponentPreview(
  ViewerComponent: React.ComponentType<IStylableHorizontalMenuUITypeProps>,
): IComponentPreviewWrapper<
  IStylableHorizontalMenuUITypePreviewProps,
  IStylableHorizontalMenuPreviewWrapperProps
> {
  return forwardRef<
    IStylableHorizontalMenuImperativeActions,
    PreviewWrapperProps<
      IStylableHorizontalMenuUITypePreviewProps,
      IStylableHorizontalMenuPreviewWrapperProps
    >
  >((props, ref) => {
    const { items, previewWrapperProps, forceState, ...viewerProps } = props;

    const { id, menuMode, submenuMode } = viewerProps;
    const cssSelector = forceState?.selectionSelector || '';
    const { compLayout, compPreviewState } = previewWrapperProps || {};

    const editSlotId = useEditSlotId(compPreviewState);

    usePreviewState(id, cssSelector, previewStateMap);

    useFlyoutAlignmentUpdate(submenuMode === 'flyout');

    const minSize = useMinSize({ id, menuMode });
    const isForceHeightAuto = useForceHeightAuto(menuMode, compLayout);

    return (
      <ViewerComponent
        {...viewerProps}
        items={forceOpenIfNeeded({
          items,
          editSlotId,
          compPreviewState,
          cssSelector,
        })}
        style={isForceHeightAuto ? forceHeightAutoStyle : minSize}
        ref={ref}
      />
    );
  });
}

export default (
  ViewerComponent: React.ComponentType<IStylableHorizontalMenuUITypePreviewProps>,
) =>
  createComponentPreviewEntry<
    IStylableHorizontalMenuUITypePreviewProps,
    IStylableHorizontalMenuPreviewWrapperProps
  >(withComponentPreview(ViewerComponent));
