import {
  withCompInfo,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';
import {
  ComboBoxInputDefinition,
  ComboBoxInputSdkData,
  IComboBoxInputMapperProps,
} from '../ComboBoxInput.types';

export const props = withCompInfo<
  IComboBoxInputMapperProps,
  ComboBoxInputDefinition,
  IComboBoxInputMapperProps
>()(['compProps'], ({ compProps }, carmiData) => {
  return {
    ...carmiData,
    autocomplete: compProps.autocomplete,
    errorMessageType: compProps.errorMessageType,
  };
});

export const sdkData = withCompInfo<
  ComboBoxInputSdkData,
  ComboBoxInputDefinition
>()(['experiments'], ({ experiments }) => {
  const { 'specs.thunderbolt.dropdownOptionStyle': optionStyleExperiment } =
    experiments;
  const isExperimentOpen = (experiment: boolean | string) =>
    experiment && optionStyleExperiment !== 'false';

  return {
    isOptionStyleEnabled: isExperimentOpen(optionStyleExperiment),
  };
});

export default createComponentMapperModel({ props, sdkData });
