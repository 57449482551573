import React from 'react';
import { TestIds } from '../constants';
import type { CopyButtonWrapperProps } from '../types';
import { copyToClipboard } from '../utils/clipBoard';
import { classes, st } from './style/ShareButton.component.st.css';
import { classes as shareButtonsClasses } from './style/ShareButtons.component.st.css';

const CopyButtonWrapper: React.FC<CopyButtonWrapperProps> = ({
  id,
  iconPosition,
  children,
  url,
  ariaLabel,
  onCopyButtonClicked,
}) => {
  const _onClick = () => {
    copyToClipboard(url);
    onCopyButtonClicked();
  };

  return (
    <>
      <button
        id={id}
        className={st(
          classes.root,
          { iconPosition },
          shareButtonsClasses.button,
        )}
        data-testid={TestIds.buttonElement}
        onClick={_onClick}
        aria-label={ariaLabel}
        role="alert"
      >
        {children}
      </button>
    </>
  );
};

export default CopyButtonWrapper;
