import {
  withCompPreviewInfo,
  createComponentPreviewMapperModel,
} from '@wix/editor-elements-integrations';
import type { CompPreviewInfoDependency } from '@wix/editor-elements-types/thunderboltPreview';

import {
  css as mediaContainerCssMapper,
  props as mediaContainerPropsMapper,
} from '../../MediaContainers/MediaContainer/viewer/MediaContainer.previewMapper';

import {
  StripColumnsContainerCss,
  StripColumnsContainerDefinition,
} from '../StripColumnsContainer.types';

const mediaContainerCssDeps = Object.keys(
  mediaContainerCssMapper.deps,
) as Array<CompPreviewInfoDependency>;

const css = withCompPreviewInfo<
  StripColumnsContainerCss,
  StripColumnsContainerDefinition,
  StripColumnsContainerCss
>()(['compProps', ...mediaContainerCssDeps], (deps, carmiData) => {
  const { compProps } = deps;

  const mediaContainerCss = mediaContainerCssMapper.resolver(deps, carmiData);

  const width = !compProps.fullWidth ? { width: 'var(--site-width)' } : {};

  return { ...carmiData, ...mediaContainerCss, ...width };
});

export default createComponentPreviewMapperModel({
  props: mediaContainerPropsMapper,
  css,
});
