import { getDataAttributes } from '@wix/thunderbolt-elements/commons/utils';
import * as React from 'react';
import WSRPopoverMenu from 'wix-style-react/dist/src/PopoverMenu';
import StylableButton from '../../StylableButton/viewer/StylableButton';

import type { IPopoverMenuProps } from '../PopoverMenu.props';
import { st, classes } from './PopoverMenu.component.st.css';

type PopoverMenuTriggerProps = Pick<
  IPopoverMenuProps,
  'id' | 'onClick' | 'icon' | 'popoverLabel'
> & { toggle: () => void };

const PopoverTrigger: React.FC<PopoverMenuTriggerProps> = props => {
  const { id, onClick, icon, popoverLabel, toggle } = props;

  return (
    <StylableButton
      onClick={(...args) => {
        toggle();
        if (onClick) {
          onClick(...args);
        }
      }}
      id={id}
      a11y={{}}
      link={undefined}
      type="button"
      svgString={icon.svgId}
      label={popoverLabel}
      isDisabled={false}
      className={classes.button}
    />
  );
};

type TriggerElementActions = {
  open: () => void;
  close: () => void;
  toggle: () => void;
};

const PopoverMenu: React.FC<IPopoverMenuProps> = props => {
  const { className, id, items, forceOpenPopover, stylableClassName } = props;

  const [triggerElementActions, setTriggerElementActions] =
    React.useState<TriggerElementActions | null>();

  React.useEffect(() => {
    if (!triggerElementActions) {
      return;
    }

    if (forceOpenPopover) {
      triggerElementActions.open();
    } else {
      triggerElementActions.close();
    }
  }, [triggerElementActions, forceOpenPopover]);

  const renderTriggerElement = (params: TriggerElementActions) => {
    if (!triggerElementActions) {
      setTriggerElementActions(params);
    }
    return <PopoverTrigger {...props} toggle={params.toggle} />;
  };

  return (
    <div {...getDataAttributes(props)} id={id} className={className}>
      <WSRPopoverMenu
        appendTo="parent"
        className={st(classes.root, {}, stylableClassName)}
        triggerElement={renderTriggerElement}
      >
        {items.map(({ label }) => (
          <WSRPopoverMenu.MenuItem text={label} />
        ))}
      </WSRPopoverMenu>
    </div>
  );
};

export default PopoverMenu;
