import { useCallback } from 'react';
import { WFacebookCommentControllerProps } from '../../components/WFacebookComment/WFacebookComment.types';
import { getScriptOrLoad } from '../ScriptLoader/ScriptLoader';
import { FacebookSdk } from '../ScriptLoader/load-strategies/FacebookSdkLoader';

const getFacebookSdk = (language = 'en_US'): Promise<FacebookSdk> => {
  return getScriptOrLoad('facebook', {
    language,
    appId: '304553036307597',
    version: 'v2.4',
  });
};

function facebookSdkCallback(language: string) {
  return async (node: any) => {
    if (!node) {
      return;
    }
    const sdk = await getFacebookSdk(language);
    if (sdk && sdk.XFBML && sdk.XFBML.parse) {
      sdk.XFBML.parse(node);
    }
  };
}

export const useFacebookComment = ({
  language,
  colorScheme,
  socialUrl,
  numPosts,
  width,
}: WFacebookCommentControllerProps) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useCallback(facebookSdkCallback(language), [
    colorScheme,
    socialUrl,
    language,
    numPosts,
    width,
  ]);
};

export const useFacebookLikeBox = ({ language }: { language: string }) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useCallback(facebookSdkCallback(language), [language]);
};
