import {
  withCompInfo,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';
import {
  FreemiumBannerResponsiveDefinition,
  FreemiumBannerResponsivePlatformProps,
} from '../FreemiumBannerResponsive.types';

export const props = withCompInfo<
  FreemiumBannerResponsivePlatformProps,
  FreemiumBannerResponsiveDefinition
>()(['direction'], ({ direction }) => {
  return {
    useOverlay: false,
    direction,
    href: `//editorx.com?utm_campaign=vir_editorx_wixad_live`,
  };
});

export default createComponentMapperModel({ props });
