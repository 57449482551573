import classNames from 'classnames';
import * as React from 'react';
import { getDataAttributes } from '../../../core/commons/utils';
import {
  A11Y_TITLE,
  TEST_ID_PLACEHOLDER,
  TRANSLATION_FEATURE,
  TRANSLATION_KEY,
} from '../constants';
import { ISoundCloudWidgetProps } from '../SoundCloudWidget.types';
import style from './style/SoundCloudWidget.scss';

function getValidUrl({
  url,
  autoPlay,
  reducedMotion,
}: Partial<ISoundCloudWidgetProps>): string {
  let validUrl: string = url || '';
  if (validUrl) {
    const ulrInstance = new URL(validUrl);
    ulrInstance.searchParams.set(
      'auto_play',
      !reducedMotion && autoPlay ? 'true' : 'false',
    );
    validUrl = ulrInstance.href;
  }
  return validUrl;
}

const SoundCloudWidget: React.FC<ISoundCloudWidgetProps> = props => {
  const {
    id,
    className,
    url,
    autoPlay,
    translate,
    reducedMotion,
    onMouseEnter,
    onMouseLeave,
  } = props;
  const [validUrl, setValidUrl] = React.useState('');

  React.useEffect(() => {
    setValidUrl(getValidUrl({ autoPlay, reducedMotion, url }));
  }, [autoPlay, reducedMotion, url]);

  if (validUrl) {
    const ariaLabel = translate!(
      TRANSLATION_FEATURE,
      TRANSLATION_KEY,
      A11Y_TITLE,
    );
    return (
      <wix-iframe
        id={id}
        {...getDataAttributes(props)}
        title={A11Y_TITLE}
        className={classNames(className, style.container)}
        data-src={validUrl}
        aria-label={ariaLabel}
        tabIndex={0}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <iframe
          className={style.iframe}
          title={A11Y_TITLE}
          data-src={validUrl}
          /** Autoplay might fail in some browsers without whitelisting this policy
           * Reference: https://developer.mozilla.org/en-US/docs/Web/Media/Autoplay_guide#the_autoplay_feature_policy */
          allow={autoPlay ? "autoplay 'src'" : undefined}
        />
      </wix-iframe>
    );
  }
  return (
    <div
      id={id}
      data-testid={TEST_ID_PLACEHOLDER}
      className={style.iframePlaceholder}
    />
  );
};

export default SoundCloudWidget;
