import { createComponentPreviewMapperModel } from '@wix/editor-elements-integrations';
import { composeMapper } from '@wix/editor-elements-preview-utils';
import type { PreviewWrapperProps } from '@wix/editor-elements-types/thunderboltPreview';
import {
  SiteButtonDefinition,
  SiteButtonProps,
} from '@wix/thunderbolt-components';
import { props as viewerPropsMapper } from './SiteButton.mapper';

const props = composeMapper<
  PreviewWrapperProps<SiteButtonProps>,
  SiteButtonDefinition,
  SiteButtonProps
>()(
  viewerPropsMapper,
  { add: ['compPreviewState'] },
  (resolver, deps, carmiData) => {
    const viewerProps = resolver(deps, carmiData);
    const { compPreviewState } = deps;

    return {
      ...viewerProps,
      previewWrapperProps: { compPreviewState },
    };
  },
);

export default createComponentPreviewMapperModel({ props });
