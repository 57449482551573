import {
  withCompInfo,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';
import {
  PinItPinWidgetProps,
  PinItPinWidgetDefinition,
} from '../PinItPinWidget.types';

const props = withCompInfo<PinItPinWidgetProps, PinItPinWidgetDefinition>()(
  ['compData'],
  ({ compData }) => {
    return { pinUrl: compData.pinId };
  },
);

export default createComponentMapperModel({ props });
