import * as React from 'react';
import Slider, {
  SliderProps as WSRSliderProps,
} from 'wix-style-react/dist/src/Slider';
import type { IRangeSliderProps } from '../../RangeSlider.types';

type ISteppedSliderProps = Pick<
  IRangeSliderProps,
  'min' | 'max' | 'prefix' | 'suffix' | 'width'
> &
  Pick<
    WSRSliderProps,
    | 'step'
    | 'value'
    | 'rtl'
    | 'className'
    | 'dataHook'
    | 'disabled'
    | 'pushable'
    | 'ariaLabelForHandle'
    | 'onChange'
  >;

interface Marks {
  [key: number]: string | number;
}

const maxTickDensity: number = 1 / 8;

const getSliderMarks = (
  min: number,
  max: number,
  prefix: string,
  suffix: string,
  step: WSRSliderProps['step'],
  sliderWidth?: number,
): Marks | undefined => {
  const marks: Marks = {};
  const total = max - min;
  const totalTickCount = step ? total / step : total;
  sliderWidth = sliderWidth ? sliderWidth : 1;
  const density = Math.min(totalTickCount / sliderWidth, maxTickDensity);
  const adjustedStep = parseFloat((total / (sliderWidth * density)).toFixed(1));
  for (let index = min; index <= max; index += adjustedStep) {
    index = parseFloat(index.toFixed(1));
    marks[index] = `${prefix} ${index} ${suffix}`;
  }
  if (!marks[max]) {
    marks[max] = `${prefix} ${max} ${suffix}`;
  }
  return marks;
};

const SteppedSlider: React.FC<ISteppedSliderProps> = props => {
  const {
    className,
    dataHook,
    rtl,
    min,
    max,
    step,
    value,
    prefix,
    suffix,
    disabled,
    width,
    pushable,
    ariaLabelForHandle,
    onChange,
  } = props;
  const marks = React.useMemo(
    () => getSliderMarks(min, max, prefix, suffix, step, width),
    [min, max, prefix, suffix, step, width],
  );

  return (
    <Slider
      className={className}
      dataHook={dataHook}
      rtl={rtl}
      min={min}
      max={max}
      value={value}
      step={step}
      marks={marks}
      displayTooltip={true}
      allowCross={false}
      onChange={onChange}
      disabled={disabled}
      pushable={pushable}
      ariaLabelForHandle={ariaLabelForHandle}
    />
  );
};

export default SteppedSlider;
