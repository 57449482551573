export const getSocialCompBlockingLayerProps = (
  isSocialInteractionAllowed: boolean,
  withTooltip: boolean = true,
) => ({
  blockingLayer: !isSocialInteractionAllowed
    ? {
        enabled: true,
        ...(withTooltip
          ? { tooltipText: 'PREVIEW_TOOLTIP_GOTO_LIVE_SITE' }
          : {}),
      }
    : undefined,
});
