import { useEffect, useRef, useState } from 'react';

/* measure height of content for proper looking css transition */
export const useHeightCssVars = () => {
  const ref = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState<number | undefined>(undefined);

  useEffect(() => {
    const el = ref.current!;
    const updateHeight = () => setHeight(el.offsetHeight);
    const observer = new ResizeObserver(updateHeight);
    observer.observe(el);
    return () => observer.disconnect();
  }, []);

  return {
    contentRef: ref,
    contentHeight: height,
  };
};
