import { TranslationGetter } from '@wix/editor-elements-types/thunderbolt';
import React from 'react';
import { DownloadLogo, WixLogo } from '../../common/assets/logos';
import {
  NAMESPACE,
  TranslationFallbacks,
  TranslationKeys,
  wixLogoPlaceHolder,
} from './constants';
import style from './style/FreemiumBannerMobile.scss';

type GenericContentProps = { translate: TranslationGetter };

const GenericContent: React.FC<GenericContentProps> = ({ translate }) => {
  const translatedBannerText = translate(
    NAMESPACE,
    TranslationKeys.genericApp,
    TranslationFallbacks.genericApp,
  );
  const [textBeforeLogo, textAfterLogo] =
    translatedBannerText.split(wixLogoPlaceHolder);
  return (
    <>
      {textBeforeLogo}
      <WixLogo rootClass={style.wixLogo} dotClass={style.dot} />
      {textAfterLogo}
      <DownloadLogo className={style.downloadLogo} />
    </>
  );
};
export default GenericContent;
