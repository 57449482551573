import {
  withCompInfo,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';
import {
  FreemiumBannerDesktopDefinition,
  FreemiumBannerDesktopPlatformProps,
} from '../FreemiumBannerDesktop.types';

export const props = withCompInfo<
  FreemiumBannerDesktopPlatformProps,
  FreemiumBannerDesktopDefinition
>()(['direction', 'metaSiteId'], ({ direction, metaSiteId }) => {
  return {
    useOverlay: false,
    direction,
    href: `//www.wix.com/lpviral/enviral?utm_campaign=vir_wixad_live&adsVersion=white&orig_msid=${metaSiteId}`,
    classNames: [direction],
  };
});
export default createComponentMapperModel({ props });
