import {
  createComponentMapperModel,
  withCompInfo,
  withStateRefsValues,
} from '@wix/editor-elements-integrations';
import { VideoBoxAudioProps } from '../../VideoBox/VideoBox.types';

export const props = withCompInfo<
  VideoBoxAudioProps,
  any,
  VideoBoxAudioProps
>()([], (_, carmiData) => {
  return carmiData;
});

export const stateRefs = withStateRefsValues(['reducedMotion']);

export default createComponentMapperModel({ props, stateRefs });
