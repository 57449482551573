import { useCallback, useMemo, useState } from 'react';
import { debounce } from '@wix/thunderbolt-elements/commons/utils';
import { useResizeObserver } from '@wix/thunderbolt-elements/providers/useResizeObserver';
import { ProGalleryOptions } from '@wix/native-pro-gallery';
import { parseValue } from '../common/utils';
import { PRO_GALLERY_CSS_VARS_PREFIX } from '../common/consts';

export function styleableOptionManager<T extends Record<string, any>>(
  element: HTMLElement | undefined,
  styleId: string,
) {
  const memoMap = new Map<string, String>();
  const prefix = `--${styleId}-${PRO_GALLERY_CSS_VARS_PREFIX}-`;
  const varsToUpdate = Object.keys(ProGalleryOptions);
  function read() {
    if (!element) {
      return {
        hasChanges: false,
        options: {} as Partial<T>,
      };
    }
    const newOptions = {} as Partial<T>;
    let hasChanges = false;
    const style = window.getComputedStyle(element);
    for (const key of varsToUpdate) {
      const styleName = `${prefix}${key}`;
      const cssVar = style.getPropertyValue(styleName);
      if (!cssVar) {
        continue;
      }
      if (cssVar === memoMap.get(key)) {
        continue;
      }
      const value = parseValue(cssVar);
      memoMap.set(key, cssVar);
      hasChanges = true;
      newOptions[key as keyof T] = value;
    }
    return {
      hasChanges,
      options: newOptions,
    };
  }
  return read;
}

interface UseOptionsProps<T> {
  element: HTMLElement | undefined;
  styleId: string;
  isExperimentOpen: (experimentName: string) => boolean;
  compProperties: T;
}

export function useOptions<T extends object = Record<string, any>>(
  props: UseOptionsProps<T>,
) {
  const [options, setOptions] = useState<T>({} as T);
  const readCssDataStore = useMemo(
    () => styleableOptionManager<T>(props.element, props.styleId),
    [props.element, props.styleId],
  );

  const update = useCallback(() => {
    const { hasChanges, options: newOptions } = readCssDataStore();
    if (hasChanges) {
      setOptions(opt => ({ ...opt, ...newOptions }));
    }
  }, [readCssDataStore]);

  const debouncedUpdate = useMemo(() => debounce(update, 100), [update]);

  useResizeObserver({
    elem: props.element || null,
    callback: () => debouncedUpdate(),
  });

  // TO DO - add experiment to disable the css data store
  return {
    options: { ...props.compProperties, ...options } as T,
    forceUpdate: update,
  };
}
