import {
  withCompInfo,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';
import {
  HeaderSectionDefinition,
  HeaderSectionCss,
  HeaderSectionProps,
} from '../HeaderSection.types';

export const props = withCompInfo<
  HeaderSectionProps,
  HeaderSectionDefinition,
  HeaderSectionProps
>()([], (_, carmiData) => {
  return carmiData;
});

export const css = withCompInfo<
  HeaderSectionCss,
  HeaderSectionDefinition,
  HeaderSectionCss
>()([], (_, carmiCss) => {
  return carmiCss;
});

export default createComponentMapperModel({ props, css });
