import React from 'react';

export const useOnVideoStopCallback = () =>
  React.useCallback((video: HTMLVideoElement) => {
    const img = video?.nextElementSibling as HTMLElement | null;

    if (img) {
      video.style.opacity = '0';
      img.style.opacity = '1';
    }
  }, []);
