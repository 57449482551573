export const testIds = {
  input: 'input',
  suggestions: 'suggestions',
  label: 'label',
};

export const ariaNamespace = 'Address_Input';

export const TranslationKeys = {
  exampleAddressText: 'address_input_design_sample_address',
  apiDisconnectedPreviewText: 'address_input_states_error_feature_setup',
};
