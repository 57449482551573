import {
  createComponentMapperModel,
  withCompInfo,
  withStateRefsValues,
} from '@wix/editor-elements-integrations';
import { IShareButtonsStateRefs } from '../types';

const props = withCompInfo<any, any>()(
  ['compProps', 'compData', 'compStylableClass', 'externalBaseUrl'],
  ({ compProps, compData, compStylableClass, externalBaseUrl }) => ({
    ...compProps,
    ...compData,
    stylableClassName: compStylableClass,
    externalBaseUrl,
  }),
);

export const css = withCompInfo<any, any>()(['compData'], ({ compData }) => ({
  height: 'auto',
  ...(compData.buttonsAlignment === 'vertical'
    ? { width: 'auto' }
    : { width: 'inital' }),
  // TODO: Change compData to compStyle once stylable props are available
  '--icon-text-spacing': compData.iconTextSpacing,
  '--buttons-spacing': compData.buttonsSpacing,
  '--rows-spacing': compData.rowsSpacing,
  'min-width': 'min-content',
}));

export const stateRefs = withStateRefsValues<keyof IShareButtonsStateRefs>([
  'currentUrlWithQueryParams',
]);

export default createComponentMapperModel({
  props,
  css,
  stateRefs,
});
