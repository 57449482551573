import { createComponentPreviewMapperModel } from '@wix/editor-elements-integrations';
import { composeMapper } from '@wix/editor-elements-preview-utils';
import type { PreviewWrapperProps } from '@wix/editor-elements-types/thunderboltPreview';
import {
  WFacebookLikeDefinition,
  WFacebookLikeMapperProps,
} from '../WFacebookLike.types';
import { getSocialCompBlockingLayerProps } from '../../../preview-utils/socialComponents';
import { props as viewerPropsMapper } from './WFacebookLike.mapper';

const props = composeMapper<
  PreviewWrapperProps<WFacebookLikeMapperProps>,
  WFacebookLikeDefinition
>()(
  viewerPropsMapper,
  { add: ['isSocialInteractionAllowed'] },
  (resolver, deps, carmiData) => {
    const viewerProps = resolver(deps, carmiData);

    return {
      ...viewerProps,
      socialUrl: 'http://www.wix.com/create/website',
      previewWrapperProps: {
        ...getSocialCompBlockingLayerProps(deps.isSocialInteractionAllowed),
      },
    };
  },
);

export default createComponentPreviewMapperModel({
  props,
});
