import {
  withCompInfo,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';
import { getCompStyles } from '../../../tpaGallery';
import { TPAGallerySdkData } from '../../../tpaGallery/types';
import {
  ThumbnailsDefinition,
  ThumbnailsPlatformProps,
  ThumbnailsPropsCarmiData,
  ThumbnailsCSSVars,
} from '../Thumbnails.types';
import { propsResolver } from '../../TPAGallery/viewer/TPAGalleryMapperUtil';

const compStyleKeys = {
  textColor: 'color1',
  descriptionColor: 'color2',
  textBackgroundColor: 'color3',
};

export const props = withCompInfo<
  ThumbnailsPlatformProps,
  ThumbnailsDefinition,
  ThumbnailsPropsCarmiData
>()(
  [
    'compId',
    'compProps',
    'viewMode',
    'language',
    'topology',
    'mainPageId',
    'globalImageQuality',
    'styleProperties',
    'siteColors',
  ],
  (data, carmiProps) => ({
    ...propsResolver<ThumbnailsDefinition>(
      data,
      carmiProps,
      'Thumbnails/Thumbnails.html',
    ),
    compStyles: getCompStyles(
      compStyleKeys,
      data.styleProperties,
      data.siteColors,
    ),
    currentIndex: 0,
  }),
);

const sdkData = withCompInfo<TPAGallerySdkData, ThumbnailsDefinition>()(
  ['compData'],
  ({ compData }) => {
    return {
      dataId: compData.id,
    };
  },
);

const css = withCompInfo<ThumbnailsCSSVars, ThumbnailsDefinition>()(
  ['compLayout'],
  ({ compLayout }) => ({
    '--height': `${compLayout.height}px`,
  }),
);

export default createComponentMapperModel({ css, props, sdkData });
