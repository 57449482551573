import {
  withCompInfo,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';
import {
  GoogleMapDefinition,
  GoogleMapProps,
  GoogleMapsPlatformProps,
} from '../GoogleMap.types';

export const props = withCompInfo<
  GoogleMapProps,
  GoogleMapDefinition,
  GoogleMapsPlatformProps
>()(['compId'], (deps, carmiData) => {
  const { compId } = deps;
  const { urlQueries, mapData, skin } = carmiData;
  return {
    id: compId,
    urlQueries,
    mapData: {
      /** Create new instance of mapData to rerender iFrame on data changes
       * (useGoogleIFrame depends on immutability)
       */
      ...mapData,
    },
    skin,
  };
});

export default createComponentMapperModel({ props });
