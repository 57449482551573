import {
  withCompInfo,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';
import { VideoLegacyDefinition, VideoLegacyProps } from '../VideoLegacy.types';

export const props = withCompInfo<VideoLegacyProps, VideoLegacyDefinition>()(
  ['compData', 'compProps', 'compLayout', 'skin'],
  ({ compData, compProps, compLayout, skin }) => {
    return {
      skin: skin || 'VideoDefault',
      videoId: compData.videoId,
      videoType: compData.videoType,
      showControls: compProps.showControls,
      autoplay: compProps.autoplay,
      loop: compProps.loop,
      showInfo: compProps.showinfo,
      youtubeConfig: {
        enablejsapi: compProps.enablejsapi,
        lightTheme: compProps.lightTheme,
      },
      facebookConfig: {
        width: `${compLayout!.width!}px`,
        height: `${compLayout!.height!}px`,
      },
    };
  },
);
export default createComponentMapperModel({ props });
