import {
  withCompInfo,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';
import {
  LinkBarPropsNoStyle,
  LinkBarDefinition,
  LinkBarCarmiProps,
  LinkBarCSSCarmiData,
} from '../LinkBar.types';

export const props = withCompInfo<
  LinkBarPropsNoStyle,
  LinkBarDefinition,
  LinkBarCarmiProps
>()(['compProps', 'experiments'], ({ compProps, experiments }, carmiData) => {
  const { iconSize } = compProps;
  return {
    ...carmiData,
    iconSize,
    shouldRenderPlaceholders:
      experiments['specs.thunderbolt.LinkBarPlaceholderImages'] === true,
  };
});

export const css = withCompInfo<
  LinkBarCSSCarmiData,
  LinkBarDefinition,
  LinkBarCSSCarmiData
>()([], (_deps, carmiCss) => {
  return carmiCss;
});

export default createComponentMapperModel({ props, css });
