import * as React from 'react';
// TODO: deep import is bad, fix importing from index
import Link from '@wix/thunderbolt-elements/components/Link';
import {
  ICollapsibleTextControllerProps,
  ExpandControlData,
  ExpandControlProps,
} from '../../CollapsibleText.types';
import { st, classes } from '../style/CollapsibleText.component.st.css';
import { ExpandModeValues, TestIds } from '../../constants';

export type ExpandControlCompProps = ExpandControlData &
  ExpandControlProps &
  ICollapsibleTextControllerProps & { wrapperId: string };

export const ExpandControl: React.FC<ExpandControlCompProps> = ({
  link,
  linkText,
  expandMode,
  readMoreText,
  readLessText,
  isExpanded,
  wrapperId,
  setIsExpanded,
}) => {
  switch (expandMode) {
    case ExpandModeValues.Expand:
      return (
        <button
          className={st(classes['expand-control'])}
          onClick={() => setIsExpanded(!isExpanded)}
          data-testid={TestIds.viewer.expand_control}
          aria-pressed={isExpanded}
          aria-describedby={wrapperId}
        >
          {isExpanded ? readLessText : readMoreText}
        </button>
      );
    case ExpandModeValues.Link:
      return (
        <Link
          {...link}
          className={st(classes['expand-control'])}
          dataTestId={TestIds.viewer.expand_control}
          aria-describedby={wrapperId}
        >
          {linkText}
        </Link>
      );
    default:
      return null;
  }
};
