import * as React from 'react';
import { IDocumentMediaProps } from '../DocumentMedia.types';
import Image from '../../Image/viewer/Image';
import Link from '../../Link/viewer/Link';
import { getDataAttributes } from '../../../core/commons/utils';
import styles from './styles/DocumentMedia.scss';

const DocumentMedia: React.FC<IDocumentMediaProps> = props => {
  const { id, title, image, link, className, onMouseEnter, onMouseLeave } =
    props;

  return (
    <div
      id={id}
      {...getDataAttributes(props)}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      className={className}
    >
      <Link {...link} className={styles.link} title={title || image.alt}>
        <div className={styles.imageContainer} aria-hidden="true">
          <Image
            {...image}
            id={`image-${id}`}
            className={styles.image}
            containerId={id}
            displayMode="fit"
            name=""
          />
        </div>
        {title && <span className={styles.title}>{title}</span>}
      </Link>
    </div>
  );
};

export default DocumentMedia;
