import {
  createComponentMapperModel,
  withCompInfo,
} from '@wix/editor-elements-integrations';
import {
  CustomElementComponentDefinition,
  ICustomElementMapperProps,
  CustomElementComponentCSSVars,
} from '../CustomElementComponent.types';

const customElementCollapsedHeightExperiment =
  'specs.thunderbolt.customElemCollapsedheight';

export const props = withCompInfo<
  ICustomElementMapperProps,
  CustomElementComponentDefinition
>()(['compData'], ({ compData: { tagName, initialAttributes } }) => {
  return { tagName, initialAttributes };
});

export const css = withCompInfo<
  Partial<CustomElementComponentCSSVars>,
  CustomElementComponentDefinition
>()(
  ['compLayout', 'hasResponsiveLayout', 'experiments'],
  ({ compLayout: { width, height }, hasResponsiveLayout, experiments }) => {
    const customElemCollapsedHeightActive = () =>
      experiments[customElementCollapsedHeightExperiment] === true;

    return hasResponsiveLayout
      ? {
          display: 'var(--display)',
          '--display': 'flex',
          '--custom-element-width': `100%`,
          '--custom-element-height': `100%`,
        }
      : {
          width: 'auto',
          height: 'auto',
          '--custom-element-width': `${width}px`,
          '--custom-element-height': `${height}px`,
          ...(customElemCollapsedHeightActive() ? { display: 'flex' } : {}),
        };
  },
);

export default createComponentMapperModel({ props, css });
