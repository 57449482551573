import { withCompController } from '@wix/editor-elements-integrations';
import { getValidationControllerProps } from '../../../core/commons/controllerUtils';
import {
  ICheckboxControllerProps,
  ICheckboxMapperProps,
  ICheckboxProps,
} from '../Checkbox.types';

export default withCompController<
  ICheckboxMapperProps,
  ICheckboxControllerProps,
  ICheckboxProps
>(({ mapperProps, controllerUtils }) => {
  const { updateProps } = controllerUtils;
  const { setValidityIndication } = getValidationControllerProps(updateProps);

  return {
    ...mapperProps,
    setValidityIndication,
    onCheckedChange: (isChecked: boolean) => {
      updateProps({
        checked: isChecked,
      });
    },
  };
});
