import * as React from 'react';
import classnames from 'classnames';
import { MouseEvent, KeyboardEvent } from 'react';
import { IEnterPasswordDialogProps } from '../EnterPasswordDialog.types';
import {
  TRANS_FEATURE,
  TRANS_KEYS,
  DEFAULT_VALUES,
  TEST_IDS,
} from '../constants';
import { activateBySpaceOrEnterButton } from '../../../core/commons/a11y';
import { getDataAttributes, performOnEnter } from '../../../core/commons/utils';
import Close from './assets/close.svg';
import style from './style/EnterPasswordDialog.scss';

const EnterPasswordDialog: React.FC<IEnterPasswordDialogProps> = props => {
  const {
    id,
    className,
    translate,
    errorCode,
    isCloseable = true,
    onSubmitCallback,
    onCloseDialogCallback,
  } = props;
  const [prevErrorCode, setPrevErrorCode] = React.useState(errorCode);
  const [errorMessage, setErrorMessage] = React.useState('');
  const [password, setPassword] = React.useState('');

  const translateFn = translate!.bind(null, TRANS_FEATURE);

  const titleText = translateFn(TRANS_KEYS.title, DEFAULT_VALUES.title);
  const subtitleText = translateFn(
    TRANS_KEYS.subtitle,
    DEFAULT_VALUES.subtitle,
  );
  const submitButtonText = translateFn(
    TRANS_KEYS.submitButton,
    DEFAULT_VALUES.submitButton,
  );
  const passwordPlaceholder = translateFn(
    TRANS_KEYS.passwordPlaceholder,
    DEFAULT_VALUES.passwordPlaceholder,
  );
  const closeButtonText = isCloseable
    ? translateFn(TRANS_KEYS.closeDialog, DEFAULT_VALUES.closeDialog)
    : '';

  if (prevErrorCode !== errorCode) {
    if (errorCode === 0) {
      setErrorMessage('');
    } else {
      const generalError = translateFn(
        TRANS_KEYS.generalError,
        DEFAULT_VALUES.generalError,
      );
      setErrorMessage(
        translateFn(
          `${TRANS_KEYS.errMsgPrefix}${errorCode}`.replace('-', ''),
          generalError,
        ),
      );
    }
    setPrevErrorCode(errorCode);
  }

  const onSubmit = (e: MouseEvent | KeyboardEvent) => {
    e.preventDefault();

    if (!password) {
      setErrorMessage(
        translateFn(TRANS_KEYS.blankPassword, DEFAULT_VALUES.blankPassword),
      );
    } else {
      setPrevErrorCode(0);
      onSubmitCallback(password);
    }
  };

  const onKeyDownHandler = performOnEnter(onSubmit);

  return (
    <div
      id={id}
      {...getDataAttributes(props)}
      className={classnames(style.enterPasswordContent, className)}
    >
      <div className={style.blockingLayer} />
      <div className={style.dialog}>
        <div className={style.title}>{titleText}</div>
        <form onKeyDown={onKeyDownHandler}>
          <div className={style.wrapper}>
            <div className={style.header}>
              <div className={style.subtitle}>{subtitleText}</div>
            </div>
            <div className={style.content}>
              <div
                data-testid={TEST_IDS.password}
                className={classnames(
                  style.password,
                  errorMessage && style.error,
                )}
              >
                <input
                  id={`${id}_input`}
                  placeholder={passwordPlaceholder}
                  autoFocus={true}
                  autoComplete="off"
                  type="password"
                  value={password}
                  onChange={event => {
                    setPassword(event.target.value);
                    if (errorMessage) {
                      setErrorMessage('');
                    }
                  }}
                  required
                />
              </div>
            </div>
            <div className={style.footer}>
              <p className={style.errMsg} data-testid={TEST_IDS.errorMessage}>
                {errorMessage}
              </p>
              <button
                type="submit"
                className={style.okButton}
                data-testid={TEST_IDS.submitButton}
                onClick={onSubmit}
              >
                {submitButtonText}
              </button>
            </div>
          </div>
        </form>
      </div>
      {isCloseable && (
        <div
          className={style.xButton}
          tabIndex={0}
          role="button"
          data-testid={TEST_IDS.closeButton}
          aria-label={closeButtonText}
          onClick={onCloseDialogCallback}
          onKeyDown={activateBySpaceOrEnterButton}
        >
          <Close />
        </div>
      )}
    </div>
  );
};

export default EnterPasswordDialog;
