import {
  withCompInfo,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';
// TODO - fix this import when we can import thunderbolt-commmons properly
import { getImpliedLinks } from '@wix/thunderbolt-commons/dist/links';
import {
  WRichTextDefinition,
  WRichTextProps,
} from '@wix/thunderbolt-components';
// TODO - import this directly from '@wix/thunderbolt-components'
import { WRichTextCSSVars as OriginalWRichTextCSSVars } from '@wix/thunderbolt-components/dist/components/WRichText/WRichText';

import { flow } from '../../../core/utils';
import { sanitizeHTML } from '../../../core/commons/htmlUtils';

export const props = withCompInfo<
  WRichTextProps,
  WRichTextDefinition,
  WRichTextProps
>()(
  ['isMobileView', 'isQaMode', 'fullNameCompType'],
  ({ isMobileView, isQaMode, fullNameCompType }, carmiData) => {
    return {
      ...carmiData,
      html: flow(
        html => getImpliedLinks(html, isMobileView),
        sanitizeHTML,
      )(carmiData.html),
      isQaMode,
      fullNameCompType,
    };
  },
);

export type WRichTextCSSVars = Omit<OriginalWRichTextCSSVars, 'min-width'> & {
  '--min-width'?: string;
};

export const css = withCompInfo<
  WRichTextCSSVars,
  WRichTextDefinition,
  WRichTextCSSVars
>()([], (_deps, carmiCss) => {
  const { 'min-width': minWidth, ...cssVars } = carmiCss;

  return { ...cssVars, ...(minWidth && { '--min-width': minWidth }) };
});

export default createComponentMapperModel({ props });
