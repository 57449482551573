import { useEffect } from 'react';
import {
  IPlatformData,
  withCompController,
} from '@wix/editor-elements-integrations';
import { ITEM_TYPES } from '@wix/advanced-seo-utils/api';
import {
  IBreadcrumbsMapperProps,
  IBreadcrumbsControllerProps,
  IBreadcrumbsProps,
  IBreadcrumbsStateValues,
} from '../Breadcrumbs.types';
import { getBreadcrumbsItems } from '../Breadcrumbs.utils';

// TODO: Remove when blocker with bump will be resolved
type PagesMapOverride = {
  [key: string]: {
    title: string;
    id: string;
  };
};

const useComponentProps = ({
  mapperProps,
  controllerUtils,
  stateValues,
}: IPlatformData<
  IBreadcrumbsMapperProps,
  IBreadcrumbsProps,
  IBreadcrumbsStateValues
>): IBreadcrumbsControllerProps => {
  const { updateProps } = controllerUtils;
  const {
    pagesMenuItems,
    isMobile,
    externalBaseUrl,
    svgString,
    showHomePageAsIcon,
    showOnlyPreviousPageOnMobile,
    showHomePage,
    showCurrentPage,
    pagesMap,
    breadcrumbs: veloBreadcrumbs,
    ...restMapperProps
  } = mapperProps;

  const propsData = {
    svgString,
    showOnlyPreviousPageOnMobile,
    showHomePageAsIcon,
    showHomePage,
    showCurrentPage,
  };

  const {
    currentUrl,
    pageId,
    setVeloSeoTags,
    previewMode: isInsideEditor,
  } = stateValues;

  const createBreadcrumbs = () => {
    const menuData = {
      pagesMenuItems,
      isMobile,
    };

    const pageTitle = Object.values(pagesMap as PagesMapOverride).find(
      ({ id }) => id === pageId,
    )!.title;

    const routeInfo = {
      homePageHref: externalBaseUrl,
      currentUrl: decodeURI(currentUrl),
      pageTitle,
    };

    return getBreadcrumbsItems(routeInfo, menuData, propsData);
  };

  const breadcrumbs = veloBreadcrumbs || createBreadcrumbs();

  useEffect(() => {
    if (!isInsideEditor) {
      setVeloSeoTags({
        itemType: ITEM_TYPES.BREADCRUMBS_COMPONENT,
        itemData: breadcrumbs,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    showHomePageAsIcon,
    showOnlyPreviousPageOnMobile,
    showHomePage,
    showCurrentPage,
    breadcrumbs,
    onEllipsisVisibilityChange: isEllipsisVisible => {
      updateProps({
        shouldWrap: !isEllipsisVisible,
      });
    },
    ...restMapperProps,
  };
};

export default withCompController(useComponentProps);
