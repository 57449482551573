import {
  withCompInfo,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';
import { getCompStyles } from '../../../tpaGallery';
import { TPAGallerySdkData } from '../../../tpaGallery/types';
import {
  CollageDefinition,
  CollagePlatformProps,
  CollageCarmiData,
  CollageCSSVars,
} from '../Collage.types';
import {
  propsDependencies,
  propsResolver,
} from '../../TPAGallery/viewer/TPAGalleryMapperUtil';

const compStyleKeys = {
  backgroundMouseoverColor: 'color3',
  descriptionColor: 'color2',
  textColor: 'color1',
};

export const props = withCompInfo<
  CollagePlatformProps,
  CollageDefinition,
  CollageCarmiData
>()(
  [...propsDependencies, 'styleProperties', 'siteColors'],
  (data, carmiProps) => {
    const { compProps } = data;
    if (compProps.maxImageSize > compProps.numOfCells) {
      compProps.maxImageSize = compProps.numOfCells;
    }
    if (compProps.minImageSize > compProps.maxImageSize) {
      compProps.minImageSize = compProps.maxImageSize;
    }

    return {
      ...propsResolver<CollageDefinition>(
        data,
        carmiProps,
        'Collage/Collage.html',
      ),
      compStyles: getCompStyles(
        compStyleKeys,
        data.styleProperties,
        data.siteColors,
      ),
      isScreenWidth: true,
      compProps,
    };
  },
);

const sdkData = withCompInfo<TPAGallerySdkData, CollageDefinition>()(
  ['compData'],
  ({ compData }) => {
    return {
      dataId: compData.id,
    };
  },
);

const css = withCompInfo<CollageCSSVars, CollageDefinition>()(
  ['compLayout'],
  ({ compLayout }) => ({
    '--height': `${compLayout.height}px`,
  }),
);

export default createComponentMapperModel({ css, props, sdkData });
