import { withCompController } from '@wix/editor-elements-integrations';

import {
  PayPalButtonMapperProps,
  PayPalButtonStateRefs,
  PayPalButtonControllerProps,
} from '../PayPalButton.types';

const compController = withCompController<
  PayPalButtonMapperProps,
  PayPalButtonControllerProps,
  never,
  PayPalButtonStateRefs
>(({ stateValues, mapperProps }) => {
  const { getVisitorId, userId, currentUrlWithQueryParams } = stateValues;
  return {
    ...mapperProps,
    getVisitorId: getVisitorId as PayPalButtonControllerProps['getVisitorId'],
    userId,
    currentUrl: currentUrlWithQueryParams,
  };
});

export default compController;
