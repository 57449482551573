import React from 'react';
import classNames from 'classnames';
import { LanguageSelectorItem } from '@wix/thunderbolt-components';
import { TestIds } from '../../constants';
import styles from './TinyMenuLanguageSection.scss';

export type TinyMenuLanguageSectionProps = {
  className: string;
  options: Array<LanguageSelectorItem>;
  onChange: (language: string) => void;
};

const handleLanguageChange =
  (onChange: TinyMenuLanguageSectionProps['onChange']) =>
  (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target;
    onChange(value);
  };

const TinyMenuLanguageSection: React.FC<TinyMenuLanguageSectionProps> = ({
  className,
  options,
  onChange,
}) => {
  const selectedOption = React.useMemo(() => {
    return options.find(({ selected }) => selected);
  }, [options]);
  return (
    <div data-testid={TestIds.lanugageSection}>
      <div className={classNames(styles.container, className)}>
        <select
          onChange={handleLanguageChange(onChange)}
          value={selectedOption?.value}
        >
          {options.map(({ value, label }) => (
            <option key={value} value={value}>
              {label}
            </option>
          ))}
        </select>
        <div className={styles.flagContainer}>
          <img alt="" src={selectedOption?.flag} />
          <span data-testid={TestIds.selectedLanguage}>
            {selectedOption?.value}
          </span>
        </div>
      </div>
      <div className={styles.placeholder}></div>
    </div>
  );
};

export default TinyMenuLanguageSection;
