import classNames from 'classnames';
import * as React from 'react';
import { customCssClasses } from '@wix/editor-elements-common-utils';

import semanticClassNames from '../../../../SearchBox.semanticClassNames';
import SearchSymbolSvg from '../../../assets/SearchSymbol.svg';
import style from './SearchSymbol.scss';

export const SearchSymbol: React.FC = () => {
  return (
    <div
      className={classNames(
        style.root,
        customCssClasses(semanticClassNames.icon),
      )}
    >
      <SearchSymbolSvg />
    </div>
  );
};
