import * as React from 'react';
import {
  ArtistName,
  TrackName,
  PlayPause,
  ProgressBar,
  TimeStamp,
} from '@wix/soundhead-react';
import classnames from 'classnames';
import {
  ISingleAudioPlayerProps,
  IPlayerProps,
} from '../../../SingleAudioPlayer.types';
import VolumeControls from '../../components/VolumeControls/VolumeControls';
import PlayerContainer from '../../components/PlayerContainer/PlayerContainer';
import { TestHooks } from '../../constants';
import style from '../../style/EPlayerRoundPlay.scss';

const Player: React.FC<IPlayerProps> = props => {
  const {
    id,
    className,
    shouldDisplayRepeatIcon,
    isMute,
    onMouseEnter,
    onMouseLeave,
    ariaLabel,
  } = props;
  return (
    <div
      id={id}
      className={classnames(className, style.EPlayerRoundPlay)}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      role="application"
      aria-label={ariaLabel}
    >
      <div className={style.player}>
        <div className={style.mediaControls}>
          <div className={style.mediaButtonContainer}>
            <div className={style.btnContainer} data-testid={TestHooks.play}>
              <PlayPause
                className={classnames(style.mediaButton, style.button, {
                  [style.repeat]: shouldDisplayRepeatIcon,
                })}
                iconPlay={
                  <span
                    className={classnames(style.mediaButtonIcon, style.play)}
                    data-testid={TestHooks.playIcon}
                  />
                }
                iconPause={
                  <span
                    className={classnames(style.mediaButtonIcon, style.pause)}
                    data-testid={TestHooks.pauseIcon}
                  />
                }
              />
            </div>
          </div>
          <div className={style.info}>
            <TrackName className={style.trackLabel} />
            <span className={style.separator}>{' - '}</span>
            <ArtistName className={style.artistLabel} />
          </div>
          <VolumeControls className={style.volumeControls} isMute={isMute} />
          <div className={style.timeStampsContainer}>
            <TimeStamp
              display="currentTime"
              className={classnames(style.duration, style.trackPosition)}
            />
            <TimeStamp
              display="duration"
              className={classnames(style.duration, style.trackDuration)}
            />
          </div>
        </div>
        <ProgressBar className={style.progressbar} />
      </div>
    </div>
  );
};

const EPlayerRoundPlay: React.FC<ISingleAudioPlayerProps> = props => {
  return <PlayerContainer {...props} player={Player} />;
};

export default EPlayerRoundPlay;
