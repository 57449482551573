import React, {
  useMemo,
  useState,
  useCallback,
  useRef,
  useEffect,
} from 'react';
import {
  WixProGallery as BaseGallery,
  makeEventListener,
  isFullscreenEnabled,
} from '@wix/pro-gallery-core-wrapper';
import {
  processProGalleryOptions,
  flattenObject,
  optionsConverter,
} from '@wix/native-pro-gallery';
// TODO - migrate to a shared place instead of using deep import2
import {
  debounce,
  getDataAttributes,
} from '@wix/thunderbolt-elements/commons/utils';
import { getQaDataAttributes } from '@wix/thunderbolt-elements/commons/qaUtils';
import { useResizeObserver } from '@wix/thunderbolt-elements/providers/useResizeObserver';
import { IProGalleryProps, GalleryViewMode } from '../ProGallery.types';
import { isEditingHover, formatItemUri, isSSR } from '../common/utils';
import getExperimentalFeatures from '../common/getExperimentalFeatures';

import { st, classes } from './style/ProGallery.component.st.css';
import './style/ProGallery.global.scss';
import { useOptions } from './useOptions';
// dummy
const RESIZE_OBSERVER_DELAY = 100;

const ProGallery: React.FC<IProGalleryProps> = props => {
  const {
    id,
    items: unprocessedItems,
    wixSDKItems,
    itemsSrc = 'organizeMedia',
    className,
    stylableClassName,
    forceState = {},
    onItemClicked = () => {},
    onMouseEnter,
    onMouseLeave,
    imageOnClickAction,
    isQaMode,
    fullNameCompType,
    shouldUseNewInfoElements,
    viewMode,
    deviceType,
    styleId,
    editorLayoutHeight,
    editorLayoutWidth,
    manualStyleParams,
  } = props;
  const isPrerender = isSSR();
  const defaultContainer = {
    scrollBase: 0,
    isDefaultContainer: true,
    width: props.options.responsive ? 980 : editorLayoutWidth,
    height: props.options.responsive ? 500 : editorLayoutHeight,
  };
  const isExperimentOpen = (name: string) => !!props.experiments?.[name];
  const containerRef = useRef<HTMLDivElement>(null);
  const [container, setContainer] = useState<any>(defaultContainer);
  const [height, setHeight] = useState<number>(container.height);
  useEffect(
    () => setHeight(editorLayoutHeight || height),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [editorLayoutHeight],
  );
  const experimentalFeatures = getExperimentalFeatures(isExperimentOpen);
  if (shouldUseNewInfoElements) {
    experimentalFeatures.newInfoElements = shouldUseNewInfoElements;
  }

  const { options } = useOptions({
    compProperties: props.options,
    element: containerRef.current || undefined,
    isExperimentOpen,
    styleId,
  });

  const { options: convertedOptions, fullscreenOverrides } = useMemo(() => {
    const processed = {
      ...processProGalleryOptions(options),
      alwaysShowHover: isEditingHover(forceState),
      ...(imageOnClickAction && { itemClick: imageOnClickAction }),
      ...flattenObject(manualStyleParams),
    };
    return optionsConverter(
      processed,
      experimentalFeatures.newInfoElements,
      viewMode,
    );
  }, [
    options,
    forceState,
    imageOnClickAction,
    manualStyleParams,
    experimentalFeatures.newInfoElements,
    viewMode,
  ]);

  const resizeGallery = useCallback(() => {
    setContainer({
      width: containerRef.current?.clientWidth,
      height: containerRef.current?.clientHeight,
    });
  }, [containerRef]);

  const resizeObserverCallback = useMemo(
    () => debounce(resizeGallery, RESIZE_OBSERVER_DELAY),
    [resizeGallery],
  );

  useResizeObserver({
    ref: containerRef,
    callback: resizeObserverCallback,
  });

  const items = useMemo(() => {
    return (
      (itemsSrc === 'wixSDK'
        ? wixSDKItems
        : unprocessedItems.map(formatItemUri)) || []
    );
  }, [unprocessedItems, wixSDKItems, itemsSrc]);
  const ssrStyleOverride = isPrerender
    ? {
        minHeight: 'max-content',
        height: '100%',
      }
    : {};
  const classicHeight = props.options.responsive ? {} : { height };
  if (shouldUseNewInfoElements) {
    experimentalFeatures.newInfoElements = shouldUseNewInfoElements;
  }

  const eventsListener = makeEventListener()
    .ITEM_CLICKED((eventData: { idx: number; type: string }) => {
      onItemClicked({
        itemIndex: eventData.idx,
        type: eventData.type,
      });
    })
    .done();

  return (
    <div
      id={id}
      className={st(classes.container, className)}
      style={{
        ...classicHeight,
        ...ssrStyleOverride,
      }}
      {...getQaDataAttributes(isQaMode, fullNameCompType)}
      {...getDataAttributes(props)}
    >
      <div
        className={st(classes.root, stylableClassName)}
        ref={containerRef}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <BaseGallery
          experimentalFeatures={experimentalFeatures}
          id={id}
          items={items}
          itemsSrc={itemsSrc}
          container={container || defaultContainer}
          eventsListener={eventsListener}
          scrollingElement={containerRef.current as any}
          deviceType={deviceType!}
          isMobile={deviceType === 'mobile'}
          staticMediaUrls={undefined as any}
          viewMode={viewMode as GalleryViewMode}
          setLayoutHeightImp={setHeight}
          flattenOptions={convertedOptions}
          flattenFullscreenOverrides={fullscreenOverrides}
          enableFullscreen={isFullscreenEnabled(options)}
        />
      </div>
    </div>
  );
};

export default ProGallery;
