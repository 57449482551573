import {
  withCompInfo,
  createComponentMapperModel,
  withStateRefsValues,
} from '@wix/editor-elements-integrations';
import {
  FreemiumBannerMobileDefinition,
  FreemiumBannerMobilePlatformProps,
  IFreemiumBannerRefState,
} from '../FreemiumBannerMobile.types';
import { shouldUsePreferredAppExperiment } from './constants';

export const props = withCompInfo<
  FreemiumBannerMobilePlatformProps,
  FreemiumBannerMobileDefinition
>()(
  ['direction', 'metaSiteId', 'experiments'],
  ({ direction, metaSiteId, experiments }) => {
    const shouldUsePreferredApp =
      experiments[shouldUsePreferredAppExperiment] === true ||
      experiments[shouldUsePreferredAppExperiment] === 'new';
    return {
      useOverlay: false,
      direction,
      href: `https://apps.wix.com/place-invites/join-lp/${metaSiteId}?ref=free_banner`,
      classNames: [direction],
      shouldReportBi: true,
      shouldUsePreferredApp,
    };
  },
);

export const stateRefs = withStateRefsValues<keyof IFreemiumBannerRefState>([
  'getAppInstanceByAppDefId',
  'reportBi',
]);

export default createComponentMapperModel({ props, stateRefs });
