import {
  withCompInfo,
  createComponentMapperModel,
  withStateRefsValues,
} from '@wix/editor-elements-integrations';
import { LanguageSelectorCSSVars } from '@wix/thunderbolt-components';
import {
  LanguageSelectorDefinition,
  ILanguageSelectorMapperProps,
  IconType,
} from '../LanguageSelector.types';

export const props = withCompInfo<
  ILanguageSelectorMapperProps,
  LanguageSelectorDefinition
>()(
  ['compProps', 'isMobileView', 'styleProperties'],
  ({
    compProps: {
      iconSize,
      alignTabs,
      displayMode: displayModeProp,
      hasArrow = false,
      itemFormat,
      iconType: iconTypeProp,
      languagesOrder,
    },
    isMobileView,
    styleProperties: { iconType: iconTypeStyle },
  }) => {
    const isLegacyMobileComponent = !iconSize && isMobileView;
    const alignItems = !isLegacyMobileComponent ? alignTabs : 'center';

    let displayMode = displayModeProp;
    if (displayModeProp === 'dropdown' && isMobileView) {
      displayMode = 'nativeDropdown';
    }

    const showArrow = hasArrow || !isLegacyMobileComponent;
    const showHandleText =
      isLegacyMobileComponent ||
      (displayMode === 'nativeDropdown' && itemFormat === 'iconOnly');

    let iconType = iconTypeProp;
    if (!iconSize) {
      iconType =
        isMobileView && iconTypeStyle === 'none'
          ? 'circle'
          : (iconTypeStyle as IconType);
    }

    return {
      displayMode,
      alignItems,
      extraOptions: {
        showArrow,
        showHandleText,
      },
      iconType,
      itemFormat,
      displayModeProp,
      isMobile: isMobileView,
      languagesOrder: languagesOrder ?? [],
    };
  },
);

export const css = withCompInfo<
  LanguageSelectorCSSVars,
  LanguageSelectorDefinition,
  LanguageSelectorCSSVars
>()([], (_, carmiCss) => carmiCss);

export const stateRefs = withStateRefsValues([
  'originalLanguageCode',
  'currentLanguage',
  'siteLanguages',
  'flagsUrl',
  'setCurrentLanguage',
]);

export default createComponentMapperModel({ props, css, stateRefs });
