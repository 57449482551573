import * as React from 'react';
import classNames from 'classnames';
import SiteButtonContent from '../../SiteButtonContent';
import { SkinButtonProps } from '../SkinButton.types';
import SiteButton from '../../SiteButton';
import {
  ISiteButtonImperativeActions,
  ISiteButtonProps,
} from '../../../SiteButton.types';
import skinsStyle from './styles/skins.scss';

const ButtonSandclockSkin = React.forwardRef<
  ISiteButtonImperativeActions,
  SkinButtonProps
>(
  (
    { wrapperProps, linkProps, a11yProps, elementType, label, onFocus, onBlur },
    ref,
  ) => {
    return (
      <div
        {...wrapperProps}
        className={classNames(
          skinsStyle.ButtonSandclock,
          wrapperProps.className,
        )}
      >
        <div className={skinsStyle.wrapper}>
          <div
            className={`${skinsStyle.container} ${skinsStyle.top} ${skinsStyle.left}`}
          >
            <div className={skinsStyle.ribbon} />
          </div>
          <div
            className={`${skinsStyle.container} ${skinsStyle.bottom} ${skinsStyle.left}`}
          >
            <div className={skinsStyle.ribbon} />
          </div>
          <div
            className={`${skinsStyle.container} ${skinsStyle.top} ${skinsStyle.right}`}
          >
            <div className={skinsStyle.ribbon} />
          </div>
          <div
            className={`${skinsStyle.container} ${skinsStyle.bottom} ${skinsStyle.right}`}
          >
            <div className={skinsStyle.ribbon} />
          </div>
          <div className={skinsStyle.filler} />
        </div>
        <SiteButtonContent
          linkProps={linkProps}
          a11yProps={a11yProps}
          elementType={elementType}
          className={skinsStyle.link}
          onFocus={onFocus}
          onBlur={onBlur}
          ref={ref}
        >
          <span className={skinsStyle.label}>{label}</span>
        </SiteButtonContent>
      </div>
    );
  },
);

const ButtonSandclock: React.ForwardRefRenderFunction<
  ISiteButtonImperativeActions,
  Omit<ISiteButtonProps, 'skin'>
> = (props, ref) => (
  <SiteButton {...props} skin={ButtonSandclockSkin} ref={ref} />
);

export default React.forwardRef(ButtonSandclock);
