import {
  withCompInfo,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';
import { HoverBoxDefinition } from '@wix/thunderbolt-components';
import {
  MediaContainerProps,
  HoverBoxSelectorCSSVars,
} from '@wix/thunderbolt-components-native';

export const props = withCompInfo<
  MediaContainerProps,
  HoverBoxDefinition,
  MediaContainerProps
>()([], (_, carmiData) => {
  return carmiData;
});

export const selectorToCss = withCompInfo<
  HoverBoxSelectorCSSVars,
  HoverBoxDefinition,
  HoverBoxSelectorCSSVars
>()([], (_, carmiCss) => {
  return carmiCss;
});

export default createComponentMapperModel({ props, selectorToCss });
