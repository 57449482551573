import type { TranslationGetter } from '@wix/editor-elements-types/thunderbolt';
import { TRANSLATIONS } from './constants';

export type TranslationType = Exclude<keyof typeof TRANSLATIONS, 'feature'>;

export const getAllTranslations = (
  translate: TranslationGetter | undefined,
  type: TranslationType,
) => {
  const translations = TRANSLATIONS[type];
  const titleText = translate!(
    TRANSLATIONS.feature,
    translations.title.key,
    translations.title.defaultText,
  );
  return {
    titleText,
  };
};
