import React from 'react';

import { createComponentPreviewEntry } from '@wix/editor-elements-integrations';
import type { IComponentPreviewWrapper } from '@wix/editor-elements-types/thunderboltPreview';
import type { IComponentCustomMeasureImperativeAPI } from '@wix/thunderbolt-becky-types';

import { withComponentPreview as withMediaContainerPreviewWrapper } from '../../MediaContainers/MediaContainer/viewer/MediaContainer.previewWrapper';

import {
  ClassicSectionProps,
  ClassicSectionPreviewProps,
} from '../ClassicSection.types';
import { MediaContainerVideoAPI } from '../../MediaContainers/MediaContainer/MediaContainer.types';

function withComponentPreview(
  ViewerComponent: React.ComponentType<
    ClassicSectionProps & { ref: React.Ref<MediaContainerVideoAPI> }
  >,
): IComponentPreviewWrapper<ClassicSectionProps, ClassicSectionPreviewProps> {
  return React.forwardRef<
    IComponentCustomMeasureImperativeAPI & MediaContainerVideoAPI,
    ClassicSectionProps
  >((props, ref) => {
    const mediaContainerRef = React.useRef<MediaContainerVideoAPI | null>(null);

    React.useImperativeHandle(
      ref,
      () =>
        ({
          ...mediaContainerRef.current,
          getCustomMeasures: () => ({
            width: {
              [props.id]: siteMeasureMap => siteMeasureMap.width.site,
            },
          }),
        } as IComponentCustomMeasureImperativeAPI & MediaContainerVideoAPI),
    );

    return <ViewerComponent {...props} ref={mediaContainerRef} />;
  });
}

export default (ViewerComponent: React.ComponentType<ClassicSectionProps>) =>
  createComponentPreviewEntry(
    withComponentPreview(withMediaContainerPreviewWrapper(ViewerComponent)),
  );
