import {
  withCompPreviewProps,
  createComponentPreviewMapperModel,
} from '@wix/editor-elements-integrations';
import {
  MenuContainerDefinition,
  MenuContainerCarmiCssVars,
  MenuContainerCssVars,
} from '../MenuContainer.types';

const css = withCompPreviewProps<
  MenuContainerCssVars,
  MenuContainerDefinition,
  MenuContainerCarmiCssVars
>()(['hasResponsiveLayout'], ({ hasResponsiveLayout }, carmiCss) => {
  const isStretched = !carmiCss.left && !carmiCss.right;
  return {
    ...carmiCss,
    '--screen-width': '320px', // width of mobile screen in the editor preview
    ...(!hasResponsiveLayout &&
      isStretched && {
        left: 'calc((100% - 320px) / 2)',
      }),
  };
});

export default createComponentPreviewMapperModel({ css } as any);
