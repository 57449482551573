import * as React from 'react';
import classNames from 'classnames';
import {
  ICheckboxGroupProps,
  ICheckboxGroupImperativeActions,
} from '../CheckboxGroup.types';
import Checkbox from '../../Checkbox/viewer/Checkbox';
import { ICheckboxImperativeActions } from '../../Checkbox/Checkbox.types';
import { getDataAttributes } from '../../../core/commons/utils';
import style from './style/CheckboxGroupDefaultSkin.scss';

const noop = () => {};

const CheckboxGroup: React.ForwardRefRenderFunction<
  ICheckboxGroupImperativeActions,
  ICheckboxGroupProps
> = (props, ref) => {
  const {
    id,
    label = '',
    options,
    required,
    isDisabled,
    shouldShowValidityIndication,
    className,
    validateValueAndShowIndication = noop,
    onOptionsChange = noop,
    onChange = noop,
    onClick = noop,
    onDblClick = noop,
    onMouseEnter = noop,
    onMouseLeave = noop,
  } = props;

  const inputRefs = React.useRef<Array<ICheckboxImperativeActions | null>>([]);

  React.useImperativeHandle(ref, () => {
    return {
      focus: () => {
        const checkedItem =
          inputRefs.current?.find(_ref => _ref && _ref.isChecked) ||
          inputRefs.current?.[0];

        checkedItem?.focus();
      },
      blur: () => {
        const focusedItem = inputRefs.current?.find(
          _ref => _ref && _ref.isFocused,
        );
        focusedItem?.blur();
      },
      setCustomValidity: message => {
        inputRefs.current?.forEach(_ref => {
          _ref?.setCustomValidity(message);
        });
      },
    };
  });

  const _onChange = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const newOptions = options.map((option, _index) => ({
      ...option,
      checked: _index === index ? event.target.checked : option.checked,
    }));
    onOptionsChange(newOptions);
    validateValueAndShowIndication();
    onChange(event);
  };

  // TODO onBlur and onFocus are not implemented in CheckboxGroup in bolt
  // If we are going to implement them we need to make sure they are not triggered on changing between checkbox buttons

  const _onClick: React.MouseEventHandler<HTMLDivElement> = event => {
    if (!isDisabled) {
      onClick(event);
    }
  };

  const _onDblClick: React.MouseEventHandler<HTMLDivElement> = event => {
    if (!isDisabled) {
      onDblClick(event);
    }
  };

  const _onMouseEnter: React.MouseEventHandler<HTMLDivElement> = event => {
    if (!isDisabled) {
      onMouseEnter(event);
    }
  };

  const _onMouseLeave: React.MouseEventHandler<HTMLDivElement> = event => {
    if (!isDisabled) {
      onMouseLeave(event);
    }
  };

  const containerClasses = classNames(style.CheckboxGroupDefaultSkin, {
    [style.requiredIndication]: required && label,
  });

  const isAnyOptionChecked = props.options.some(option => option.checked);

  return (
    <div
      id={id}
      {...getDataAttributes(props)}
      className={classNames(containerClasses, className)}
      onDoubleClick={_onDblClick}
      onMouseEnter={_onMouseEnter}
      onMouseLeave={_onMouseLeave}
    >
      <fieldset>
        <legend className={style.groupLabelLegend}>
          {label && (
            <div className={style.groupLabel} data-testid="label">
              {label}
            </div>
          )}
        </legend>
        <div className={style.items} data-testid="items">
          {options.map((option, index) => (
            <Checkbox
              ref={el => (inputRefs.current[index] = el)}
              key={index}
              {...option}
              id={`${index}_${id}`}
              required={required && !isAnyOptionChecked}
              isDisabled={isDisabled}
              readOnly={false}
              shouldShowValidityIndication={shouldShowValidityIndication}
              validateValueAndShowIndication={() => {}}
              hideValidityIndication={() => {}}
              setValidityIndication={() => {}}
              onCheckedChange={() => {}}
              onBlur={() => {}}
              onFocus={() => {}}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                _onChange(index, event)
              }
              onClick={_onClick}
              onDblClick={() => {}}
              name={id}
            ></Checkbox>
          ))}
        </div>
      </fieldset>
    </div>
  );
};

export default React.forwardRef(CheckboxGroup);
