import {
  withCompInfo,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';
import {
  ImageXProps,
  ImageXDefinition,
} from '@wix/thunderbolt-components-native';
import { IImageXProps } from '../ImageX.types';

export const props = withCompInfo<
  ImageXProps,
  ImageXDefinition,
  IImageXProps
>()([], (_, carmiProps) => {
  const {
    link: _link,
    imageInfo: { imageData },
  } = carmiProps;
  const link = _link && Object.keys(_link).length ? _link : undefined;
  const aspectRatio = imageData.height / imageData.width;
  return { ...carmiProps, link, aspectRatio };
});

export default createComponentMapperModel({
  props,
});
