import classNames from 'classnames';
import * as React from 'react';
import { createPortal } from 'react-dom';
import { useClickOutside } from '../../../../providers/useClickOutside/useClickOutside';
import { usePopper } from '../../../../providers/usePopper/usePopper';
import { POPPERS_WRAPPER_ID, testIds } from '../../constants';
import { IFileErrorIconProps } from '../../FileUploader.types';
import { getFileNameErrorTooltipId } from '../utils';
import PopperErrorIcon from './assets/popperErrorIcon.svg';
import TooltipArrow from './assets/tooltipArrow.svg';
import style from './style/FileErrorIcon.scss';

const noop = () => {};

export const FileErrorIcon: React.FC<IFileErrorIconProps> = ({
  fileNameId,
  message,
  withSingleFileBehavior,
  filesAlignment,
  forceShowTooltip = false,
}) => {
  const [isOpen, setOpen] = React.useState(false);
  const [poppersWrapper, setPoppersWrapper] = React.useState<Element>();

  const popper = usePopper<HTMLAnchorElement>({
    placement: 'top',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 8],
        },
      },
    ],
  });

  React.useEffect(() => {
    const poppersWrapperEl = document.getElementById(POPPERS_WRAPPER_ID);

    if (!poppersWrapperEl) {
      const PoppersWrapperElement = document.createElement('div');

      PoppersWrapperElement.setAttribute('data-testid', testIds.poppersWrapper);
      PoppersWrapperElement.setAttribute('id', POPPERS_WRAPPER_ID);

      document.body.appendChild(PoppersWrapperElement);

      setPoppersWrapper(PoppersWrapperElement);
      return () => {
        document.body.removeChild(PoppersWrapperElement);
      };
    } else {
      setPoppersWrapper(poppersWrapperEl);
    }

    return noop;
  }, []);

  const hideTooltip = () => setOpen(false);
  const showTooltip = () => setOpen(true);

  useClickOutside([popper.ref], hideTooltip);

  const tooltipId = getFileNameErrorTooltipId(fileNameId);

  const shouldShowTooltip = forceShowTooltip || isOpen;

  return (
    <div
      className={classNames(style.tooltipContainer, {
        [style.singleFileBehavior]: withSingleFileBehavior,
      })}
      data-testid={testIds.tooltipContainer}
    >
      <div
        className={style.tooltipTrigger}
        data-testid={testIds.tooltipTrigger}
        role="button"
        aria-expanded={shouldShowTooltip}
        aria-describedby={tooltipId}
        ref={popper.setRef as React.LegacyRef<HTMLDivElement>}
        onMouseOver={showTooltip}
        onMouseOut={hideTooltip}
      >
        <PopperErrorIcon role="presentation" />
      </div>
      {shouldShowTooltip &&
        poppersWrapper &&
        createPortal(
          <div
            ref={popper.setPopper}
            style={popper.styles.popper}
            {...popper.attributes.popper}
            id={tooltipId}
            className={style.tooltip}
            data-testid={testIds.errorTooltip}
            role="tooltip"
            aria-live="polite"
            aria-label={message}
            aria-hidden="true"
          >
            <div data-testid={testIds.errorTooltipText}>{message}</div>
            <TooltipArrow
              className={classNames(style.tooltipArrow, {
                [style.singleFileBehavior]: withSingleFileBehavior,
                [style.rtl]: filesAlignment === 'right',
                [style.centerAligned]: filesAlignment === 'center',
              })}
              role="presentation"
            />
          </div>,
          poppersWrapper,
        )}
    </div>
  );
};
