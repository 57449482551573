import {
  withCompInfo,
  createComponentMapperModel,
  withStateRefsValues,
} from '@wix/editor-elements-integrations';
import * as vectorImage from '@wix/thunderbolt-commons/dist/vectorImage';
import { BreadcrumbsSdkDataCarmiData } from '@wix/thunderbolt-components-native';
import {
  BreadcrumbsDefinition,
  IBreadcrumbsMapperProps,
  BreadcrumbsCSSVars,
  BreadcrumbsPropsCarmiData,
  IBreadcrumbsStateValues,
} from '../Breadcrumbs.types';

const props = withCompInfo<
  IBreadcrumbsMapperProps,
  BreadcrumbsDefinition,
  BreadcrumbsPropsCarmiData
>()(
  [
    'compProps',
    'compStylableClass',
    'isMobileView',
    'externalBaseUrl',
    'pagesMap',
  ],
  (
    { compProps, compStylableClass, isMobileView, externalBaseUrl, pagesMap },
    carmiData,
  ) => {
    const {
      separator,
      direction,
      shouldWrap,
      showHomePage,
      showCurrentPage,
      showHomePageAsIcon,
      showOnlyPreviousPageOnMobile,
      itemsBeforeCollapse,
      itemsAfterCollapse,
    } = compProps;
    return {
      svgString: carmiData.svgString,
      pagesMenuItems: carmiData.pagesMenuItems,
      pagesMap,
      separator,
      direction,
      shouldWrap,
      showHomePage,
      showCurrentPage,
      showHomePageAsIcon,
      itemsBeforeCollapse,
      itemsAfterCollapse,
      showOnlyPreviousPageOnMobile:
        isMobileView && showOnlyPreviousPageOnMobile,
      isMobile: isMobileView,
      stylableClassName: compStylableClass,
      externalBaseUrl,
    };
  },
);

const css = withCompInfo<BreadcrumbsCSSVars, BreadcrumbsDefinition>()(
  ['compProps'],
  ({ compProps }) => {
    const { horizontalMargin, verticalMargin, alignment, direction } =
      compProps;

    const isLtr = direction === 'ltr';

    return {
      '--lastItemMargin': isLtr
        ? `0 0 0 ${horizontalMargin}px`
        : `0 ${horizontalMargin}px 0 0`,
      '--rightMargin': isLtr ? '0px' : `${horizontalMargin}px`,
      '--leftMargin': isLtr ? `${horizontalMargin}px` : '0px',
      '--horizontalMargin': `${horizontalMargin}px`,
      '--verticalMargin': `${verticalMargin}px`,
      '--alignment': alignment,
      '--flexDirection': isLtr ? 'row' : 'row-reverse',
      height: 'auto', // component is not resizable vertically and should have content height
    };
  },
);

const sdkData = withCompInfo<
  BreadcrumbsSdkDataCarmiData,
  BreadcrumbsDefinition
>()(['topology'], ({ topology }) => {
  const { mediaRootUrl } = topology;
  const { getSvgBaseUrl } = vectorImage.buildSvgUrlFactory();

  return {
    mediaSvgUrl: getSvgBaseUrl(mediaRootUrl),
  };
});

const stateRefs = withStateRefsValues<keyof IBreadcrumbsStateValues>([
  'currentUrl',
  'pageId',
  'setVeloSeoTags',
  'previewMode',
]);

export default createComponentMapperModel({ css, props, sdkData, stateRefs });
