import { useEffect, useState, TransitionEvent } from 'react';

const isNeededEvent = (e: TransitionEvent) =>
  e.target === e.currentTarget && e.propertyName === 'height';

/* hide content with a delay - only after accordion's css transition ends */
export const useHideContentAfterTransitionEnds = (isOpened: boolean) => {
  const [isContentShown, setIsContentShown] = useState(isOpened);

  useEffect(() => {
    if (isOpened) {
      setIsContentShown(true);
    }
  }, [isOpened]);

  const onTransitionEnd = (e: TransitionEvent) => {
    if (isNeededEvent(e) && !isOpened) {
      setIsContentShown(false);
    }
  };

  return {
    isContentShown,
    onTransitionEnd,
  };
};
