import {
  withCompInfo,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';
import {
  SignatureInputDefinition,
  ISignatureInputMapperProps,
  SignatureInputCSSVars,
  SignatureInputCssCarmiData,
} from '../SignatureInput.types';

const isThemeColor = (color: string) => /^color_\d+$/.test(color);
const getFromColorMap = (rawColor: string, colorsMap: Array<string>) => {
  const [, themeColorIndex] = rawColor.split('_');
  const i = parseInt(themeColorIndex, 10);
  return typeof i === 'number' && isNaN(i) === false ? colorsMap[i] : rawColor;
};

export const props = withCompInfo<
  ISignatureInputMapperProps,
  SignatureInputDefinition
>()(
  ['compData', 'compProps', 'styleProperties', 'siteColors'],
  ({ compData, compProps, styleProperties, siteColors }) => {
    const { signatureSize, signatureColor: signatureColorProp } =
      styleProperties;

    return {
      label: compData.titleText,
      clearButtonText: compData.clearButtonText,
      required: compProps.required || false,
      isDisabled: compProps.isDisabled || false,
      direction: compProps.direction,
      signatureSize: signatureSize.endsWith('px')
        ? signatureSize.slice(0, -2)
        : signatureSize,
      signatureColor: isThemeColor(signatureColorProp)
        ? getFromColorMap(signatureColorProp, siteColors)
        : signatureColorProp,
    };
  },
);

export const css = withCompInfo<
  SignatureInputCSSVars,
  SignatureInputDefinition,
  SignatureInputCssCarmiData
>()(['compProps'], ({ compProps }, cssVars) => {
  const { direction, titlePaddingStart, titleMarginBottom } = compProps;

  return {
    ...cssVars,
    '--dir': direction,
    '--labelPadding':
      direction === 'rtl'
        ? `0 ${titlePaddingStart}px 0 0`
        : `0 0 0 ${titlePaddingStart}px`,
    '--labelMarginBottom': `${titleMarginBottom}px`,
  };
});

export default createComponentMapperModel({ props, css });
