import {
  withCompInfo,
  withCompPreviewProps,
  createComponentPreviewMapperModel,
} from '@wix/editor-elements-integrations';
import { TinyMenuDefinition, TinyMenuProps } from '@wix/thunderbolt-components';
import { SkinType } from '../TinyMenu.types';

const props = withCompPreviewProps<
  TinyMenuProps,
  TinyMenuDefinition,
  TinyMenuProps
>()(['compPreviewState'], ({ compPreviewState }, carmiProps) => ({
  ...carmiProps,
  isOpen: compPreviewState === 'open',
}));

const uiType = withCompInfo()(['skin'], (({ skin }: { skin: SkinType }) =>
  skin === 'TinyMenuSkin' ? skin : 'TinyMenuDefaultSkin') as any);

export default createComponentPreviewMapperModel({ props, uiType });
