import * as React from 'react';
import { Translations } from '../constants';
import { ITinyMenuProps } from '../TinyMenu.types';

const TinyMenu: React.FC<ITinyMenuProps> = props => {
  const { translate, skin: MenuClass, ...restProps } = props;
  const ariaLabel = translate
    ? translate(
        Translations.ariaLabelNamespace,
        Translations.ariaLabelKey,
        Translations.ariaLabelDefault,
      )
    : Translations.ariaLabelDefault;
  return (
    <MenuClass
      wrapperProps={{
        ariaLabel,
        ...restProps,
      }}
    />
  );
};

export default TinyMenu;
