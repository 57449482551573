import * as React from 'react';
import ResponsiveBox from '../../Container/viewer/skinComps/ResponsiveBox/ResponsiveBox.skin';
import { IMultiStateBoxProps } from '../MultiStateBox.types';
import { getAriaAttributes } from '../../../core/commons/a11y';
import { getDataAttributes } from '../../../core/commons/utils';
import { useMSBStates } from './useMSBStates';
import { st, classes } from './style/MultiStateBox.st.css';

export const REPEATER_DELIMITER = '__';

// cut repeater delimiter
const getBaseStateId = (stateId: string | undefined) => {
  const [baseId] = stateId?.split(REPEATER_DELIMITER) ?? [];
  return baseId;
};

const getDefaultContainerProps = (id: string) => ({
  // TODO - wire correctly
  containerLayoutClassName: `container-${id}`,
  // TODO - wire correctly
  overlowWrapperClassName: `overflow-wrapper-${id}`,
  // TODO - wire correctly
  hasOverflow: true,
});

const MultiStateBox: React.FC<IMultiStateBoxProps> = props => {
  const {
    id,
    className,
    children,
    renderContainer,
    selectedStateId,
    a11y = {},
    ariaAttributes,
    containerProps,
    role,
    containerRootClassName,
    onChange,
    onFocus,
    ...rest
  } = props;
  const states = useMSBStates({
    children,
    getStateId: state => getBaseStateId(state.props.id),
    selectedStateId: getBaseStateId(selectedStateId),
    onChange,
  });

  const a11yProps = getAriaAttributes({
    ...a11y,
    ...ariaAttributes,
  });

  return renderContainer ? (
    renderContainer({ state: states })
  ) : (
    <ResponsiveBox
      {...getDataAttributes(props)}
      role={role}
      containerRootClassName={st(
        className,
        classes.root,
        containerRootClassName,
      )}
      hasPlatformClickHandler={false}
      containerProps={containerProps || getDefaultContainerProps(id)}
      id={id}
      onFocus={onFocus}
      a11y={a11yProps}
      {...rest}
    >
      {() => states}
    </ResponsiveBox>
  );
};

export default MultiStateBox;
