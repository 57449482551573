import * as React from 'react';
import { noButtonsErrorMessage, shareUrlPrefixes, TestIds } from '../constants';
import { IShareButtonProps, IShareButtonsViewerProps } from '../types';
import { shouldDisplayIcon, shouldDisplayLabel } from '../utils/display';
import getFullShareUrl from '../utils/getFullShareUrl';
import ShareButton from './ShareButton';
import { classes, st } from './style/ShareButtons.component.st.css';

const ShareButtons: React.FC<IShareButtonsViewerProps> = (
  props: IShareButtonsViewerProps,
) => {
  const {
    id,
    className,
    stylableClassName,
    buttons,
    buttonContent,
    iconPosition = 'left',
    buttonsAlignment = 'horizontal',
    horizontalDirection = 'ltr',
    shareUrl,
    translate,
  } = props;

  if (buttons.length === 0) {
    // eslint-disable-next-line no-console
    console.error(noButtonsErrorMessage);
  }

  const totalDisplayedButtons = React.useMemo(
    () => buttons.reduce((acc, next) => (acc += next.isHidden ? 0 : 1), 0),
    [buttons],
  );

  return (
    <div id={id} className={className}>
      <div className={st(classes.root, {}, stylableClassName)}>
        {totalDisplayedButtons === 1 ? (
          <ShareButton
            {...buttons.find(button => !button.isHidden)}
            url={getFullShareUrl(shareUrlPrefixes[buttons[0].value], shareUrl)}
            displayIcon={shouldDisplayIcon(buttonContent)}
            displayLabel={shouldDisplayLabel(buttonContent)}
            iconPosition={iconPosition}
            translate={translate}
          />
        ) : (
          // eslint-disable-next-line jsx-a11y/no-redundant-roles
          <ul
            id={`items_${id}`}
            className={st(classes.container, {
              buttonsAlignment,
              horizontalDirection,
            })}
            data-testid={TestIds.buttonsContainer}
            role="list"
          >
            {(buttons as Array<IShareButtonProps>)
              .filter(button => !button.isHidden)
              .map(button => (
                <li
                  key={`item_${button.id}`}
                  className={st(classes.item, {
                    buttonsAlignment,
                    horizontalDirection,
                  })}
                >
                  <ShareButton
                    {...button}
                    url={getFullShareUrl(
                      shareUrlPrefixes[button.value],
                      shareUrl,
                    )}
                    displayIcon={shouldDisplayIcon(buttonContent)}
                    displayLabel={shouldDisplayLabel(buttonContent)}
                    iconPosition={iconPosition}
                    translate={translate}
                  />
                </li>
              ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default ShareButtons;
