import {
  createComponentMapperModel,
  withStateRefsValues,
} from '@wix/editor-elements-integrations';
import { ExpandableMenuStateRefs } from '../ExpandableMenu.types';

export const stateRefs = withStateRefsValues<keyof ExpandableMenuStateRefs>([
  'currentUrl',
]);

export default createComponentMapperModel({ stateRefs });
