import {
  withCompInfo,
  createComponentMapperModel,
  withStateRefsValues,
} from '@wix/editor-elements-integrations';
import {
  FileUploaderDefinition,
  IFileUploaderMapperProps,
} from '../FileUploader.types';

export const props = withCompInfo<
  IFileUploaderMapperProps,
  FileUploaderDefinition,
  IFileUploaderMapperProps
>()(['compId', 'isMobileView'], ({ compId, isMobileView }, carmiData) => {
  return { ...carmiData, compId, isMobileView };
});

const stateRefs = withStateRefsValues([
  'enableCyclicTabbing',
  'disableCyclicTabbing',
  'setSiteScrollingBlocked',
]);

export default createComponentMapperModel({ props, stateRefs });
