import {
  withCompInfo,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';
import {
  getInputHeightInPx,
  getLabelPadding,
  getRequiredIndicationDisplay,
} from '../../../core/commons/inputUtils';
import {
  TextAreaInputDefinition,
  ITextAreaInputMapperProps,
  TextAreaInputCSSVars,
  TextAreaInputCssCarmiData,
} from '../TextAreaInput.types';

export const props = withCompInfo<
  ITextAreaInputMapperProps,
  TextAreaInputDefinition
>()(['compData', 'compProps', 'skin'], ({ compData, compProps, skin }) => ({
  skin:
    skin === 'ResponsiveTextAreaDefaultSkin'
      ? 'ResponsiveTextAreaDefaultSkin'
      : 'TextAreaDefaultSkin',
  value: compData.value || '',
  label: compData.label,
  placeholder: compData.placeholder || compProps.placeholder,
  readOnly: compProps.readOnly || false,
  required: compProps.required || false,
  isDisabled: compProps.isDisabled || false,
  maxLength: compData.maxLength,
  errorMessageType: compProps.errorMessageType,
}));

const getTextPaddingCssVar = (
  textAlignment: TextAreaInputDefinition['property']['textAlignment'],
  textPadding: TextAreaInputDefinition['property']['textPadding'],
  hasResponsiveLayout: boolean,
): string | undefined => {
  const top = hasResponsiveLayout ? '0.75em' : '3px';
  const padding = textPadding === undefined ? 3 : textPadding;
  switch (textAlignment) {
    case 'left':
      return `${top} 10px 3px ${padding}px`;
    case 'right':
      return `${top} ${padding}px 3px 10px`;
    case 'center':
      return `${top} ${padding}px 3px ${padding}px`;
    default:
      return `${top} 3px 3px 3px`;
  }
};

export const css = withCompInfo<
  TextAreaInputCSSVars,
  TextAreaInputDefinition,
  TextAreaInputCssCarmiData
>()(
  [
    'compLayout',
    'compProps',
    'styleProperties',
    'isMobileView',
    'hasResponsiveLayout',
  ],
  (
    {
      compLayout,
      compProps,
      styleProperties,
      isMobileView,
      hasResponsiveLayout,
    },
    cssVars,
  ) => {
    const { textAlignment, textPadding, labelMargin } = compProps;

    const sharedProps = {
      ...cssVars,
      '--dir': (textAlignment === 'right' ? 'rtl' : 'ltr') as 'ltr' | 'rtl',
      '--textAlign': textAlignment,
      '--textPadding': getTextPaddingCssVar(
        textAlignment,
        textPadding,
        hasResponsiveLayout,
      ),
      '--labelPadding': getLabelPadding(compProps),
      '--labelMarginBottom': `${labelMargin}px`,
      '--requiredIndicationDisplay':
        getRequiredIndicationDisplay(styleProperties),
    };

    if (hasResponsiveLayout) {
      return sharedProps;
    } else {
      return {
        ...sharedProps,
        height: 'auto',
        '--inputHeight': getInputHeightInPx({
          compProps,
          compLayout,
          isMobileView,
        }),
      };
    }
  },
);

export default createComponentMapperModel({ props, css });
