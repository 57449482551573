import { composeMapper } from '@wix/editor-elements-preview-utils';
import { createComponentPreviewMapperModel } from '@wix/editor-elements-integrations';
import {
  LoginSocialBarDefinition,
  ILoginSocialBarPreviewMapperProps,
} from '../LoginSocialBar.types';
import { props as viewerPropsMapper } from './LoginSocialBar.mapper';

const props = composeMapper<
  ILoginSocialBarPreviewMapperProps,
  LoginSocialBarDefinition,
  ILoginSocialBarPreviewMapperProps
>()(
  viewerPropsMapper,
  { add: ['compPreviewState'] },
  (resolver, deps, carmiData) => {
    const viewerProps = resolver(deps, carmiData);
    const { compPreviewState } = deps;
    const isMenuOpen = Boolean(
      compPreviewState && compPreviewState.includes('loggedInMenuOpened'),
    );

    const shouldShowAsLoggedOut =
      compPreviewState && compPreviewState.includes('loggedOut');

    return {
      ...viewerProps,
      isMenuOpen,
      isLoggedIn: !shouldShowAsLoggedOut,
      previewWrapperProps: { compPreviewState },
    };
  },
);

export default createComponentPreviewMapperModel({ props });
