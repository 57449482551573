import React from 'react';
import classNames from 'classnames';
import { SlideShowSlideProps } from '../SlideShowSlide.types';
import MeshContainer from '../../../thunderbolt-core-components/MeshContainer/viewer/MeshContainer';
import FillLayers from '../../FillLayers/viewer/FillLayers';
import { useVideoAPI } from '../../../core/useVideoAPI';
import { MediaContainerVideoAPI } from '../../MediaContainers/MediaContainer/MediaContainer.types';
import { getDataAttributes } from '../../../core/commons/utils';
import styles from './style/SlideShowSlide.scss';

const SlideShowSlide: React.ForwardRefRenderFunction<
  MediaContainerVideoAPI,
  SlideShowSlideProps
> = (props, compRef) => {
  const {
    id,
    className,
    fillLayers,
    children,
    skin,
    meshProps,
    onMouseEnter,
    onMouseLeave,
    getPlaceholder,
    onStop,
  } = props;
  const MeshContainerWithChildren = (
    <MeshContainer id={id} {...meshProps}>
      {children}
    </MeshContainer>
  );

  const hasVideo = !!fillLayers.video;
  const videoRef = useVideoAPI(compRef, hasVideo, onStop);

  return (
    <div
      id={id}
      {...getDataAttributes(props)}
      className={classNames(className, styles.SlideShowSlide)}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <FillLayers
        {...fillLayers}
        extraClass={styles.bg}
        getPlaceholder={getPlaceholder}
        videoRef={videoRef}
      />
      <div className={styles.borderNode} />
      {skin === 'StateBoxStateFormSkin' ? (
        <form
          onSubmit={e => {
            e.preventDefault();
          }}
        >
          {MeshContainerWithChildren}
        </form>
      ) : (
        MeshContainerWithChildren
      )}
    </div>
  );
};

export default React.forwardRef(SlideShowSlide);
