import {
  createComponentMapperModel,
  withStateRefsValues,
} from '@wix/editor-elements-integrations';
import { IWPhotoStateValues } from '../WPhoto.types';

const stateRefs = withStateRefsValues<keyof IWPhotoStateValues>([
  'experiments',
]);

export default createComponentMapperModel({ stateRefs });
