import {
  createComponentMapperModel,
  withCompInfo,
} from '@wix/editor-elements-integrations';
import type { GalleryItem } from '@wix/thunderbolt-components-native';

import {
  ProGalleryDefinition,
  IProGalleryProps,
  IProGallerySdkData,
} from '../ProGallery.types';

export const props = withCompInfo<
  Omit<IProGalleryProps, 'id'>,
  ProGalleryDefinition,
  { itemsProps: GalleryItem }
>()(
  [
    'compData',
    'compProps',
    'compStylableClass',
    'isQaMode',
    'fullNameCompType',
    'viewMode',
    'compLayout',
    'experiments',
    'deviceType',
    'structure',
    'hasResponsiveLayout',
  ],
  (data, carmiData) => {
    const {
      compProps,
      compStylableClass,
      isQaMode,
      fullNameCompType,
      deviceType: unmappedDeviceType,
      compLayout: { width, height },
      structure: { styleId },
      experiments,
      hasResponsiveLayout,
    } = data;

    const deviceType =
      {
        Smartphone: 'mobile',
        Tablet: 'tablet',
        Desktop: 'desktop',
        Other: 'desktop',
      }[unmappedDeviceType] || 'desktop';

    const options = {
      ...compProps.options,
      responsive: !!hasResponsiveLayout,
    };

    const newInfoThunderboltExperiment =
      'specs.thunderbolt.proGalleryMasterInfo';
    let shouldUseNewInfoElements = false;
    if (experiments) {
      shouldUseNewInfoElements =
        experiments[newInfoThunderboltExperiment] === 'new' ||
        experiments[newInfoThunderboltExperiment] === true;
    }

    return {
      ...compProps,
      options,
      viewMode: 'SITE',
      stylableClassName: compStylableClass,
      deviceType,
      items: carmiData.itemsProps || [],
      isQaMode,
      fullNameCompType,
      styleId,
      editorLayoutHeight: height,
      editorLayoutWidth: width,
      shouldUseNewInfoElements,
      experiments,
    } as any;
  },
);

/**
 * sdkData is sent to the Velo factory (in API) - needed for gallery utils
 */
export const sdkData = withCompInfo<IProGallerySdkData, ProGalleryDefinition>()(
  ['compData'],
  ({ compData }) => {
    return {
      imageDisplayMode: 'fill', // remove once mot used in EE
      dataId: compData.id,
    };
  },
);

export const css = withCompInfo()([], () => ({}));

export default createComponentMapperModel({ css, props, sdkData });
