import { withCompController } from '@wix/editor-elements-integrations';
import {
  IStylableHorizontalMenuProps,
  StylableHorizontalMenuControllerProps,
  StylableHorizontalMenuMapperProps,
  StylableHorizontalMenuStateRefs,
} from '../StylableHorizontalMenu.types';

const compController = withCompController<
  StylableHorizontalMenuMapperProps,
  StylableHorizontalMenuControllerProps,
  IStylableHorizontalMenuProps,
  StylableHorizontalMenuStateRefs
>(({ stateValues, mapperProps }) => {
  const { currentUrl } = stateValues;

  return {
    ...mapperProps,
    currentUrl,
  };
});

export default compController;
