import React from 'react';
import {
  IVerticalAnchorsMenuProps,
  ILinkContent,
} from '../../../VerticalAnchorsMenu.types';
import VerticalAnchorsMenuCommonSkin from '../VerticalAnchorsMenuCommonSkin';
import styles from './styles/skins.scss';

const linkContent = (props: ILinkContent) => {
  const { label, paddingDirection, id } = props;
  return (
    <>
      <svg className={styles.symbol} viewBox="0 0 24 24">
        <circle cx="12" cy="12" r="10" />
      </svg>
      <span className={styles.label}>
        <span
          id={id}
          className={
            paddingDirection === 'right'
              ? styles.paddingRight
              : styles.paddingLeft
          }
        >
          {label}
        </span>
      </span>
    </>
  );
};

const VerticalAnchorsMenuSymbolWithTextSkin: React.FC<
  IVerticalAnchorsMenuProps
> = props => {
  return (
    <VerticalAnchorsMenuCommonSkin
      {...props}
      style={styles}
      skin="VerticalAnchorsMenuSymbolWithTextSkin"
      linkContent={linkContent}
    />
  );
};

export default VerticalAnchorsMenuSymbolWithTextSkin;
