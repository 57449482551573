import * as React from 'react';
import classNames from 'classnames';
import { GalleryItem } from '@wix/thunderbolt-components-native';
import { CSSProperties } from 'react';
import {
  IPaginatedGalleryImperativeActions,
  PaginatedGridGallerySkinProps,
} from '../PaginatedGridGallery.types';
import { TestIds, translations } from '../constants';
import { NavigationButtons } from '../../commons/viewer/NavigationButtons';
import { GalleryCounter } from '../../commons/viewer/GalleryCounter';
import { keyCodes } from '../../../../core/commons/a11y';
import { useInterval } from '../../../../providers/useInterval/useInterval';
import { useInViewport } from '../../../../providers/useInViewport/useInViewport';
import PaginatedGridGalleryItem from '../../PaginatedGridGalleryItem/viewer/PaginatedGridGalleryItem';
import { generateItemIndexString } from '../../commons/itemUtils';
import { getDataAttributes } from '../../../../core/commons/utils';
import PaginatedGridGalleryItemsContainer from './PaginatedGridGalleryItemsContainer';
import { Panel } from './Panel';

const shouldPlay = (
  reducedMotion: boolean,
  isGalleryInViewport: boolean,
  numberOfPages: number,
  autoPlay: boolean,
  canPlay: boolean = autoPlay,
) => {
  if (reducedMotion || !isGalleryInViewport || numberOfPages <= 1) {
    return false;
  }

  return canPlay;
};

const noop = () => {};

const PaginatedGridGallery: React.ForwardRefRenderFunction<
  IPaginatedGalleryImperativeActions,
  PaginatedGridGallerySkinProps
> = (props, ref) => {
  const {
    id,
    className,
    items,
    rows,
    columns,
    imageOnClickAction,
    skinsStyle,
    skinsItemStyle,
    translate,
    showNavigation,
    showAutoplay,
    showCounter,
    currentPage,
    setCurrentPage,
    goToLinkText,
    transition,
    transitionDuration,
    hasPanel: hasCommonPanel,
    autoPlay,
    autoPlayInterval,
    setAutoPlay,
    reducedMotion,
    isPlaying: canPlay,
    buttonPrevContent,
    buttonNextContent,
    openImageZoom = noop,
    onItemClicked = noop,
    onPlay = noop,
    onPause = noop,
    onNavigationEnd = noop,
    onMouseEnter = noop,
    onMouseLeave = noop,
    getPlaceholder,
  } = props;

  const numberOfItemsPerPage = rows * columns;
  const numberOfPages = Math.ceil(items.length / numberOfItemsPerPage);
  const galleryRef = React.useRef<HTMLDivElement>(null);
  const isGalleryInViewport = useInViewport(galleryRef);

  const isPlaying = shouldPlay(
    reducedMotion,
    isGalleryInViewport,
    numberOfPages,
    autoPlay,
    canPlay,
  );

  const play = React.useCallback(() => {
    onPlay?.({ type: 'autoplayOn' });
    setAutoPlay(true);
  }, [onPlay, setAutoPlay]);

  const pause = React.useCallback(() => {
    onPause?.({ type: 'autoplayOff' });
    setAutoPlay(false);
  }, [onPause, setAutoPlay]);

  const togglePlay = () => {
    // user clicked pause
    if (autoPlay) {
      pause();
      // user clicked play
    } else {
      play();
    }
  };

  const openImageZoomAndPausePlayIfNeeded = (dataId: string) => {
    if (isPlaying) {
      pause();
      openImageZoom(dataId, id, play);
    } else {
      openImageZoom(dataId, id);
    }
  };

  const [hoveredItem, setHoveredItem] = React.useState<GalleryItem | null>(
    null,
  );

  const hasTransition = transition !== 'NoTransition';
  const inTransition = React.useRef(false);

  const onTransitionEnd = React.useCallback(() => {
    inTransition.current = false;
    onNavigationEnd();
  }, [onNavigationEnd]);

  const moveToPageWithTransition = React.useCallback(
    (newPageIndex: number) => {
      if (inTransition.current) {
        return;
      }
      inTransition.current = true;
      setCurrentPage(newPageIndex);
    },
    [setCurrentPage],
  );

  const moveToPageImmediate = React.useCallback(
    (newPageIndex: number) => {
      setCurrentPage(newPageIndex);
      onNavigationEnd();
    },
    [setCurrentPage, onNavigationEnd],
  );

  const moveToPage = React.useCallback(
    (newPageIndex: number) => {
      return hasTransition
        ? moveToPageWithTransition(newPageIndex)
        : moveToPageImmediate(newPageIndex);
    },
    [hasTransition, moveToPageWithTransition, moveToPageImmediate],
  );

  const moveToNextPage = React.useCallback(() => {
    const newPageIndex =
      currentPage === numberOfPages - 1 ? 0 : currentPage + 1;
    moveToPage(newPageIndex);
  }, [currentPage, numberOfPages, moveToPage]);

  const moveToPrevPage = React.useCallback(() => {
    const newPageIndex =
      currentPage === 0 ? numberOfPages - 1 : currentPage - 1;
    moveToPage(newPageIndex);
  }, [currentPage, numberOfPages, moveToPage]);

  const handleNavigationByKey: React.KeyboardEventHandler<
    HTMLDivElement
  > = event => {
    if (event.keyCode === keyCodes.arrowRight) {
      moveToNextPage();
    } else if (event.keyCode === keyCodes.arrowLeft) {
      moveToPrevPage();
    }
  };

  useInterval(moveToNextPage, isPlaying ? autoPlayInterval : null);

  React.useImperativeHandle(
    ref,
    () => {
      return {
        next: moveToNextPage,
        previous: moveToPrevPage,
        play,
        pause,
      };
    },
    [moveToNextPage, moveToPrevPage, play, pause],
  );

  const galleryItems = items.map((item, index) => {
    // const rowNumber = Math.floor(index / columns) % columns;
    // const columnNumber = index % columns;
    const indexInPage = index % numberOfItemsPerPage;
    const rowNumber = Math.floor(indexInPage / columns);
    const columnNumber = indexInPage % columns;
    const itemClickHandler = () => {
      onItemClicked({
        itemIndex: index,
        type: 'itemClicked',
      });
    };
    item.image.getPlaceholder = getPlaceholder;

    return (
      <PaginatedGridGalleryItem
        key={`item-${index}`}
        extraClassNames={{
          root: classNames(skinsStyle.item),
        }}
        extraStyle={
          {
            '--rowNum': rowNumber,
            '--colNum': columnNumber,
            gridRow: rowNumber + 1,
            gridColumn: columnNumber + 1,
          } as CSSProperties
        }
        translate={translate}
        imageOnClickAction={imageOnClickAction}
        openImageZoom={openImageZoomAndPausePlayIfNeeded}
        goToLinkText={goToLinkText}
        skinsStyle={skinsItemStyle}
        itemClickHandler={itemClickHandler}
        onItemMouseIn={() => setHoveredItem(item)}
        onFocus={() => setHoveredItem(item)}
        onBlur={() => setHoveredItem(null)}
        hasPanel={!hasCommonPanel}
        itemId={generateItemIndexString(index, id)}
        {...item}
      />
    );
  });

  return (
    <div
      id={id}
      {...getDataAttributes(props)}
      ref={galleryRef}
      className={classNames(className, skinsStyle.root)}
      role="region"
      aria-label={translate!(
        translations.ARIA_LABEL_NAMESPACE,
        translations.PAGINATED_GRID_GALLERY_ARIA_LABEL_KEY,
        translations.PAGINATED_GRID_GALLERY_ARIA_LABEL_DEFAULT,
      )}
      onKeyDown={handleNavigationByKey}
      onMouseEnter={onMouseEnter}
      onMouseLeave={e => {
        onMouseLeave(e);
        setHoveredItem(null);
      }}
    >
      {showNavigation && (
        <NavigationButtons
          translate={translate}
          skinsStyle={skinsStyle}
          moveToPrevItem={moveToPrevPage}
          moveToNextItem={moveToNextPage}
          reverse={false}
          withCounter={false}
          buttonPrevContent={buttonPrevContent}
          buttonNextContent={buttonNextContent}
        />
      )}
      <PaginatedGridGalleryItemsContainer
        skinsStyle={skinsStyle}
        columns={columns}
        rows={rows}
        currentPage={currentPage}
        transition={transition}
        transitionDuration={transitionDuration}
        onTransitionEnd={onTransitionEnd}
        isPlaying={isPlaying}
      >
        {galleryItems}
      </PaginatedGridGalleryItemsContainer>
      {hasCommonPanel && hoveredItem && (
        <div className={skinsStyle.outsidePanel}>
          <Panel
            title={hoveredItem.title}
            description={hoveredItem.description}
            skinsStyle={{
              title: skinsItemStyle.title,
              description: skinsItemStyle.description,
            }}
          />
        </div>
      )}
      {showAutoplay && (
        <button
          className={classNames(
            skinsStyle.playButton,
            isPlaying ? skinsStyle.autoplayOn : skinsStyle.autoplayOff,
          )}
          data-testid={TestIds.playButton}
          type="button"
          aria-label={translate!(
            translations.ARIA_LABEL_NAMESPACE,
            translations.PLAY_BUTTON_ARIA_LABEL_KEY,
            translations.PLAY_BUTTON_ARIA_LABEL_DEFAULT,
          )}
          aria-pressed={isPlaying}
          onClick={togglePlay}
        >
          <span
            className={skinsStyle.playIcon}
            data-testid={TestIds.playButtonInner}
            role="presentation"
            aria-hidden="true"
          />
        </button>
      )}
      {showCounter && (
        <GalleryCounter
          skinsStyle={skinsStyle}
          currentIndex={currentPage}
          itemsLength={numberOfPages}
        />
      )}
    </div>
  );
};

export default React.forwardRef(PaginatedGridGallery);
