import React from 'react';
import { TestIds } from '../constants';
import { st, classes } from './style/Tabs.component.st.css';

type ITabWrapperProps = {
  currentTabRef: React.RefObject<HTMLDivElement>;
  tabElement: React.ReactElement;
  isCurrentTab: boolean;
};

const TabWrapper: React.FC<ITabWrapperProps> = ({
  tabElement,
  currentTabRef,
  isCurrentTab,
}) => {
  const uniqueId = tabElement.props?.id;

  return (
    <div
      className={st(classes.tabPanelWrapper, { isCurrentTab })}
      ref={isCurrentTab ? currentTabRef : undefined}
      tabIndex={-1}
      role="tabpanel"
      aria-labelledby={`tab-${uniqueId}`}
      key={uniqueId}
      data-hook={TestIds.TabPanel}
    >
      {tabElement}
    </div>
  );
};

export default TabWrapper;
