import React from 'react';
import classNames from 'classnames';
import { SkinFiveGridLineProps } from '../SkinFiveGridLine';
import { FiveGridLineWrapper } from '../../FiveGridLineWrapper';
import skinsStyle from './ShadowBottomLine.scss';

const ShadowBottomLine: React.FC<SkinFiveGridLineProps> = wrapperProps => {
  return (
    <FiveGridLineWrapper
      {...wrapperProps}
      className={classNames(wrapperProps.className, skinsStyle.root)}
    >
      <div className={skinsStyle.line} />
    </FiveGridLineWrapper>
  );
};

export default ShadowBottomLine;
