import React, { useContext, useState, useEffect } from 'react';
import classnames from 'classnames';
import { ToolbarChildrenProps } from 'draft-js-inline-toolbar-plugin';
import { createPortal } from 'react-dom';
import LinkModal from '../../../../../../RichTextBoxLinkModal/viewer/RichTextBoxLinkModal';
import buttonStyles from '../../button.scss';
import { hasEntity } from '../../../../utils/draftUtils';
import { TestIds } from '../../../../../constants';
import StylesContext from '../../../../StylesContext';
import {
  IRichTextBoxProps,
  OverrideDialogVisibilty,
} from '../../../../../RichTextBox.types';
import LinkIcon from './LinkIcon';
import styles from './styles.scss';

export default (
  props: ToolbarChildrenProps &
    OverrideDialogVisibilty &
    Pick<
      IRichTextBoxProps,
      'linkModalId' | 'openMobileLinkModalTemp' | 'closeMobileLinkModalTemp'
    > & {
      type: IRichTextBoxProps['toolbarPosition'];
    },
) => {
  const stylesContext = useContext(StylesContext);

  const {
    setEditorState,
    getEditorState,
    type,
    linkModalId,
    openMobileLinkModalTemp,
    closeMobileLinkModalTemp,
    onOverrideDialogClose,
    onOverrideDialogOpen,
  } = props;

  const [showDesktopModal, setShowDesktopModal] = useState(false);
  const [showMobileModal, setShowMobileModal] = useState(false);
  const [mobileLinkModalWrapper, setMobileLinkModalWrapper] =
    useState<HTMLElement>();

  useEffect(() => {
    const container =
      document.getElementById('SITE_CONTAINER') || document.body;
    if (container) {
      setMobileLinkModalWrapper(container);
    }
  }, []);

  const openLinkModal = () => {
    if (openMobileLinkModalTemp) {
      setShowMobileModal(true);
      openMobileLinkModalTemp();
    } else {
      setShowDesktopModal(true);
      if (typeof onOverrideDialogOpen === 'function') {
        onOverrideDialogOpen();
      }
    }
  };

  const closeDesktopModal = () => {
    setShowDesktopModal(false);
    if (typeof onOverrideDialogClose === 'function') {
      onOverrideDialogClose();
    }
  };

  const closeMobileModal = () => {
    if (closeMobileLinkModalTemp) {
      closeMobileLinkModalTemp();
    }
    setShowMobileModal(false);
  };

  const preventBubblingUp = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const selected = hasEntity(getEditorState(), 'LINK');
  return (
    <div className={buttonStyles.buttonContainer}>
      <button
        className={classnames(buttonStyles.button, {
          [buttonStyles.hovered]: stylesContext.hovered,
          [buttonStyles.focused]: stylesContext.focused,
          [buttonStyles.error]: stylesContext.error,
        })}
        onMouseDown={preventBubblingUp}
        onClick={openLinkModal}
        data-testid={TestIds.linkButton}
        type="button"
      >
        <div
          className={classnames(
            buttonStyles.buttonContent,
            selected && buttonStyles.buttonContentSelected,
          )}
        >
          <LinkIcon />
        </div>
      </button>
      {showDesktopModal && (
        <div
          className={
            type === 'bottom' ? styles.bottomToolbar : styles.topToolbar
          }
        >
          <LinkModal
            getEditorState={getEditorState}
            setEditorState={setEditorState}
            onClose={closeDesktopModal}
            staticDialog={true}
          />
        </div>
      )}
      {showMobileModal &&
        mobileLinkModalWrapper &&
        createPortal(
          <LinkModal
            id={linkModalId}
            getEditorState={getEditorState}
            setEditorState={setEditorState}
            onClose={closeMobileModal}
          />,
          mobileLinkModalWrapper,
        )}
    </div>
  );
};
