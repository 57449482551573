import React from 'react';
import { createComponentPreviewEntry } from '@wix/editor-elements-integrations';
import {
  withScrollAPIForResponsiveContainer,
  ScrollableContainerTypes,
} from '../../../../preview-utils/scrollablePreviewWrapper';
import { withComponentPreview as withMediaContainerPreviewWrapper } from '../../../MediaContainers/MediaContainer/viewer/MediaContainer.previewWrapper';

export default (
  ViewerComponent: React.ComponentType<ScrollableContainerTypes>,
) =>
  createComponentPreviewEntry(
    withScrollAPIForResponsiveContainer(
      withMediaContainerPreviewWrapper(ViewerComponent),
    ),
  );
