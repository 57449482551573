import { createComponentPreviewMapperModel } from '@wix/editor-elements-integrations';
import { composeMapper } from '@wix/editor-elements-preview-utils';
import type { PreviewWrapperProps } from '@wix/editor-elements-types/thunderboltPreview';
import {
  FileUploaderDefinition,
  IFileUploaderMapperProps,
} from '../FileUploader.types';
import { props as viewerPropsMapper } from './FileUploader.mapper';

const props = composeMapper<
  PreviewWrapperProps<IFileUploaderMapperProps>,
  FileUploaderDefinition,
  IFileUploaderMapperProps
>()(
  viewerPropsMapper,
  {
    add: [
      'shouldResetComponent',
      'componentViewMode',
      'compPreviewState',
      'compData',
      'compProps',
    ],
  },
  (resolver, deps, carmiData) => {
    const viewerProps = resolver(deps, carmiData);
    const {
      shouldResetComponent,
      compPreviewState,
      componentViewMode,
      compData,
      compProps,
    } = deps;

    const hasPlaceholder =
      compData?.placeholderLabel && compProps?.showPlaceholder;
    const shouldRenderDummyFileName =
      !hasPlaceholder && compPreviewState?.split(' ').includes('regular');

    return {
      ...viewerProps,
      previewWrapperProps: {
        shouldResetComponent,
        compPreviewState,
        componentViewMode,
        shouldRenderDummyFileName,
      },
    };
  },
);

export default createComponentPreviewMapperModel({
  props,
});
