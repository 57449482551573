import {
  withCompInfo,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';
import {
  ImageButtonPlatformProps,
  ImageButtonDefinition,
  ImageButtonCarmiProps,
} from '../ImageButton.types';

export const props = withCompInfo<
  ImageButtonPlatformProps,
  ImageButtonDefinition,
  ImageButtonCarmiProps
>()([], (_, carmiData) => {
  return carmiData;
});

export default createComponentMapperModel({
  props,
});
