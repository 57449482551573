import {
  withCompInfo,
  createComponentMapperModel,
  withStateRefsValues,
} from '@wix/editor-elements-integrations';
import {
  IQuickActionBarMapperProps,
  QuickActionBarDefinition,
  QuickActionBarCSSVars,
} from '../QuickActionBar.types';

export const props = withCompInfo<
  IQuickActionBarMapperProps,
  QuickActionBarDefinition,
  IQuickActionBarMapperProps
>()([], (_, carmiData) => {
  return carmiData;
});

export const css = withCompInfo<
  QuickActionBarCSSVars,
  QuickActionBarDefinition,
  QuickActionBarCSSVars
>()([], (_, carmiCSSVars) => {
  return carmiCSSVars;
});

const stateRefs = withStateRefsValues(['trackEvent']);

export default createComponentMapperModel({
  props,
  css,
  stateRefs,
});
