import type {
  IStylableHorizontalMenuUITypeProps,
  ISubmenuProps,
} from '../../../../StylableHorizontalMenu.types';
import { getClasses_column } from '../styles/getClasses_column';
import { createInjectCssVars_column } from '../styles/injectCssVars_column';
import { createDepth0PositionUpdaters_column } from '../positionUpdaters/itemDepth0_column';

export const createSubmenuProps_column = ({
  stylableClassName: className,
  menuMode,
}: IStylableHorizontalMenuUITypeProps): ISubmenuProps => ({
  column: {
    positionUpdaters: [createDepth0PositionUpdaters_column(menuMode)],
    getClasses: getClasses_column,
    injectCssVars: createInjectCssVars_column(className),
  },
});
