import {
  withCompInfo,
  createComponentMapperModel,
  withStateRefsValues,
} from '@wix/editor-elements-integrations';
import { LoginSocialBarSdkData } from '@wix/thunderbolt-components';
import {
  ILoginSocialBarMapperProps,
  LoginSocialBarDefinition,
  LoginSocialBarCSSVars,
  ArrowShape,
} from '../LoginSocialBar.types';

export const props = withCompInfo<
  ILoginSocialBarMapperProps,
  LoginSocialBarDefinition,
  ILoginSocialBarMapperProps
>()(['compProps'], ({ compProps }, carmiData) => {
  return {
    ...carmiData,
    arrowShape: compProps.arrowShape ?? ArrowShape.Line,
    showBellIcon: compProps.showBellIcon ?? true,
    menuItemsAlignment: compProps.dropDownTextAlignment,
  };
});

export const css = withCompInfo<
  LoginSocialBarCSSVars,
  LoginSocialBarDefinition,
  LoginSocialBarCSSVars
>()(
  ['compProps', 'styleProperties'],
  ({ compProps, styleProperties }, carmiCss) => {
    let avatarAndArrowStrokeWidth: string;

    const typeofAvatarStrokeWidth =
      typeof styleProperties.avatarAndArrowStrokeWidth;
    // we receive avatarAndArrowStrokeWidth sometimes with 'px' sometimes without
    if (
      typeofAvatarStrokeWidth === 'number' ||
      (typeofAvatarStrokeWidth === 'string' &&
        !styleProperties.avatarAndArrowStrokeWidth.includes('px'))
    ) {
      avatarAndArrowStrokeWidth = `${styleProperties.avatarAndArrowStrokeWidth}px`;
    } else {
      avatarAndArrowStrokeWidth = styleProperties.avatarAndArrowStrokeWidth;
    }

    return {
      ...carmiCss,
      ...(compProps.arrowSize && {
        '--arrow-size': `${compProps.arrowSize}px`,
      }),
      ...(avatarAndArrowStrokeWidth && {
        '--avatarAndArrowStrokeWidth': avatarAndArrowStrokeWidth,
      }),
    };
  },
);

export const sdkData = withCompInfo<
  LoginSocialBarSdkData,
  LoginSocialBarDefinition,
  LoginSocialBarSdkData
>()([], (_deps, carmiSdkData) => {
  return carmiSdkData;
});

export const stateRefs = withStateRefsValues([
  'memberDetails',
  'reportBi',
  'currentUrl',
  'navigateTo',
  'logout',
  'promptLogin',
]);

export default createComponentMapperModel({ props, css, sdkData, stateRefs });
