import React, { useState, useCallback } from 'react';
import { keyCodes } from '@wix/thunderbolt-elements/commons/a11y';
import { IPageFormProps } from '../../../Pagination.types';
import { Slash } from '../../assets';
import { testIds } from './constants';
import { st, classes } from './style/PaginationForm.st.css';

const PageForm: React.FC<IPageFormProps> = ({
  totalPages,
  currentPage,
  isDisabled: disabled,
  showTotalPages,
  onChange,
}) => {
  const [inputValue, setInputValue] = useState(currentPage);
  const [inputError, setInputError] = useState(false);

  const onChangeHandler = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) =>
      !disabled && setInputValue(Number(event.target.value)),
    [disabled],
  );

  const onKeyDownHandler = (
    event: React.KeyboardEvent<HTMLInputElement>,
  ): void => {
    if (
      disabled ||
      event.keyCode !== keyCodes.enter ||
      inputValue === currentPage
    ) {
      return;
    }

    if (1 <= inputValue && inputValue <= totalPages) {
      onChange(inputValue);
    } else {
      setInputError(true);
    }
  };

  const onBlurHandler = useCallback(() => {
    setInputValue(currentPage);
    setInputError(false);
  }, [currentPage]);

  return (
    <div
      data-testid={testIds.PageFormRoot}
      className={st(classes.root, { disabled, error: inputError })}
      dir="ltr"
    >
      <input
        data-testid={testIds.PageFormInput}
        className={classes.input}
        type="number"
        min={1}
        max={totalPages}
        value={inputValue}
        disabled={disabled}
        onChange={onChangeHandler}
        onKeyDown={onKeyDownHandler}
        onBlur={onBlurHandler}
        aria-label={'Page number, select a number between 1 and ' + totalPages}
        {...(inputError && { 'aria-invalid': true })}
        style={
          { '--totalPagesCharLength': `${String(totalPages).length}em` } as any
        }
      />
      {showTotalPages && (
        <>
          <span className={classes.slash}>
            <Slash />
          </span>
          <span
            data-testid={testIds.PageFormTotalPages}
            className={classes.totalPages}
          >
            {totalPages}
          </span>
        </>
      )}
    </div>
  );
};

export default PageForm;
