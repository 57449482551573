import * as React from 'react';
import Slider, {
  SliderProps as WSRSliderProps,
} from 'wix-style-react/dist/src/Slider';
import type { IRangeSliderProps } from '../../RangeSlider.props';

type IContinuousSliderProps = Pick<IRangeSliderProps, 'min' | 'max'> &
  Pick<
    WSRSliderProps,
    | 'step'
    | 'value'
    | 'rtl'
    | 'className'
    | 'dataHook'
    | 'disabled'
    | 'pushable'
    | 'ariaLabelForHandle'
    | 'onChange'
  >;

const ContinuousSlider: React.FC<IContinuousSliderProps> = props => {
  const {
    className,
    dataHook,
    rtl,
    min,
    max,
    value,
    disabled,
    pushable,
    ariaLabelForHandle,
    onChange,
  } = props;

  return (
    <Slider
      className={className}
      dataHook={dataHook}
      rtl={rtl}
      min={min}
      max={max}
      step={0.1}
      value={value}
      displayMarks={false}
      displayTooltip={true}
      allowCross={false}
      onChange={onChange}
      disabled={disabled}
      pushable={pushable}
      ariaLabelForHandle={ariaLabelForHandle}
    />
  );
};

export default ContinuousSlider;
