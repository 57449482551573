import React from 'react';
import classNames from 'classnames';
import { ISpotifyFollowProps } from '../SpotifyFollow.types';
import { getDataAttributes } from '../../../core/commons/utils';
import style from './style/SpotifyFollow.scss';

const placeholders: Record<ISpotifyFollowProps['placeholderImage'], string> = {
  basic_hide: style.placeholderBasicHide,
  basic_show: style.placeholderBasicShow,
  detail_light_hide: style.placeholderDetailLightHide,
  detail_light_show: style.placeholderDetailLightShow,
  detail_dark_hide: style.placeholderDetailDarkHide,
  detail_dark_show: style.placeholderDetailDarkShow,
};

const SpotifyFollow: React.FC<ISpotifyFollowProps> = props => {
  const {
    id,
    className,
    iframeSrc,
    width,
    height,
    placeholderImage,
    onMouseEnter,
    onMouseLeave,
  } = props;

  return (
    <wix-iframe
      id={id}
      {...getDataAttributes(props)}
      className={className}
      data-src={iframeSrc}
      data-valid-uri={!!iframeSrc}
      style={{ width: `${width}px`, height: `${height + 2}px` }}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {/* eslint-disable-next-line jsx-a11y/iframe-has-title */}
      <iframe
        className={classNames(style.iframe, {
          [style.displayNone]: !iframeSrc,
        })}
        style={{ width: `${width}px`, height: `${height}px` }}
        data-src={iframeSrc}
        scrolling="no"
      />
      <div
        className={classNames(
          style.placeholder,
          placeholders[placeholderImage],
          { [style.displayNone]: !!iframeSrc },
        )}
      />
    </wix-iframe>
  );
};

export default SpotifyFollow;
