import {
  createComponentMapperModel,
  withCompInfo,
} from '@wix/editor-elements-integrations';
import {
  GridDefinition,
  IGridMapperProps,
  GridProperties,
  GridCSSVars,
  GridPagination,
  GridSkin,
  GridHeaderColumn,
  GridHorizontalTextAlignment,
  GridHorizontalContentAlignment,
} from '../Grid.types';
import { paginationAlignmentMap } from '../constants';

const skinToHeaderColumnMap: Record<GridSkin, GridHeaderColumn> = {
  GridSkin: 'none',
  GridLeftHeaderSkin: 'first',
  GridRightHeaderSkin: 'last',
};

const horizontalContentAlignmentMap: Record<
  GridHorizontalTextAlignment,
  GridHorizontalContentAlignment
> = {
  center: 'center',
  left: 'flex-start',
  right: 'flex-end',
};

const mapPagination = ({
  type,
  rowsPerPage,
  style,
}: GridProperties['pagination']): GridPagination => ({
  type,
  rowsPerPage,
  style,
});

export const props = withCompInfo<IGridMapperProps, GridDefinition>()(
  ['skin', 'compProps', 'compData', 'compLayout', 'topology', 'viewMode'],
  ({
    skin,
    compProps: {
      columns,
      showHeader,
      pagination,
      userSelectionType,
      dateFormat,
      linkTarget,
      columnLayout,
      rowHeight,
    },
    compData: { rows, dataSource },
    compLayout: { width },
    topology: { staticMediaUrl },
  }) => ({
    dataSource: dataSource.type,
    columns,
    rows,
    headerColumn: skinToHeaderColumnMap[skin],
    showHeader,
    pagination: mapPagination(pagination),
    userSelectionType,
    dateFormat,
    linkTarget,
    columnLayout,
    staticMediaUrl,
    rowHeight,
    width,
  }),
);

export const css = withCompInfo<GridCSSVars, GridDefinition>()(
  ['compProps', 'styleProperties'],
  ({
    compProps: {
      headerHeight,
      rowHeight,
      heightLayout,
      horizontalAlignment,
      pagination: { alignment = 'right' },
    },
    styleProperties: { cellPadding },
  }) => ({
    ...(heightLayout === 'auto' && { height: 'auto' }),
    '--headerHeight': `${headerHeight}px`,
    '--rowHeight': `${rowHeight}px`,
    '--horizontalTextAlignment': horizontalAlignment,
    '--horizontalContentAlignment':
      horizontalContentAlignmentMap[horizontalAlignment],
    ...(horizontalAlignment === 'left' && { '--cellPaddingLeft': cellPadding }),
    ...(horizontalAlignment === 'right' && {
      '--cellPaddingRight': cellPadding,
    }),
    '--paginationAlignment': paginationAlignmentMap[alignment],
  }),
);

export default createComponentMapperModel({ props, css });
