import * as React from 'react';
import classNames from 'classnames';
import { TestIds } from '../../constants';
import { classes } from '../Slider.st.css';
import { SliderTickMarksPosition } from '../../Slider.types';

interface TicksProps {
  step: number;
  min: number;
  max: number;
  maxTickDensity: number;
  trackSize: number;
  onTickClick(clientX: number): void;
  tickMarksPosition: SliderTickMarksPosition;
}

const Ticks: React.FC<TicksProps> = ({
  step,
  min,
  max,
  maxTickDensity,
  onTickClick,
  trackSize,
  tickMarksPosition,
}) => {
  const total = max - min;
  const totalTickCount = total / step;
  const density = Math.min(totalTickCount / trackSize, maxTickDensity);
  const adjustedStep = total / (trackSize * density);
  const ticks: Array<number> = [];

  for (let i = min; i < max; i += adjustedStep) {
    ticks.push((i - min) / total);
  }
  ticks.push(1);

  return (
    <div
      className={classNames({
        [classes.ticksWrapper]: tickMarksPosition !== 'middle',
      })}
      data-testid={TestIds.ticksWrapper}
    >
      {ticks.map((percent, index) => (
        <div
          data-testid={TestIds.tick + index}
          className={classes.tick}
          key={percent}
          onClick={event => onTickClick(event.clientX)}
          style={
            {
              '--tickLeftPercent': percent,
            } as React.CSSProperties
          }
        />
      ))}
    </div>
  );
};

export default React.memo(Ticks);
