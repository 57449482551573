import {
  withCompInfo,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';
import { getInputHeightInPx } from '../../../core/commons/inputUtils';
import {
  TimePickerDefinition,
  ITimePickerMapperProps,
  TimePickerCSSVars,
  TimePickerCssCarmiData,
  TimePickerAlignment,
} from '../TimePicker.types';
import { getEnabledTimes } from '../utils';

export const props = withCompInfo<
  ITimePickerMapperProps,
  TimePickerDefinition
>()(
  ['compData', 'compProps', 'hasResponsiveLayout'],
  ({ compData, compProps, hasResponsiveLayout }) => {
    const { value, placeholder, step, label } = compData;
    const {
      useAmPmFormat,
      controller,
      initialTime,
      readOnly,
      isDisabled,
      required,
      disabledTimes,
      enabledTimes,
    } = compProps;

    return {
      value,
      placeholder: initialTime === 'none' ? '' : placeholder,
      step,
      label,
      useAmPmFormat,
      controller,
      initialTime,
      readOnly,
      isDisabled,
      required,
      isResponsive: hasResponsiveLayout,
      enabledTimes: getEnabledTimes({ enabledTimes, disabledTimes }),
    };
  },
);

const alignmentCssVars = (
  alignment: TimePickerAlignment,
  labelPadding: number,
) => {
  return {
    '--textAlign': alignment,
    ...(alignment === 'right'
      ? {
          '--dir': 'rtl' as 'rtl',
          '--arrowMargin': '0 0 0 20px',
          '--flexDirection': 'row-reverse',
        }
      : {
          '--dir': 'ltr' as 'ltr',
          '--arrowMargin': '0 20px 0 0',
          '--flexDirection': 'row',
        }),
    ...(alignment === 'right'
      ? {
          '--labelPadding': `0 ${labelPadding}px 0 0`,
        }
      : alignment === 'center'
      ? {
          '--labelPadding': '0',
        }
      : {
          '--labelPadding': `0 0 0 ${labelPadding}px`,
        }),
  };
};

const inputPaddingVar = (
  alignment: TimePickerAlignment,
  isMobileView: boolean,
  margin: number = 15,
) => {
  return isMobileView
    ? `0 ${margin}px`
    : alignment === 'left'
    ? `0 0 0 ${margin}px`
    : alignment === 'right'
    ? `0 ${margin}px 0 0`
    : '0';
};

export const css = withCompInfo<
  TimePickerCSSVars,
  TimePickerDefinition,
  TimePickerCssCarmiData
>()(
  ['compLayout', 'compProps', 'isMobileView', 'hasResponsiveLayout'],
  ({ compLayout, compProps, isMobileView, hasResponsiveLayout }, cssVars) => {
    const { labelMargin, labelPadding, alignment, margin } = compProps;

    const sharedProps = {
      ...cssVars,
      ...alignmentCssVars(alignment, labelPadding),
      '--labelMarginBottom': `${labelMargin}px`,
      '--inputPadding': inputPaddingVar(alignment, isMobileView, margin),
    };

    if (hasResponsiveLayout) {
      return sharedProps;
    } else {
      return {
        ...sharedProps,
        height: 'auto',
        '--inputHeight': getInputHeightInPx({
          compProps,
          compLayout,
          isMobileView: false,
        }),
      };
    }
  },
);

export default createComponentMapperModel({ css, props });
