import React from 'react';

/**
 * When previewWrapper re-renders, useImperativeHandle hook updates ref.current and returns new handle
 * object with new functions. previewWrapper re-renders when component is expanded in Editor.
 *
 * However ds-feature-components-measures keeps reference to ref.current, and not ref so it will call
 * initial handle functions to measure component size.
 *
 * Link: https://github.com/wix-private/thunderbolt/blob/master/packages/ds-feature-components-measures/src/componentsMeasures.ts#L35,
 * Those initial functions do not reflect changes to previewWrapperProps.compPreviewState in their scope, so previewWrapperProps.
 *
 * So compPreviewState is wrapped in a ref as a workaround to ensure that handle functions get latest value of compPreviewState
 */

export function useCustomMeasuresDependency<T>(dependency: T) {
  const dependencyRef = React.useRef(dependency);

  React.useEffect(() => {
    dependencyRef.current = dependency;
  }, [dependency]);

  return dependencyRef;
}
