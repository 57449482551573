import { useEffect, useState } from 'react';
import { CompLayout, MenuMode } from '../../StylableHorizontalMenu.types';

/*
  when in the editor component becomes resizable
  we leave "height: auto" till the component's height got remeasured
*/
export const useForceHeightAuto = (
  menuMode: MenuMode,
  compLayout?: CompLayout,
): boolean => {
  const [isWaitingForMeasure, setIsWaitingForMeasure] = useState(false);

  useEffect(() => {
    if (menuMode === 'scroll') {
      setIsWaitingForMeasure(true);
    }
  }, [menuMode]);
  useEffect(() => {
    setIsWaitingForMeasure(false);
  }, [compLayout]);

  if (menuMode === 'wrap') {
    // component has "height: auto" by default
    return false;
  }

  return isWaitingForMeasure;
};
