import type { DeviceType } from '@wix/thunderbolt-ssr-api';
import {
  withCompInfo,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';
import {
  DatePickerDefinition,
  DatePickerCssCarmiData,
  DatePickerSkin,
} from '../../DatePicker/DatePicker.types';
import { isMobileViewOrDevice } from '../../DatePicker/viewer/DatePicker.mapper';
import { DatePickerCalendarCSSVars } from '../DatePickerCalendar.types';

export const calendarButtonPositionVars = (
  textAlignment: DatePickerDefinition['property']['textAlignment'],
) => {
  switch (textAlignment) {
    case 'right':
      return {
        '--calendarButtonLeft': '20px',
        '--calendarButtonRight': 'auto',
      };
    default:
      return {
        '--calendarButtonLeft': 'auto',
        '--calendarButtonRight': '20px',
      };
  }
};

const calendarPositionVars = (
  textAlignment: DatePickerDefinition['property']['textAlignment'],
) => {
  switch (textAlignment) {
    case 'right':
      return {
        '--calendarLeft': 'auto',
        '--calendarRight': '0',
      };
    case 'center':
      return {
        '--calendarLeft': 'calc(50% - 160px)',
        '--calendarRight': 'auto',
      };
    default:
      return {
        '--calendarLeft': '0',
        '--calendarRight': 'auto',
      };
  }
};

const calendarNavRotationVar = (
  textAlignment: DatePickerDefinition['property']['textAlignment'],
) => {
  switch (textAlignment) {
    case 'right':
      return {
        '--calendarNavRotate': '180deg',
      };
    default:
      return {
        '--calendarNavRotate': '0',
      };
  }
};

const calendarNavButtonMarginVars = (
  skin: DatePickerSkin,
  isMobile: boolean,
  textAlignment: DatePickerDefinition['property']['textAlignment'],
) => {
  const getMargins = () => {
    switch (skin) {
      case 'DatePickerTextBetweenNavSkin': {
        if (isMobile) {
          return {
            '--calendarNavYearPrevMargin': [0, -6, -12, 0],
            '--calendarNavYearNextMargin': [-10, 0, 0, -10],
            '--calendarNavMonthPrevMargin': [6, -10, -10, 6],
            '--calendarNavMonthNextMargin': [-10, 54, 54, -10],
          };
        } else {
          return {
            '--calendarNavYearPrevMargin': [],
            '--calendarNavYearNextMargin': [],
            '--calendarNavMonthPrevMargin': [25, 0, 0, 25],
            '--calendarNavMonthNextMargin': [0, 20, 20, 0],
          };
        }
      }
      case 'DatePickerTextYearNavSkin': {
        if (isMobile) {
          return {
            '--calendarNavYearPrevMargin': [],
            '--calendarNavYearNextMargin': [],
            '--calendarNavMonthPrevMargin': [12, -10, -10, 12],
            '--calendarNavMonthNextMargin': [-10, 12, 12, -10],
          };
        } else {
          return {
            '--calendarNavYearPrevMargin': [],
            '--calendarNavYearNextMargin': [],
            '--calendarNavMonthPrevMargin': [10, 0, 0, 10],
            '--calendarNavMonthNextMargin': [0, 10, 10, 0],
          };
        }
      }
      default: {
        if (isMobile) {
          return {
            '--calendarNavYearPrevMargin': [],
            '--calendarNavYearNextMargin': [],
            '--calendarNavMonthPrevMargin': [],
            '--calendarNavMonthNextMargin': [],
          };
        } else {
          return {
            '--calendarNavYearPrevMargin': [],
            '--calendarNavYearNextMargin': [],
            '--calendarNavMonthPrevMargin': [5, 0, 5, 0],
            '--calendarNavMonthNextMargin': [0, 5, 0, 5],
          };
        }
      }
    }
  };
  const isRtl = textAlignment === 'right';
  return Object.entries(getMargins()).reduce(
    (margins, [varName, [left, right, rtlLeft, rtlRight]]) => {
      return {
        ...margins,
        [varName]: isRtl
          ? `0 ${rtlRight || 0}px 0 ${rtlLeft || 0}px`
          : `0 ${right || 0}px 0 ${left || 0}px`,
      };
    },
    {},
  ) as {
    '--calendarNavYearPrevMargin': string;
    '--calendarNavYearNextMargin': string;
    '--calendarNavMonthPrevMargin': string;
    '--calendarNavMonthNextMargin': string;
  };
};

export const getCss = (
  {
    skin,
    compProps,
    isMobileView,
    deviceType,
    withCalendarPortal,
  }: {
    skin: DatePickerSkin;
    compProps: DatePickerDefinition['property'];
    isMobileView: boolean;
    deviceType: DeviceType;
    withCalendarPortal: boolean;
  },
  cssVars: DatePickerCssCarmiData,
): DatePickerCalendarCSSVars => {
  const { textAlignment } = compProps;

  const isMobile = isMobileViewOrDevice(isMobileView, deviceType);

  return {
    ...cssVars,
    '--dir': (textAlignment === 'right' ? 'rtl' : 'ltr') as 'ltr' | 'rtl',
    ...calendarButtonPositionVars(textAlignment),
    ...calendarPositionVars(textAlignment),
    ...calendarNavRotationVar(textAlignment),
    '--calendarMarginTop': isMobile || withCalendarPortal ? '0' : '22px',
    ...calendarNavButtonMarginVars(skin, isMobile, textAlignment),
  };
};

const calendarWidth = (
  isMobileView: boolean,
  deviceType: DeviceType,
  withCalendarPortal: boolean,
) => {
  const isMobile = isMobileViewOrDevice(isMobileView, deviceType);
  const withCalendarPortalWidth = '320px';
  const nonMobileWidth = withCalendarPortal
    ? { width: withCalendarPortalWidth }
    : {};

  return isMobile ? { width: '100%' } : nonMobileWidth;
};

export const css = withCompInfo<
  DatePickerCalendarCSSVars,
  DatePickerDefinition,
  DatePickerCssCarmiData
>()(
  ['skin', 'compProps', 'isMobileView', 'deviceType', 'experiments'],
  ({ skin, compProps, isMobileView, deviceType, experiments }, cssVars) => {
    const isMobile = isMobileViewOrDevice(isMobileView, deviceType);
    const withCalendarPortal =
      experiments['specs.thunderbolt.DatePickerPortal'] === 'new' ||
      experiments['specs.thunderbolt.DatePickerPortal'] === true;

    return {
      ...getCss(
        {
          skin,
          compProps,
          isMobileView,
          deviceType,
          withCalendarPortal,
        },
        cssVars,
      ),
      ...calendarWidth(isMobile, deviceType, withCalendarPortal),
    };
  },
);

export default createComponentMapperModel({ css });
