import * as React from 'react';
import classnames from 'classnames';
import { IAudioPlayerProps } from '../../../AudioPlayer.types';
import BasePlayer from '../../BasePlayer';
import { TEST_IDS } from '../../constants';
import style from './styles/Audio3DPlayer.scss';

const Audio3DPlayer: React.FC<IAudioPlayerProps> = props => {
  const playIcon = (
    <div className={style.first}>
      <div className={style.second}>
        <div className={style.gradient}></div>
      </div>
      <span
        data-testid={TEST_IDS.icon}
        className={classnames(style.icon, style.pausing)}
      ></span>
    </div>
  );
  const pauseIcon = (
    <div className={style.first}>
      <div className={style.second}>
        <div className={style.gradient}></div>
      </div>
      <span
        data-testid={TEST_IDS.icon}
        data-playing="true"
        className={classnames(style.icon, style.playing)}
      ></span>
    </div>
  );

  return (
    <BasePlayer
      {...props}
      style={style}
      playIcon={playIcon}
      pauseIcon={pauseIcon}
    />
  );
};

export default Audio3DPlayer;
