import * as React from 'react';
import classNames from 'classnames';
import SiteMembersDialogLayout from '../../SiteMembersDialogLayout/viewer/SiteMembersDialogLayout';
import { IConfirmationEmailDialogProps } from '../ConfirmationEmailDialog.types';
import { activateBySpaceOrEnterButton } from '../../../core/commons/a11y';
import style from './style/style.scss';
import {
  translationFeature,
  ConfirmationEmailDialogTranslationKeys as translationKeys,
  ConfirmationEmailDialogTranslationFallback as translationFallback,
  TestIds,
} from './constants';

const ConfirmationEmailDialog: React.FC<
  IConfirmationEmailDialogProps
> = props => {
  const {
    id,
    className,
    isSignUp,
    onCloseDialogCallback,
    onResendConfirmationEmail,
  } = props;
  const headlineId = `ConfirmationEmailHeadline_${id}`;
  const translate = props.translate!.bind(null, translationFeature);
  const title = translate(translationKeys.title, translationFallback.title);
  const signUpTitle = translate(
    translationKeys.signUpTitle,
    translationFallback.signUpTitle,
  );
  const subtitle = translate(translationKeys.subtitle, 'Check your inbox');
  const signUpSubtitle = translate(
    translationKeys.signUpSubtitle,
    translationFallback.signUpSubtitle,
  );
  const signUpSecondarySubtitle = translate(
    translationKeys.signUpSecondarySubtitle,
    translationFallback.signUpSecondarySubtitle,
  );
  const description = translate(
    translationKeys.description,
    translationFallback.description,
  );
  const descriptionSecondary = translate(
    translationKeys.descriptionSecondary,
    translationFallback.descriptionSecondary,
  );
  const descriptionLink = translate(
    translationKeys.descriptionLink,
    translationFallback.descriptionLink,
  );

  return (
    <SiteMembersDialogLayout
      isCloseable={true}
      translate={props.translate}
      onCloseDialogCallback={onCloseDialogCallback}
      headlineId={headlineId}
      className={className}
    >
      <div
        id={id}
        className={classNames(style.confirmationEmailDialog, {
          [style.emailVerification]: !isSignUp,
        })}
      >
        <div className={style.header}>
          <h2 id={headlineId} className={style.title}>
            {isSignUp ? signUpTitle : title}
          </h2>
          {!isSignUp && <p className={style.subtitle}>{subtitle}</p>}
        </div>
        <div className={style.content}>
          {isSignUp && (
            <div className={style.signUpSubtitle}>
              <div data-testid={TestIds.signUpSubtitle}>{signUpSubtitle}</div>
              <div data-testid={TestIds.signUpSecondarySubtitle}>
                {signUpSecondarySubtitle}
              </div>
            </div>
          )}
          <div data-testid={TestIds.description}>{description}</div>
          <div data-testid={TestIds.secondaryDescription}>
            {descriptionSecondary}
            <span
              className={style.resendConfirmationEmailButton}
              data-testid={TestIds.resendConfirmationEmailButton}
              onClick={onResendConfirmationEmail}
              role="button"
              tabIndex={0}
              onKeyDown={activateBySpaceOrEnterButton}
            >
              {descriptionLink}
            </span>
          </div>
        </div>
      </div>
    </SiteMembersDialogLayout>
  );
};

export default ConfirmationEmailDialog;
