import React from 'react';
import { createComponentPreviewEntry } from '@wix/editor-elements-integrations';
import type { PreviewWrapperProps } from '@wix/editor-elements-types/thunderboltPreview';
import { CssBorder } from '@wix/thunderbolt-components-native';
import { mapValues } from 'lodash';
import {
  MediaContainerCompProps,
  MediaContainerVideoAPI,
} from '../MediaContainer.types';
import { useCustomMeasuresDependency } from '../../../../preview-utils/useCustomMeasuresDependency';
import { useOnVideoStopCallback } from '../../../../preview-utils/useOnVideoStopCallback';
import { useVideoPlayEffect } from '../../../../preview-utils/useVideoPlayEffect';

export function withComponentPreview<
  T extends Record<string, any> = MediaContainerCompProps,
>(ViewerComponent: React.ComponentType<T>) {
  return React.forwardRef<MediaContainerVideoAPI, T>(
    (
      {
        previewWrapperProps = {},
        ...viewerProps
      }: PreviewWrapperProps<
        T,
        {
          autoPlayVideo?: boolean;
          isPlayingAllowed?: boolean;
          borderWidth?: CssBorder['width'];
        }
      >,
      ref,
    ) => {
      const { autoPlayVideo, isPlayingAllowed, borderWidth } =
        previewWrapperProps;
      const compRef = React.useRef<MediaContainerVideoAPI | null>(null);

      const borderWidthRef = useCustomMeasuresDependency(borderWidth);

      const getMeasuredBorderWidths = (mediaContainer: HTMLElement) => {
        if (!borderWidthRef.current) {
          return {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
          };
        }

        // When browser has a zoom border becomes a decimal value, so we must measure it (https://jira.wixpress.com/browse/TB-6482)
        const computedStyle = window.getComputedStyle(mediaContainer);
        const borderWidths = {
          top: 'Top' as const,
          right: 'Right' as const,
          bottom: 'Bottom' as const,
          left: 'Left' as const,
        };
        return mapValues(borderWidths, borderSideName =>
          Number(
            (computedStyle[`border${borderSideName}Width`] || '0').replace(
              'px',
              '',
            ),
          ),
        );
      };

      React.useImperativeHandle<MediaContainerVideoAPI | null, any>(
        ref,
        () => ({
          getCustomMeasures: () => {
            return {
              width: {
                [props.id]: () => {
                  const mediaContainer = document.getElementById(props.id);
                  if (!mediaContainer) {
                    return;
                  }

                  const borderWidths = getMeasuredBorderWidths(mediaContainer);
                  const width = mediaContainer.offsetWidth;
                  return width - borderWidths.left - borderWidths.right;
                },
              },
              height: {
                [props.id]: () => {
                  const mediaContainer = document.getElementById(props.id);
                  if (!mediaContainer) {
                    return;
                  }
                  const borderWidths = getMeasuredBorderWidths(mediaContainer);
                  const height = mediaContainer.offsetHeight;
                  return height - borderWidths.top - borderWidths.bottom;
                },
              },
              topOffset: {
                [props.id]: () => {
                  const mediaContainer = document.getElementById(props.id);
                  if (!mediaContainer) {
                    return;
                  }
                  const borderWidths = getMeasuredBorderWidths(mediaContainer);
                  return borderWidths.top;
                },
              },
              leftOffset: {
                [props.id]: () => {
                  const mediaContainer = document.getElementById(props.id);
                  if (!mediaContainer) {
                    return;
                  }
                  const borderWidths = getMeasuredBorderWidths(mediaContainer);
                  return borderWidths.left;
                },
              },
            };
          },
          ...(compRef && compRef.current),
        }),
      );

      useVideoPlayEffect({ compRef, isPlayingAllowed, autoPlayVideo });

      const onStop = useOnVideoStopCallback();

      const props = {
        ...viewerProps,
        ref: compRef,
        onStop: isPlayingAllowed ? undefined : onStop,
      } as unknown as T;

      return <ViewerComponent {...props} />;
    },
  );
}

export default (
  ViewerComponent: React.ComponentType<MediaContainerCompProps>,
) => createComponentPreviewEntry(withComponentPreview(ViewerComponent));
