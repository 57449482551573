import {
  withCompInfo,
  withCompPreviewInfo,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';
import { ColumnDefinition, ColumnCarmiData, ColumnCss } from '../Column.types';

export const props = withCompInfo<
  ColumnCarmiData,
  ColumnDefinition,
  ColumnCarmiData
>()([], (_, carmiData) => {
  return carmiData;
});

export const css = withCompPreviewInfo<
  ColumnCss,
  ColumnDefinition,
  ColumnCss
>()([], (_, carmiData) => {
  return carmiData;
});

export default createComponentMapperModel({
  props,
  css,
});
