import { TranslationGetter } from '@wix/editor-elements-types/thunderbolt';
import React from 'react';
import { DownloadLogo } from '../../common/assets/logos';
import { TestIds } from '../../common/constants';
import {
  AppsNames,
  NAMESPACE,
  TranslationFallbacks,
  TranslationKeys,
} from './constants';
import style from './style/FreemiumBannerMobile.scss';

type PreferredAppContentProps = {
  preferredApp: string | undefined;
  translate: TranslationGetter;
};

const PreferredAppContent: React.FC<PreferredAppContentProps> = ({
  preferredApp,
  translate,
}) => {
  let bannerTextTranslationKey = TranslationKeys.spacesApp;
  let bannerFallbackText = TranslationFallbacks.WixOneApp;

  if (preferredApp === AppsNames.WixFitnessApp) {
    bannerTextTranslationKey = TranslationKeys.fitnessApp;
    bannerFallbackText = TranslationFallbacks.WixFitnessApp;
  } else if (preferredApp === AppsNames.WixRestaurantsApp) {
    bannerTextTranslationKey = TranslationKeys.dineApp;
    bannerFallbackText = TranslationFallbacks.WixRestaurantsApp;
  }
  const bannerText = translate(
    NAMESPACE,
    bannerTextTranslationKey,
    bannerFallbackText,
  );
  return (
    <>
      <span data-hook={TestIds.content}>{bannerText}</span>
      <DownloadLogo className={style.downloadLogo} />
    </>
  );
};
export default PreferredAppContent;
