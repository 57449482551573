import {
  withCompInfo,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';
import {
  LoginButtonDefinition,
  LoginButtonCSSVars,
} from '../LoginButton.types';

export const css = withCompInfo<LoginButtonCSSVars, LoginButtonDefinition>()(
  ['compLayout'],
  ({ compLayout }) => {
    return {
      height: 'auto',
      'min-height': `${compLayout.height}px`,
    };
  },
);

export default createComponentMapperModel({ css });
