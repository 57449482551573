import { createComponentPreviewMapperModel } from '@wix/editor-elements-integrations';
import { composeMapper } from '@wix/editor-elements-preview-utils';
import type { PreviewWrapperProps } from '@wix/editor-elements-types/thunderboltPreview';
import { HeaderSdkStyles } from '@wix/thunderbolt-symbols';
import {
  HeaderContainerCarmiProps,
  HeaderContainerDefinition,
  HeaderContainerCarmiCss,
} from '../HeaderContainer.types';
import {
  props as viewerPropsMapper,
  css as viewerCssMapper,
  sdkData as viewerSdkMapper,
} from './HeaderContainer.mapper';

const props = composeMapper<
  PreviewWrapperProps<HeaderContainerCarmiProps>,
  HeaderContainerDefinition,
  HeaderContainerCarmiProps
>()(
  viewerPropsMapper,
  {
    add: [
      'compPreviewState',
      'componentViewMode',
      'compLayout',
      'shouldResetComponent',
    ],
  },
  (resolver, deps, carmiData) => {
    const viewerProps = resolver(deps, carmiData);
    const {
      compPreviewState,
      componentViewMode,
      shouldResetComponent,
      compLayout: { fixedPosition },
    } = deps;
    return {
      ...viewerProps,
      previewWrapperProps: {
        compPreviewState,
        componentViewMode,
        shouldResetComponent,
        fixedPosition,
      },
    };
  },
);

const css = composeMapper<
  HeaderContainerCarmiCss,
  HeaderContainerDefinition,
  HeaderContainerCarmiCss
>()(
  viewerCssMapper,
  {
    add: ['renderFixedPositionContainers', 'compLayout', 'isMobileView'],
  },
  (resolver, dependencies, carmiData) => {
    const viewerCss = resolver(dependencies, carmiData);
    const { renderFixedPositionContainers, compLayout, isMobileView } =
      dependencies;
    const styles: Partial<
      HeaderContainerCarmiCss & {
        height?: string;
        left?: string;
      }
    > = {};
    if (!renderFixedPositionContainers) {
      styles.position = undefined;
    }
    if (isMobileView && compLayout.fixedPosition) {
      styles.left = 'auto';
    }
    if (isMobileView && compLayout.height) {
      styles.height = `${compLayout.height}px`;
    }
    return { ...viewerCss, ...styles };
  },
);

const sdkData = composeMapper<
  HeaderSdkStyles,
  HeaderContainerDefinition,
  HeaderSdkStyles
>()(viewerSdkMapper, {}, (resolver, dependencies, carmiData) => {
  return resolver(dependencies, carmiData);
});

export default createComponentPreviewMapperModel({
  props,
  css,
  sdkData,
});
