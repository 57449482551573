import * as React from 'react';
import { SlavePlayer } from '@wix/soundhead-react';
import {
  IPlayerRef,
  IPlayerContainerProps,
} from '../../../SingleAudioPlayer.types';
import { keyCodes } from '../../../../../core/commons/a11y';

const PlayerContainer: React.FC<IPlayerContainerProps> = props => {
  const {
    id,
    className,
    autoPlay,
    reducedMotion,
    player: Player,
    onMouseEnter,
    onMouseLeave,
    ariaLabel = '',
  } = props;
  const playerRef = React.useRef<IPlayerRef>();
  const barsCount = 5;
  const volumeStep = 1 / barsCount;
  const currentTime = React.useRef(0);
  const duration = React.useRef(0);

  const handleReady = () =>
    autoPlay && !reducedMotion && playerRef.current?.play();

  const [isMute, setMute] = React.useState(false);
  const [shouldDisplayRepeatIcon, setShouldDisplayRepeatIcon] =
    React.useState(false);
  const setRepeatIcon = () => {
    if (!props.loop) {
      setShouldDisplayRepeatIcon(true);
    }
  };

  const setDuration = (newDuration: number) => {
    duration.current = newDuration;
  };
  const setCurrentTime = (newCurrentTime: number) => {
    currentTime.current = newCurrentTime;
  };

  const setPlayIcon = () => {
    setShouldDisplayRepeatIcon(false);
  };

  const getCurrentVolume = () =>
    playerRef.current?.playback.getAudioProps().volume || 0;

  const keyCodeToAction = {
    [keyCodes.arrowDown]: () => {
      const newVolume = Math.max(getCurrentVolume() - volumeStep, 0);
      playerRef.current?.changeVolume(newVolume);
    },
    [keyCodes.arrowUp]: () => {
      const newVolume = Math.min(getCurrentVolume() + volumeStep, 1);
      playerRef.current?.changeVolume(newVolume);
    },
    [keyCodes.arrowLeft]: () => {
      const newDuration = Math.max(
        currentTime.current - duration.current * 0.1,
        0,
      );
      playerRef.current?.seekTo(newDuration);
    },
    [keyCodes.arrowRight]: () => {
      const newDuration = Math.min(
        currentTime.current + duration.current * 0.1,
        duration.current,
      );
      playerRef.current?.seekTo(newDuration);
    },
    [keyCodes.space]: () => playerRef.current?.togglePlay(),
    [keyCodes.enter]: () => playerRef.current?.togglePlay(),
  };

  const onKeyDownHandler: React.KeyboardEventHandler = event => {
    if (keyCodeToAction.hasOwnProperty(event.keyCode)) {
      keyCodeToAction[event.keyCode as keyof typeof keyCodeToAction]();
    }
  };

  return (
    <SlavePlayer
      playerId={id}
      {...props}
      playerRef={(player: IPlayerRef) => (playerRef.current = player)}
      onTrackReadyToPlay={handleReady}
      onMuteChanged={setMute}
      onEnded={setRepeatIcon}
      onKeyDown={onKeyDownHandler}
      onPlayRequested={setPlayIcon}
      onDurationChanged={setDuration}
      onTimeUpdated={setCurrentTime}
    >
      <Player
        id={id}
        className={className}
        shouldDisplayRepeatIcon={shouldDisplayRepeatIcon}
        isMute={isMute}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        ariaLabel={ariaLabel}
      />
    </SlavePlayer>
  );
};
export default PlayerContainer;
