import * as React from 'react';
// TODO - Arrange it better, currently skin components are not exported via the index
import ResponsiveBox from '@wix/thunderbolt-elements/components/Container/ResponsiveBox';
import { getDataAttributes } from '@wix/thunderbolt-elements/commons/utils';
import { IMegaMenuContainerItemPropsResponsive } from '../../../MegaMenuContainerItem.types';

const MegaMenuContainerItem: React.FC<
  IMegaMenuContainerItemPropsResponsive
> = props => {
  const { id, children, containerRootClassName } = props;
  const childrenToRender =
    typeof children === 'function' ? children : () => children;
  return (
    <ResponsiveBox
      {...getDataAttributes(props)}
      containerProps={props.containerProps}
      hasPlatformClickHandler={false}
      id={id}
      containerRootClassName={containerRootClassName}
    >
      {childrenToRender}
    </ResponsiveBox>
  );
};

export default MegaMenuContainerItem;
