import { withCompController } from '@wix/editor-elements-integrations';
import {
  LineShareButtonMapperProps,
  LineShareStateRefs,
  LineShareButtonControllerProps,
} from '../LineShareButton.types';

const compController = withCompController<
  LineShareButtonMapperProps,
  LineShareButtonControllerProps,
  never,
  LineShareStateRefs
>(({ stateValues, mapperProps }) => {
  return {
    ...mapperProps,
    currentUrl: stateValues.currentUrlWithQueryParams,
  };
});

export default compController;
