import * as React from 'react';

import { ISearchBoxProps } from '../../../SearchBox.types';
import { buildSuggestions } from '../buildSuggestions';

const NOT_SELECTED_INDEX = -1;

export function useSuggestionNavigation(
  props: ISearchBoxProps,
  shouldShowSuggestions: boolean,
) {
  const {
    id,
    value,
    onSuggestionGroupSelect,
    onSuggestionSelect,
    suggestions,
    translate,
  } = props;

  const [activeOptionIndex, setActiveOptionIndex] =
    React.useState(NOT_SELECTED_INDEX);
  const scrollIntoRef = React.useRef<HTMLInputElement>(null);

  React.useEffect(() => {
    if (scrollIntoRef.current) {
      scrollIntoRef.current.scrollIntoView?.(false);
    }
  }, [activeOptionIndex]);

  React.useEffect(() => {
    if (!shouldShowSuggestions) {
      setActiveOptionIndex(NOT_SELECTED_INDEX);
    }
  }, [shouldShowSuggestions]);

  React.useEffect(() => {
    setActiveOptionIndex(NOT_SELECTED_INDEX);
  }, [value]);

  const suggestionsWithNavigation = React.useMemo(() => {
    return buildSuggestions(
      {
        id,
        onSuggestionGroupSelect,
        onSuggestionSelect,
        suggestions,
        translate,
      },
      activeOptionIndex,
    );
  }, [
    activeOptionIndex,
    id,
    onSuggestionGroupSelect,
    onSuggestionSelect,
    suggestions,
    translate,
  ]);

  const selectableSuggestions = suggestionsWithNavigation.items.filter(
    suggestion => suggestion.item.url,
  );

  const selectableSuggestionsLastItemIndex = selectableSuggestions.length;

  const activeSelectableSuggestionIndex = selectableSuggestions.findIndex(
    suggestion =>
      suggestion.id === suggestionsWithNavigation.items[activeOptionIndex]?.id,
  );

  const moveUp = () => {
    const selectableSuggestionIndex =
      activeSelectableSuggestionIndex > 0
        ? activeSelectableSuggestionIndex - 1
        : selectableSuggestionsLastItemIndex - 1;

    setActiveOptionIndex(
      suggestionsWithNavigation.items.findIndex(
        suggestion =>
          suggestion.id ===
          selectableSuggestions[selectableSuggestionIndex]?.id,
      ),
    );
  };

  const moveDown = () => {
    const selectableSuggestionIndex =
      activeSelectableSuggestionIndex < selectableSuggestionsLastItemIndex - 1
        ? activeSelectableSuggestionIndex + 1
        : 0;

    setActiveOptionIndex(
      suggestionsWithNavigation.items.findIndex(
        suggestion =>
          suggestion.id ===
          selectableSuggestions[selectableSuggestionIndex]?.id,
      ),
    );
  };

  const navigate = () => {
    try {
      suggestionsWithNavigation?.items?.[activeOptionIndex].action();
    } catch (ex) {
      // eslint-disable-next-line no-console
      console.error('Error while navigating', ex);
    }
  };

  return {
    moveDown,
    moveUp,
    navigate,
    scrollIntoRef,
    activeOptionIndex,
    suggestionsWithNavigation,
  };
}
