import React from 'react';

export const useVideoPlayEffect = ({
  compRef,
  isPlayingAllowed,
  autoPlayVideo,
}: {
  compRef: React.MutableRefObject<{
    load: () => void;
    stop: () => void;
  } | null>;
  isPlayingAllowed?: boolean;
  autoPlayVideo?: boolean;
}) =>
  React.useEffect(() => {
    if (!isPlayingAllowed) {
      compRef?.current?.stop();
    } else if (autoPlayVideo) {
      compRef?.current?.load();
    }
  }, [compRef, isPlayingAllowed, autoPlayVideo]);
