import React from 'react';
import classnames from 'classnames';

import { SearchSymbol } from '../searchSymbol';
import { HAS_CUSTOM_FOCUS_CLASSNAME } from '../../../../../../core/commons/a11y';
import { ISearchBoxLayoutProps } from '../../SearchBoxLayout';
import style from './SearchButton.scss';

type SearchButtonProps = Pick<
  ISearchBoxLayoutProps,
  'searchButtonLabel' | 'onFocus'
>;
export function SearchButton({
  searchButtonLabel,
  onFocus,
}: SearchButtonProps) {
  return (
    <button
      aria-label={searchButtonLabel}
      className={classnames(style.button, HAS_CUSTOM_FOCUS_CLASSNAME)}
      onFocus={onFocus}
      onClick={e => {
        e.stopPropagation();
      }}
    >
      <SearchSymbol />
    </button>
  );
}
