import * as React from 'react';
import cx from 'classnames';
import { ICaptchaDialogProps } from '../CaptchaDialog.types';
import Captcha from '../../Captcha/viewer/Captcha';
import * as translations from '../constants';
import {
  keyCodes,
  activateBySpaceOrEnterButton,
} from '../../../core/commons/a11y';
import { getDataAttributes } from '../../../core/commons/utils';
import style from './style/CaptchaDialog.scss';
import Close from './assets/close.svg';

const CaptchaDialog: React.FC<ICaptchaDialogProps> = props => {
  const {
    id,
    language = 'en',
    className,
    translate,
    onVerified,
    onClose,
  } = props;

  const [recaptchaToken, setCaptchaToken] = React.useState('');
  const dialogRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    dialogRef.current?.focus();
  }, [dialogRef]);

  const arialLabelTranslateFn = translate!.bind(
    null,
    translations.ARIA_LABELS_NAMESPACE,
  );
  const captchaTranslateFn = translate!.bind(
    null,
    translations.CAPTCHA_NAMESPACE,
  );
  const dialogText = arialLabelTranslateFn(
    translations.CAPTCHA_DIALOG_KEY,
    translations.CAPTCHA_DIALOG_DEFAULT,
  );
  const closeButtonText = arialLabelTranslateFn(
    translations.CAPTCHA_CLOSE_KEY,
    translations.CAPTCHA_CLOSE_DEFAULT,
  );
  const titleText = captchaTranslateFn(
    translations.CAPTCHA_TITLE_KEY,
    translations.CAPTCHA_TITLE_DEFAULT,
  );
  const subtitleText = captchaTranslateFn(
    translations.CAPTCHA_SUBTITLE_KEY,
    translations.CAPTCHA_SUBTITLE_DEFAULT,
  );

  const closeOnEscape = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.keyCode === keyCodes.escape) {
      onClose();
    }
  };

  return (
    <div
      id={id}
      {...getDataAttributes(props)}
      className={cx(style.captchaDialog, className)}
      onKeyDown={closeOnEscape}
    >
      <div
        className={style.blockingLayer}
        onClick={onClose}
        data-testid="captcha-blocking"
      />
      <div
        className={style.dialog}
        aria-modal="true"
        aria-label={dialogText}
        aria-describedby={`captchaDescription_${id}`}
        data-testid="captcha-dialog"
        role="dialog"
        tabIndex={-1}
        ref={dialogRef}
      >
        <div className={style.wrapper}>
          <button
            className={style.xButton}
            aria-label={closeButtonText}
            data-testid="close-btn"
            onClick={onClose}
            onKeyDown={activateBySpaceOrEnterButton}
          >
            <Close />
          </button>
          <div className={style.content}>
            <h2 className={style.title} data-testid="title">
              {titleText}
            </h2>
            <p
              className={style.subtitle}
              data-testid="subtitle"
              id={`captchaDescription_${id}`}
            >
              {subtitleText}
            </p>
            <div className={style.captchaWrapper}>
              <Captcha
                language={language}
                id={`captchaInput_${id}`}
                onTokenChange={(token = '') => {
                  setCaptchaToken(token);

                  if (token !== '') {
                    setTimeout(() => {
                      onVerified(token);
                    }, 500);
                  }
                }}
                token={recaptchaToken}
                isMobileFriendly={false}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CaptchaDialog;
