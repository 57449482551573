import React from 'react';
import classNames from 'classnames';
import { SkinPageProps } from '../SkinPage';
import Page from '../../Page';
import styles from './styles/SloopyPageSkin.scss';

const SloopyPageSkin: React.FC<SkinPageProps> = ({
  id,
  className,
  pageDidMount,
  onClick,
  onDblClick,
  children,
  onMouseEnter,
  onMouseLeave,
}) => {
  return (
    <div
      id={id}
      className={classNames(styles.pageWrapper, className)}
      ref={pageDidMount}
      onClick={onClick}
      onDoubleClick={onDblClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <div className={styles.bg}>
        <div className={styles.innerBg} />
        <div className={styles.innerBg2} />
      </div>
      <div className={styles.inlineContent}>{children()}</div>
    </div>
  );
};

const SloopyPage: React.FC<SkinPageProps> = props => (
  <Page {...props} skin={SloopyPageSkin} />
);

export default SloopyPage;
