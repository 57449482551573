import React from 'react';

import { createComponentPreviewEntry } from '@wix/editor-elements-integrations';
import type { IComponentPreviewWrapper } from '@wix/editor-elements-types/thunderboltPreview';
import type { IComponentCustomMeasureImperativeAPI } from '@wix/thunderbolt-becky-types';

import { withComponentPreview as withMediaContainerPreviewWrapper } from '../../MediaContainers/MediaContainer/viewer/MediaContainer.previewWrapper';
import {
  StripColumnsContainerProps,
  StripColumnsContainerPreviewProps,
} from '../StripColumnsContainer.types';
import { MediaContainerVideoAPI } from '../../MediaContainers/MediaContainer/MediaContainer.types';

function withComponentPreview(
  ViewerComponent: React.ComponentType<
    StripColumnsContainerProps & { ref: React.Ref<MediaContainerVideoAPI> }
  >,
): IComponentPreviewWrapper<
  StripColumnsContainerProps,
  StripColumnsContainerPreviewProps
> {
  return React.forwardRef<
    IComponentCustomMeasureImperativeAPI,
    StripColumnsContainerProps
  >((props, ref) => {
    const mediaContainerRef = React.useRef<MediaContainerVideoAPI | null>(null);

    React.useImperativeHandle(ref, () => ({
      getCustomMeasures: () => {
        return {
          relativeToScreenOverrides: {
            [props.id]: () => {
              const stripColumns = document.getElementById(props.id);
              if (stripColumns) {
                const { x, width } = stripColumns.getBoundingClientRect();
                return { x, width };
              }
              return;
            },
          },
          left: {
            [props.id]: () => 0,
          },
        };
      },
    }));

    return <ViewerComponent {...props} ref={mediaContainerRef} />;
  });
}

export default (
  ViewerComponent: React.ComponentType<StripColumnsContainerProps>,
) =>
  createComponentPreviewEntry(
    withComponentPreview(withMediaContainerPreviewWrapper(ViewerComponent)),
  );
