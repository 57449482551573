import {
  withCompInfo,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';
import type {
  ICheckboxMapperProps,
  CheckboxDefinition,
} from '../Checkbox.types';

export const props = withCompInfo<
  ICheckboxMapperProps,
  CheckboxDefinition,
  ICheckboxMapperProps
>()([], (_deps, carmiData) => {
  return carmiData;
});

export default createComponentMapperModel({ props });
