import { withCompController } from '@wix/editor-elements-integrations';
import type {
  ILoginSocialBarControllerProps,
  ILoginSocialBarMapperProps,
  ILoginSocialBarProps,
  ILoginSocialBarStateValues,
} from '../LoginSocialBar.types';

export default withCompController<
  ILoginSocialBarMapperProps,
  ILoginSocialBarControllerProps,
  ILoginSocialBarProps,
  ILoginSocialBarStateValues
>(({ mapperProps, controllerUtils, stateValues }) => {
  const { updateProps } = controllerUtils;
  const {
    memberDetails,
    reportBi,
    currentUrl,
    navigateTo,
    logout,
    promptLogin,
  } = stateValues;
  const membersData = {
    isLoggedIn: false,
    userName: '',
    avatarUri: '',
  };

  if (
    memberDetails &&
    (!!memberDetails.nickname ||
      !!memberDetails.memberName ||
      !!memberDetails.loginEmail)
  ) {
    membersData.isLoggedIn = true;
    membersData.userName =
      memberDetails.nickname ||
      memberDetails.memberName ||
      memberDetails.loginEmail;
    membersData.avatarUri = memberDetails.imageUrl;
  }

  return {
    ...mapperProps,
    ...membersData,
    onMenuOpen: () => {
      updateProps({
        isMenuOpen: true,
      });
    },
    onMenuClose: () => {
      updateProps({
        isMenuOpen: false,
      });
    },
    reportBi,
    currentPrimaryPageHref: currentUrl,
    navigateTo,
    onLogout: () => {
      logout();
    },
    onLogin: () => {
      promptLogin();
    },
  };
});
