import {
  withCompInfo,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';
import {
  CoBrandingBannerDefinition,
  CoBrandingBannerPlatformProps,
} from '../CoBrandingBannerDesktop.types';

export const props = withCompInfo<
  CoBrandingBannerPlatformProps,
  CoBrandingBannerDefinition
>()(['direction'], ({ direction }) => {
  return {
    direction,
  };
});

export default createComponentMapperModel({ props });
