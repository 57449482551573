import {
  withCompInfo,
  createComponentMapperModel,
  withStateRefsValues,
} from '@wix/editor-elements-integrations';
import {
  PinterestPinItMapperProps,
  PinterestPinItDefinition,
  PinterestPinItStateRefs,
} from '../PinterestPinIt.types';
import { BUTTON_SIZES } from '../constants';

export const props = withCompInfo<
  PinterestPinItMapperProps,
  PinterestPinItDefinition
>()(
  ['compData', 'compProps', 'topology', 'externalBaseUrl'],
  ({
    compData: { uri, description, urlFormat, isHttpsEnabled },
    compProps,
    topology,
    externalBaseUrl,
  }) => {
    const { width, height } =
      BUTTON_SIZES[compProps.counterPosition][compProps.size];
    return {
      uri,
      description,
      size: compProps.size,
      color: compProps.color,
      counterPosition: compProps.counterPosition,
      staticMediaUrl: topology.staticMediaUrl,
      width: `${width}px`,
      height: `${height}px`,
      urlFormat,
      externalBaseUrl,
      isHttpsEnabled,
    };
  },
);

export const stateRefs = withStateRefsValues<keyof PinterestPinItStateRefs>([
  'pageId',
  'relativeUrl',
]);

export default createComponentMapperModel({ props, stateRefs });
