import { createComponentPreviewMapperModel } from '@wix/editor-elements-integrations';
import { composeMapper } from '@wix/editor-elements-preview-utils';
import {
  PaginatedGridGalleryCarmiData,
  PaginatedGridGalleryDefinition,
  IPaginatedGridGalleryMapperProps,
} from '../PaginatedGridGallery.types';
import { props as viewerPropsMapper } from './PaginatedGridGallery.mapper';

export const props = composeMapper<
  IPaginatedGridGalleryMapperProps,
  PaginatedGridGalleryDefinition,
  PaginatedGridGalleryCarmiData
>()(
  viewerPropsMapper,
  {
    add: ['isPlayingAllowed'],
  },
  (resolver, deps, carmiData) => {
    const viewerProps = resolver(deps, carmiData);
    return {
      ...viewerProps,
      autoPlay: deps.isPlayingAllowed ? viewerProps.autoPlay : false,
    };
  },
);

export default createComponentPreviewMapperModel({
  props,
});
