
  var rt = require("@stylable/runtime/dist/css-runtime-stylesheet.js");

  module.exports = rt.create(
      "AccordionContainer1266025101",
      {"classes":{"root":"AccordionContainer1266025101__root","accordion":"AccordionContainer1266025101__accordion","accordionHeader":"AccordionContainer1266025101__accordionHeader","accordionContent":"AccordionContainer1266025101__accordionContent","animationBox":"AccordionContainer1266025101__animationBox"},"vars":{"flexDirection":"--AccordionContainer1266025101-flexDirection","direction":"--AccordionContainer1266025101-direction","vPadding":"--AccordionContainer1266025101-vPadding","hPadding":"--AccordionContainer1266025101-hPadding","textAlign":"--AccordionContainer1266025101-textAlign"},"stVars":{"animation-time":"var(--animation-time)","content-height":"var(--content-height)"},"keyframes":{},"layers":{}},
      "",
      -1,
      module.id
  );
  