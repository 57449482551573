import {
  withCompInfo,
  withCompPreviewInfo,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';
import {
  StripColumnsContainerDefinition,
  StripColumnsContainerCarmiData,
  StripColumnsContainerCss,
} from '../StripColumnsContainer.types';

export const props = withCompInfo<
  StripColumnsContainerCarmiData,
  StripColumnsContainerDefinition,
  StripColumnsContainerCarmiData
>()([], (_, carmiData) => {
  return carmiData;
});

export const css = withCompPreviewInfo<
  StripColumnsContainerCss,
  StripColumnsContainerDefinition,
  StripColumnsContainerCss
>()(['compProps', 'isMobileView'], ({ compProps, isMobileView }, carmiData) => {
  // temporary fix to https://jira.wixpress.com/browse/TB-1838 issue in mobile that caused by chromium bug (https://bugs.chromium.org/p/chromium/issues/detail?id=1168537#c1) - should remove when fixed
  const columnsMargin = compProps.columnsMargin;
  const rowMargin = compProps.rowMargin;

  return {
    ...carmiData,
    '--firstChildMarginTop': columnsMargin === 0 ? '-1px' : undefined,
    '--lastChildMarginBottom': columnsMargin === 0 ? '-1px' : undefined,
    ...(isMobileView && columnsMargin === 0 && { '--margin': '-1px' }),
    ...(isMobileView && rowMargin === 0 && { '--padding': '-1px' }),
  };
});

export default createComponentMapperModel({
  props,
  css,
});
