import { HAS_CUSTOM_FOCUS_CLASSNAME } from '@wix/thunderbolt-elements/commons/a11y';
import style0 from './itemDepth0.st.css';
import style1 from './itemDepth1.st.css';

const depthStyleMap = [style0, style1, style1];

type MenuItemLabelClasses = {
  root: string;
  itemWrapper: string;
  container: string;
  label: string;
};

export type GetMenuItemLabelClassesProps = {
  depth: number;
  isHovered: boolean;
  isCurrentPage: boolean;
  className: string;
};

export const getLabelClasses = ({
  depth,
  isHovered,
  isCurrentPage,
  className: parentClassName,
}: GetMenuItemLabelClassesProps): MenuItemLabelClasses => {
  const { st, classes } = depthStyleMap[depth] || style0;

  return {
    root: st(
      classes.root,
      { isHovered, isCurrentPage },
      parentClassName,
      depth === 0 ? HAS_CUSTOM_FOCUS_CLASSNAME : '',
    ),
    itemWrapper: classes.itemWrapper,
    container: classes.container,
    label: classes.label,
  };
};
