import React from 'react';
import { createComponentPreviewEntry } from '@wix/editor-elements-integrations';
import type {
  IComponentPreviewWrapper,
  PreviewWrapperProps,
} from '@wix/editor-elements-types/thunderboltPreview';
import { usePreviewState } from '@wix/editor-elements-preview-utils';
import { selectors } from '../constants';
import { ITabsImperativeActions, ITabsProps } from '../Tabs.types';
import { classes as scrollButtonClasses } from './TabsList/style/TabsScrollButton.st.css';

const previewStateMap = {
  [selectors.scrollButton]: {
    selectors: `.${scrollButtonClasses.root}`,
    type: 'plural',
    state: 'isVisible',
  },
};

function withComponentPreview(
  ViewerComponent: React.ComponentType<ITabsProps>,
): IComponentPreviewWrapper<ITabsProps, any> {
  return React.forwardRef<ITabsImperativeActions, ITabsProps>(
    (props: PreviewWrapperProps<ITabsProps>, ref) => {
      const { previewWrapperProps = {}, ...viewerProps } = props;
      const { compPreviewState } = previewWrapperProps;
      const { id } = viewerProps;

      usePreviewState(id, compPreviewState, previewStateMap);

      return <ViewerComponent {...viewerProps} ref={ref} />;
    },
  );
}

export default (ViewerComponent: React.ComponentType<ITabsProps>) =>
  createComponentPreviewEntry<ITabsProps, any>(
    withComponentPreview(ViewerComponent),
  );
