import React from 'react';
import type { IAccordionLayoutProps } from '../../types';
import { handleKeyDown } from '../utils/handleKeyDown';
import { iconsMap } from '../icons';
import { st, classes } from '../AccordionContainer.component.st.css';
import { classes as headerClasses } from '../AccordionContainerHeader.st.css';
import { classes as headerLabelClasses } from '../AccordionContainerHeaderLabel.st.css';
import { classes as headerIconClasses } from '../AccordionContainerHeaderIcon.st.css';

export const AccordionLayout: React.FC<IAccordionLayoutProps> = ({
  item: { id, label, content },
  isOpened,
  toggleItem,
  icon,
  animationProps: {
    isContentShown = isOpened,
    onTransitionEnd,
    contentHeight,
    contentRef,
  } = {},
}) => {
  const [iconCollapsed, iconExpanded] = iconsMap[icon];

  return (
    <div className={st(classes.accordion, { isOpened })} role="group">
      {/* this div can have a11y role heading in future (phase 2) */}
      <div>
        <button
          className={st(headerClasses.root, classes.accordionHeader)}
          onClick={() => toggleItem(id)}
          onKeyDown={handleKeyDown}
          type="button"
          id={id}
          aria-expanded={isOpened}
          aria-haspopup={isOpened}
        >
          <span
            className={st(
              headerLabelClasses.root,
              headerClasses.accordionLabel,
            )}
          >
            {label}
          </span>
          <span
            className={st(headerIconClasses.root, headerClasses.accordionIcon)}
          >
            {isOpened ? iconExpanded : iconCollapsed}
          </span>
        </button>
      </div>
      <div
        className={classes.animationBox}
        onTransitionEnd={onTransitionEnd}
        style={{ '--content-height': contentHeight } as any}
        aria-labelledby={id}
      >
        <div
          className={st(classes.accordionContent, {
            isContentHidden: !isContentShown,
          })}
          ref={contentRef}
        >
          {content}
        </div>
      </div>
    </div>
  );
};
