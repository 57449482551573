import {
  createComponentMapperModel,
  withCompInfo,
} from '@wix/editor-elements-integrations';
import {
  ItunesButtonDefinition,
  ItunesButtonPlatformProps,
  ItunesButtonCSSVars,
} from '../ItunesButton.types';

export const props = withCompInfo<
  ItunesButtonPlatformProps,
  ItunesButtonDefinition,
  ItunesButtonPlatformProps
>()([], (_deps, carmiProps) => carmiProps);

export const css = withCompInfo<
  ItunesButtonCSSVars,
  ItunesButtonDefinition,
  ItunesButtonCSSVars
>()([], (_deps, carmiProps) => carmiProps);

export default createComponentMapperModel({ props, css });
