import classnames from 'classnames';
import * as React from 'react';
import {
  IContainerProps,
  IContainerImperativeActions,
} from '../../../Container.types';
import { ContainerLogic } from '../../shared/ContainerLogic';
import styles from './ForkedRibbonArea.scss';

const ForkedRibbonArea: React.ForwardRefRenderFunction<
  IContainerImperativeActions,
  IContainerProps
> = (props, ref) => {
  return (
    <ContainerLogic
      {...props}
      ref={ref}
      className={classnames(styles.root, props.className)}
      renderSlot={({ containerChildren }) => (
        <>
          <b className={styles.top} />
          <b className={styles.bot} />
          <b className={styles.ctr} />
          <div className={styles.bg} />
          {containerChildren}
        </>
      )}
    />
  );
};

export default React.forwardRef(ForkedRibbonArea);
