import * as React from 'react';
import Image from '../../Image/viewer/Image';
import { IGridProps, GridImageData } from '../Grid.types';
import { extractImageDataFromSrc } from '../utils';
import styles from './styles/Grid.scss';

type IImageCellProps = Pick<IGridProps, 'staticMediaUrl'> & {
  value: any;
  id: string;
  containerId: string;
  maxWidth?: number;
  maxHeight: number;
};

const isExternalUrl = (url: string) =>
  /(^https?)|(^data)|(^blob)|(^\/\/)/.test(url);

const buildMediaImageSrc = (
  { uri }: GridImageData,
  staticMediaUrl: string,
  maxHeight: number,
  maxWidth: number,
) => `//${staticMediaUrl}/${uri}/v1/fit/w_${maxWidth},h_${maxHeight}/${uri}`;

const ImageCell: React.FC<IImageCellProps> = ({
  value,
  id,
  containerId,
  staticMediaUrl,
  maxHeight,
  // With equal column layout this value is computed based on Grid component
  // compLayout.width - which is marked as optional in Thunderbolt (for responsive).
  // We default to 999 in this case since we still have to send it as part of URL.
  maxWidth = 999,
}) => {
  const src = React.useMemo(() => {
    let imageData: GridImageData | undefined;

    if (typeof value === 'string') {
      const extractedImageData = extractImageDataFromSrc(value);
      if (extractedImageData) {
        imageData = extractedImageData;
      }
    } else if (typeof value === 'object') {
      imageData = value;
    }

    if (!imageData) {
      return undefined;
    }

    return isExternalUrl(imageData.uri)
      ? imageData.uri
      : buildMediaImageSrc(imageData, staticMediaUrl, maxHeight, maxWidth);
  }, [value, staticMediaUrl, maxHeight, maxWidth]);

  return src ? (
    <Image
      id={id}
      containerId={containerId}
      className={styles.image}
      uri={src}
      width={maxWidth}
      height={maxHeight}
      displayMode="original_size"
      alt=""
      name=""
    />
  ) : null;
};

export default ImageCell;
