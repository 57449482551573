import * as React from 'react';

import { DataFetcherDirection, TestId } from '../constants';
import { GridDataFetcherDirection } from '../Grid.types';
import ArrowIcon from './assets/arrow.svg';
import styles from './styles/Grid.scss';

type IDirectionalPaginationProps = {
  onChangePage(direction: GridDataFetcherDirection): void;
  nextEnabled?: boolean;
  previousEnabled?: boolean;
};

const DirectionalPagination: React.FC<IDirectionalPaginationProps> = ({
  onChangePage,
  nextEnabled,
  previousEnabled,
}) => {
  const handleNextClick = () =>
    nextEnabled && onChangePage(DataFetcherDirection.Next);
  const handlePrevClick = () =>
    previousEnabled && onChangePage(DataFetcherDirection.Previous);

  return (
    <div className={styles.pagination} data-testid={TestId.Pagination}>
      <button
        className={styles.prevPage}
        onClick={handlePrevClick}
        disabled={!previousEnabled}
        aria-label="Previous"
        data-testid={TestId.PagePrev}
      >
        <ArrowIcon />
      </button>
      <button
        className={styles.nextPage}
        onClick={handleNextClick}
        disabled={!nextEnabled}
        aria-label="Next"
        data-testid={TestId.PageNext}
      >
        <ArrowIcon />
      </button>
    </div>
  );
};

export default DirectionalPagination;
