import {
  withCompInfo,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';
import { getCompStyles } from '../../../tpaGallery';
import { TPAGallerySdkData } from '../../../tpaGallery/types';
import {
  ImpressDefinition,
  ImpressPropsPlatformProps,
  ImpressPropsCarmiData,
  ImpressCSSVars,
} from '../Impress.types';
import {
  propsDependencies,
  propsResolver,
} from '../../TPAGallery/viewer/TPAGalleryMapperUtil';

const compStyleKeys = {
  bcgColor1: 'color1',
  bcgColor2: 'color2',
  bcgColor3: 'color3',
  bcgColor4: 'color4',
  bcgColor5: 'color5',
  textColor: 'color6',
  descriptionColor: 'color7',
  textBackgroundColor: 'color8',
};

export const props = withCompInfo<
  ImpressPropsPlatformProps,
  ImpressDefinition,
  ImpressPropsCarmiData
>()(
  [...propsDependencies, 'styleProperties', 'siteColors'],
  (data, carmiProps) => ({
    ...propsResolver<ImpressDefinition>(
      data,
      carmiProps,
      'Impress/Impress.html',
    ),
    currentIndex: 0,
    compStyles: getCompStyles(
      compStyleKeys,
      data.styleProperties,
      data.siteColors,
    ),
  }),
);

const sdkData = withCompInfo<TPAGallerySdkData, ImpressDefinition>()(
  ['compData'],
  ({ compData }) => {
    return {
      dataId: compData.id,
    };
  },
);

const css = withCompInfo<ImpressCSSVars, ImpressDefinition>()(
  ['compLayout'],
  ({ compLayout }) => ({
    '--height': `${compLayout.height}px`,
  }),
);

export default createComponentMapperModel({ css, props, sdkData });
