import {
  withCompPreviewProps,
  createComponentPreviewMapperModel,
} from '@wix/editor-elements-integrations';
import {
  ExpandableMenuProps,
  ExpandableMenuDefinition,
} from '../ExpandableMenu.types';
import { forceOpenMenu } from './utils/forceOpenMenu';

const demoData = [
  {
    label: 'Menu item 1',
    items: [],
    link: undefined,
  },
  {
    label: 'Menu item 2',
    items: [],
    link: undefined,
  },
  {
    label: 'Menu item 3',
    items: [],
    link: undefined,
  },
];
const props = withCompPreviewProps<
  ExpandableMenuProps,
  ExpandableMenuDefinition,
  ExpandableMenuProps
>()(
  ['compPreviewState', 'isWixBlocks'],
  ({ compPreviewState, isWixBlocks }, { items, ...carmiData }) => {
    if (isWixBlocks) {
      // TODO: replace labels with translation keys
      items = demoData;
    }

    return {
      items: ['regular', 'selected'].includes(compPreviewState as string)
        ? forceOpenMenu(items)
        : items,
      ...carmiData,
    };
  },
);

export default createComponentPreviewMapperModel({ props } as any);
