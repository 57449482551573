
  var rt = require("@stylable/runtime/dist/css-runtime-stylesheet.js");

  module.exports = rt.create(
      "ShareButtoncomponent1171347912",
      {"classes":{"root":"ShareButtoncomponent1171347912__root","item":"ShareButtoncomponent1171347912__item","icon":"ShareButtoncomponent1171347912__icon","label":"ShareButtoncomponent1171347912__label"},"vars":{},"stVars":{"icon-text-spacing":"var(--icon-text-spacing)"},"keyframes":{},"layers":{}},
      "",
      -1,
      module.id
  );
  