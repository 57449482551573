import cn from 'classnames';
import React, { useState, useEffect, useRef, useMemo } from 'react';
import { keyCodes } from '@wix/thunderbolt-elements/commons/a11y';
import Link from '@wix/thunderbolt-elements/components/Link';
import { testIds } from '../../testIds';
import { getIsCurrentPage } from '../../utils/getIsCurrentPage';
import type { IMenuItemLabelProps } from '../../../StylableHorizontalMenu.types';
import { getLabelClasses } from './styles/getLabelClasses';

const createEventListeners = (
  setIsHovered: (val: React.SetStateAction<boolean>) => void,
) => {
  const showSubmenu = () => setIsHovered(true);
  const hideSubmenu = () => setIsHovered(false);

  return {
    onMouseEnter: showSubmenu,
    onMouseLeave: hideSubmenu,
    onFocus: showSubmenu,
    onBlur: (e: React.FocusEvent) => {
      if (!e.currentTarget.contains(e.relatedTarget)) {
        hideSubmenu();
      }
    },
    onKeyDown: (e: React.KeyboardEvent) => {
      const menuItemLabel = e.currentTarget.firstChild as HTMLElement;
      if (menuItemLabel !== e.target) {
        return;
      }

      switch (e.keyCode) {
        case keyCodes.arrowLeft:
        case keyCodes.arrowRight:
          const siblingNavigateTo =
            e.currentTarget[
              e.keyCode === keyCodes.arrowLeft
                ? 'previousSibling'
                : 'nextSibling'
            ];
          (siblingNavigateTo?.firstChild as HTMLElement)?.focus();
          break;
        default:
          break;
      }
    },
  };
};

export const MenuItemLabel: React.FC<IMenuItemLabelProps> = ({
  item,
  className,
  withSubItemsClassname,
  currentPageHref,
  depth,
  isStretched,
  hasColumnSubSubs,
  positionUpdaters: positionUpdatersMap,
  positionBoxRef,
  children,
  onItemClick,
  onItemDblClick,
  onItemMouseIn,
  onItemMouseOut,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const labelRef = useRef<HTMLAnchorElement | HTMLDivElement | null>(null);

  const { label, link, forceHovered = false } = item;
  const eventListeners = createEventListeners(setIsHovered);
  const isCurrentPage =
    // Provided by Velo API to force the current page to be highlighted/unhighlighted
    item.selected ?? getIsCurrentPage(link, currentPageHref);

  const isHeading = hasColumnSubSubs && depth === 1;
  const classes = getLabelClasses({
    depth,
    isHovered: isHeading ? false : isHovered,
    isCurrentPage,
    className,
  });

  const handleOnClick = useMemo(
    () =>
      onItemClick
        ? (mouseEvent: React.MouseEvent) => {
            onItemClick?.(mouseEvent, {
              ...item,
              selected: isCurrentPage,
            });
          }
        : undefined,
    [onItemClick, isCurrentPage, item],
  );

  const handleOnDoubleClick = useMemo(
    () =>
      onItemDblClick
        ? (mouseEvent: React.MouseEvent) => {
            onItemDblClick?.(mouseEvent, {
              ...item,
              selected: isCurrentPage,
            });
          }
        : undefined,
    [onItemDblClick, isCurrentPage, item],
  );

  const handleOnMouseIn = useMemo(
    () =>
      onItemMouseIn
        ? (mouseEvent: React.MouseEvent) => {
            onItemMouseIn?.(mouseEvent, {
              ...item,
              selected: isCurrentPage,
            });
          }
        : undefined,
    [onItemMouseIn, isCurrentPage, item],
  );

  const handleOnMouseOut = useMemo(
    () =>
      onItemMouseOut
        ? (mouseEvent: React.MouseEvent) => {
            onItemMouseOut?.(mouseEvent, {
              ...item,
              selected: isCurrentPage,
            });
          }
        : undefined,
    [onItemMouseOut, isCurrentPage, item],
  );

  const positionUpdaters = positionUpdatersMap[depth];
  useEffect(() => {
    if (
      !isHovered ||
      !labelRef.current ||
      !positionBoxRef.current ||
      !positionUpdaters
    ) {
      return;
    }

    const { onEnter, onLeave } = positionUpdaters({
      label: labelRef.current,
      positionBox: positionBoxRef.current,
      isStretched,
    });
    onEnter();
    return onLeave;
  }, [isHovered, isStretched, positionBoxRef, positionUpdaters, item]);

  useEffect(() => {
    setIsHovered(forceHovered);
  }, [forceHovered]);

  return (
    <li
      className={cn(classes.itemWrapper, withSubItemsClassname)}
      data-testid={testIds.menuItem(depth)}
      data-item-depth={depth}
      data-is-current={isCurrentPage}
      {...(isHovered && {
        'data-hovered': true,
      })}
      {...eventListeners}
    >
      <Link
        {...link}
        className={classes.root}
        ref={labelRef}
        onClick={handleOnClick}
        onMouseEnter={handleOnMouseIn}
        onMouseLeave={handleOnMouseOut}
        onDoubleClick={handleOnDoubleClick}
        tabIndex={0}
      >
        <div className={classes.container}>
          <span className={classes.label}>{label}</span>
        </div>
      </Link>
      {children}
    </li>
  );
};
