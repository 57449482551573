import {
  withStateRefsValues,
  createComponentMapperModel,
  withCompInfo,
} from '@wix/editor-elements-integrations';
import { VideoBoxDefinition } from '@wix/thunderbolt-components';
import { VideoBoxProps } from '../VideoBox.types';

export const props = withCompInfo<
  VideoBoxProps,
  VideoBoxDefinition,
  VideoBoxProps
>()([], (_, carmiData) => {
  return carmiData;
});

export const stateRefs = withStateRefsValues(['reducedMotion']);

export default createComponentMapperModel({ props, stateRefs });
