import { withCompController } from '@wix/editor-elements-integrations';
import {
  WTwitterFollowMapperProps,
  WTwitterFollowStateRefs,
  WTwitterFollowControllerProps,
} from '../WTwitterFollow.types';

const compController = withCompController<
  WTwitterFollowMapperProps,
  WTwitterFollowControllerProps,
  never,
  WTwitterFollowStateRefs
>(({ stateValues, mapperProps }) => {
  return {
    ...mapperProps,
    currentUrl: stateValues.currentUrl,
  };
});

export default compController;
