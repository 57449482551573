import {
  withCompInfo,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';
import {
  SliderGalleryProps,
  SliderGalleryCarmiData,
  SliderGalleryCSSVars,
  SliderGalleryDefinition,
  ISliderGallerySdkData,
} from '../SliderGallery.types';

export const props = withCompInfo<
  SliderGalleryProps,
  SliderGalleryDefinition,
  SliderGalleryCarmiData
>()(
  ['skin', 'compProps', 'isMobileView'],
  ({ skin, compProps, isMobileView }, carmiData) => {
    const {
      galleryImageOnClickAction,
      goToLinkText,
      aspectRatio,
      imageMode,
      loop,
      maxSpeed,
      margin,
    } = compProps;

    return {
      skin,
      goToLinkText: goToLinkText || 'Go to link',
      aspectRatio,
      keepImageAspectRatio: imageMode === 'flexibleWidth',
      items: carmiData.itemsProps,
      imageOnClickAction: galleryImageOnClickAction,
      playOnHover: !isMobileView,
      playInLoop: loop,
      maxSpeed,
      margin,
    };
  },
);

export const css = withCompInfo<
  SliderGalleryCSSVars,
  SliderGalleryDefinition
>()(['compProps'], ({ compProps }) => {
  const { margin } = compProps;

  return {
    '--spacing': `${margin}px`,
  };
});

export const sdkData = withCompInfo<
  ISliderGallerySdkData,
  SliderGalleryDefinition
>()(['compData'], ({ compData }) => {
  return {
    imageDisplayMode: 'fill', // remove once mot used in EE
    dataId: compData.id,
  };
});

export default createComponentMapperModel({ css, sdkData, props });
