import { createComponentPreviewMapperModel } from '@wix/editor-elements-integrations';
import { composeMapper } from '@wix/editor-elements-preview-utils';
import type { PreviewWrapperProps } from '@wix/editor-elements-types/thunderboltPreview';
import { getSocialCompBlockingLayerProps } from '../../../preview-utils/socialComponents';
import {
  WTwitterFollowDefinition,
  WTwitterFollowMapperProps,
} from '../WTwitterFollow.types';
import { props as viewerPropsMapper } from './WTwitterFollow.mapper';

const props = composeMapper<
  PreviewWrapperProps<WTwitterFollowMapperProps>,
  WTwitterFollowDefinition
>()(
  viewerPropsMapper,
  { add: ['isSocialInteractionAllowed'] },
  (resolver, deps, carmiData) => {
    const viewerProps = resolver(deps, carmiData);

    return {
      ...viewerProps,
      previewWrapperProps: {
        ...getSocialCompBlockingLayerProps(deps.isSocialInteractionAllowed),
      },
    };
  },
);

export default createComponentPreviewMapperModel({
  props,
});
