import {
  createComponentMapperModel,
  withCompInfo,
} from '@wix/editor-elements-integrations';
import {
  CarouselDefinition,
  TPA3DCarouselPropsPlatformProps,
  CarouselPropsCarmiData,
  CarouselCSSVars,
} from '../TPA3DCarousel.types';
import {
  generateCss,
  generateSdkData,
  propsDependencies,
  propsResolver,
} from '../../TPAGallery/viewer/TPAGalleryMapperUtil';
import { getUrl } from '../../../tpaGallery';
import carouselHtml from './assets/Carousel.html?resource';

export const props = withCompInfo<
  TPA3DCarouselPropsPlatformProps,
  CarouselDefinition,
  CarouselPropsCarmiData
>()([...propsDependencies, 'experiments'], (data, carmiProps) => {
  const { experiments, compId, viewMode, language } = data;
  const isNewImageSizeExperimentOn =
    experiments.se_carouselGalleryImageSizeFix === true;
  const overrideHtmlUrl = getUrl(carouselHtml, compId, viewMode, language);
  const resolvedProps = propsResolver<CarouselDefinition>(
    data,
    carmiProps,
    'Carousel/Carousel.html',
  );
  return {
    ...resolvedProps,
    url: isNewImageSizeExperimentOn ? overrideHtmlUrl : resolvedProps.url,
    currentIndex: 0,
  };
});

const sdkData = generateSdkData<CarouselDefinition>();

const css = generateCss<CarouselCSSVars, CarouselDefinition>();

export default createComponentMapperModel({ css, props, sdkData });
