import {
  createComponentMapperModel,
  withCompInfo,
} from '@wix/editor-elements-integrations';
import {
  YouTubeSubscribeButtonCSSVars,
  YouTubeSubscribeButtonDefinition,
  YouTubeSubscribeButtonProps,
} from '../YouTubeSubscribeButton.types';
import { Layout } from '../documentManagement/YouTubeSubscribeButton.schemaTypes';

const getSize = (layout: Layout) => {
  const [width, height] = layout === 'full' ? [212, 55] : [145, 33];
  return {
    width: `${width}px`,
    height: `${height}px`,
  };
};

export const props = withCompInfo<
  YouTubeSubscribeButtonProps,
  YouTubeSubscribeButtonDefinition
>()(
  ['compData', 'compProps'],
  ({ compData, compProps: { layout, theme } }) => ({
    channel: compData.youtubeChannelId,
    layout,
    theme: theme === 'dark' ? 'dark' : 'default',
  }),
);

export const css = withCompInfo<
  YouTubeSubscribeButtonCSSVars,
  YouTubeSubscribeButtonDefinition
>()(['compProps'], ({ compProps: { layout } }) => getSize(layout));

export default createComponentMapperModel({ css, props });
