import * as React from 'react';
import classNames from 'classnames';
import { IPolicyLink } from '../../SignUpDialog/SignUpDialog.types';
import { closeDialog } from '../../SignUpDialog/viewer/constants';
import Link from '../../Link/viewer/Link';
import style from './style/style.scss';

export const PolicyLink: React.FC<IPolicyLink> = ({
  onCloseDialogCallback,
  privacyLinkLabel,
  link,
  testId,
  className,
}) => {
  return (
    <Link
      className={classNames(style.dialogLink, className)}
      href={link.href}
      target={link.target}
      rel="noreferrer"
      dataTestId={testId}
      onClick={closeDialog(link.target, onCloseDialogCallback)}
    >
      {privacyLinkLabel}
    </Link>
  );
};
