export const translationFeature = 'SM';

export const ConfirmationEmailDialogTranslationKeys = {
  title: 'SMEmailVerification_EmailConfirmationSent',
  signUpTitle: 'SMEmailVerification_Title',
  subtitle: 'SMEmailVerification_CheckYourInbox',
  signUpSubtitle: 'SMEmailVerification_Thanks',
  signUpSecondarySubtitle: 'SMEmailVerification_ClickTheLink',
  description: 'SMEmailVerification_DidntGetEmail',
  descriptionSecondary: 'SMEmailVerification_CheckSpam',
  descriptionLink: 'SMEmailVerification_ResendLink',
};

export const ConfirmationEmailDialogTranslationFallback = {
  title: 'We’ve Sent a Confirmation Email',
  signUpTitle: 'Confirm Your Email To Get Started',
  subtitle: 'Check your inbox',
  signUpSubtitle: 'Thanks for signing up! We sent you a confirmation email.',
  signUpSecondarySubtitle:
    'Click the link in the email to get to your account.',
  description: 'Didn’t get the email?',
  descriptionSecondary: 'Check your spam folder or ',
  descriptionLink: 'click here to resend confirmation email.',
};

export const TestIds = {
  signUpSubtitle: 'sign-up-subtitle',
  signUpSecondarySubtitle: 'sign-up-secondary-subtitle',
  description: 'description',
  secondaryDescription: 'secondary-description',
  resendConfirmationEmailButton: 'resend-confirmation-email',
} as const;
