import React, { useRef, useImperativeHandle } from 'react';
import { getDataAttributes } from '@wix/thunderbolt-elements/commons/utils';
import { useMSBStates } from '@wix/thunderbolt-elements/components/MultiStateBox/useMSBStates';
import { keyCodes } from '@wix/thunderbolt-elements/commons/a11y';
import ResponsiveBoxSkin from '@wix/thunderbolt-elements/components/Container/ResponsiveBox';
import {
  ITabsProps,
  ITabsImperativeActions,
  ITabsListItem,
} from '../Tabs.types';
import { TabItemClickedEventType } from '../constants';
import TabsList from './TabsList/TabsList';
import { st, classes } from './style/Tabs.component.st.css';
import TabWrapper from './TabWrapper';

const getDefaultContainerProps = (id: string) => ({
  // TODO - wire correctly
  containerLayoutClassName: `${id}-container`,
  // TODO - wire correctly
  overlowWrapperClassName: `${id}-overflow-wrapper`,
  // TODO - wire correctly
  hasOverflow: true,
});

const getTabId = (
  tabItems: Array<ITabsListItem>,
  tabElement: React.ReactElement,
) => {
  const tabItem = tabItems.find(item => item.id === tabElement.props.id);
  return tabItem?.tabId ?? '';
};

const Tabs: React.ForwardRefRenderFunction<
  ITabsImperativeActions,
  ITabsProps
> = (props, ref) => {
  const {
    id,
    currentTabId,
    tabItems,
    className,
    stylableClassName,
    children,
    setCurrentTabId,
    onTabItemClicked,
    onChange,
    hasResponsiveLayout,
    containerProps,
    containerRootClassName,
  } = props;

  useImperativeHandle(ref, () => ({
    setCurrentTabId,
  }));

  const tabsToRender = useMSBStates({
    children,
    getStateId: tabElement => getTabId(tabItems, tabElement),
    selectedStateId: currentTabId,
    onChange,
  });

  const activeMenuItemRef = useRef<HTMLDivElement>(null);
  const currentTabRef = useRef<HTMLDivElement>(null);

  const handleTabItemClick = (tabId: string, uniqueId: string) => {
    setCurrentTabId(tabId);
    onTabItemClicked?.({ type: TabItemClickedEventType, tabId: uniqueId });
    currentTabRef.current?.focus({ preventScroll: true });
  };

  const handleKeyboardNav = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.keyCode === keyCodes.escape) {
      activeMenuItemRef.current?.focus({ preventScroll: true });
    }
  };

  const content = (
    <div className={st(classes.root, stylableClassName)}>
      <TabsList
        className={classes.tabsList}
        currentTabId={currentTabId}
        tabItems={tabItems}
        onTabItemClick={handleTabItemClick}
        activeMenuItemRef={activeMenuItemRef}
      />
      <div
        className={classes.multiStateBoxWrapper}
        onKeyDown={handleKeyboardNav}
      >
        {tabsToRender.map(tabElement => (
          <TabWrapper
            tabElement={tabElement as React.ReactElement}
            currentTabRef={currentTabRef}
            isCurrentTab={
              getTabId(tabItems, tabElement as React.ReactElement) ===
              currentTabId
            }
          />
        ))}
      </div>
    </div>
  );

  return hasResponsiveLayout ? (
    <ResponsiveBoxSkin
      {...getDataAttributes(props)}
      className={className}
      hasPlatformClickHandler={false}
      containerRootClassName={containerRootClassName}
      containerProps={containerProps || getDefaultContainerProps(id)}
      id={id}
    >
      {() => content}
    </ResponsiveBoxSkin>
  ) : (
    <div {...getDataAttributes(props)} className={className} id={id}>
      {content}
    </div>
  );
};

export default React.forwardRef(Tabs);
