import * as React from 'react';
import classNames from 'classnames';
import { IPinterestFollowProps } from '../PinterestFollow.types';
import { TestIds } from '../constants';
import Link from '../../Link/viewer/Link';
import { getDataAttributes } from '../../../core/commons/utils';
import style from './style/PinterestFollow.scss';

const PinterestFollow: React.FC<IPinterestFollowProps> = props => {
  const { id, label, className, urlChoice, onMouseEnter, onMouseLeave } = props;

  return (
    <div
      id={id}
      {...getDataAttributes(props)}
      className={classNames(className, style.root)}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <Link
        className={style.followButton}
        title={label}
        href={urlChoice}
        target="_blank"
        rel="noopener noreferrer"
      >
        <div
          className={style.followButtonTag}
          data-testid={TestIds.labelWrapper}
        >
          {label}
        </div>
        <div className={style.icon}></div>
      </Link>
    </div>
  );
};

export default PinterestFollow;
