import {
  withCompInfo,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';
import { getRowHeight } from '../../commons/gridCalculations';
import { legacyTransitionTypesMap } from '../../commons/constants';
import {
  IPaginatedGridGalleryMapperProps,
  PaginatedGridGalleryCarmiData,
  PaginatedGridGalleryCarmiCss,
  PaginatedGridGalleryCSSVars,
  PaginatedGridGalleryDefinition,
  PaginatedGridGallerySdkData,
} from '../PaginatedGridGallery.types';

const MIN_HEIGHT = 70;

export const props = withCompInfo<
  IPaginatedGridGalleryMapperProps,
  PaginatedGridGalleryDefinition,
  PaginatedGridGalleryCarmiData
>()(['skin', 'compProps'], ({ skin, compProps }, carmiData) => {
  const {
    maxRows: rows,
    numCols: columns,
    galleryImageOnClickAction,
    autoplay: autoPlay,
    showNavigation,
    showAutoplay,
    showCounter,
    goToLinkText,
  } = compProps;
  const hasTransition = compProps.transition !== 'none';
  const transition =
    legacyTransitionTypesMap[compProps.transition] || 'NoTransition';
  const transitionDuration = compProps.transDuration * 1000;
  const autoPlayInterval =
    compProps.autoplayInterval * 1000 +
    (hasTransition ? transitionDuration : 0);

  return {
    skin,
    rows,
    columns,
    items: carmiData.itemsProps,
    imageOnClickAction: galleryImageOnClickAction,
    transition,
    transitionDuration,
    autoPlay,
    autoPlayInterval,
    showNavigation,
    showAutoplay,
    showCounter,
    reducedMotion: false,
    currentPage: 0,
    goToLinkText: goToLinkText || 'Go to link',
  };
});

const skinBottomPaddingMap = {
  PaginatedGridDefaultSkin: 0,
  PaginatedGridOverlay: 0,
  PaginatedGridArrowsOutside: 20,
  PaginatedGridRibbonArrows: 20,
  PaginatedGridTextBottom: 60,
} as const;

export const css = withCompInfo<
  PaginatedGridGalleryCSSVars,
  PaginatedGridGalleryDefinition,
  PaginatedGridGalleryCarmiCss
>()(
  ['skin', 'compProps', 'compLayout'],
  ({ skin, compProps, compLayout }, carmiCss) => {
    const { height = MIN_HEIGHT } = compLayout;
    const {
      margin,
      maxRows: rows,
      numCols: columns,
      transDuration,
    } = compProps;
    const galleryBottomPadding = skinBottomPaddingMap[skin];

    const rowHeight = getRowHeight(height, margin, rows, galleryBottomPadding);

    const galleryWidthRelativeToItem = `calc(100% * ${columns} + var(--spacing) * ${columns})`;
    const galleryHeightRelativeToItem = `calc(100% * ${rows} + var(--spacing) * ${rows})`;

    return {
      ...carmiCss,
      '--number-of-columns': columns,
      '--spacing': `${margin}px`,
      '--row-height': `${rowHeight}px`, // todo - remove after updating EE
      '--transition-duration': `${transDuration * 1000}ms`,
      '--horiz-translate-item-pct': galleryWidthRelativeToItem, // todo - remove after updating EE
      '--vert-translate-item-pct': galleryHeightRelativeToItem, // todo - remove after updating EE
      '--gallery-bottom-padding': `${galleryBottomPadding}px`,
    };
  },
);

export const sdkData = withCompInfo<
  PaginatedGridGallerySdkData,
  PaginatedGridGalleryDefinition
>()(['compData'], ({ compData }) => {
  return {
    imageDisplayMode: 'fill',
    dataId: compData.id,
  };
});

export default createComponentMapperModel({ props, sdkData, css });
