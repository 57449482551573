import { useState } from 'react';

import { isBrowser } from '../../../../../../core/commons/utils';
import { SuggestionItemProps } from '../../Suggestion.types';

type SuggestionItem = SuggestionItemProps['item'];

export enum ThumbnailState {
  Loading,
  Loaded,
  Empty,
  Hidden,
}

export function useThumbnailState(item: SuggestionItem) {
  const [thumbnailState, setThumbnailState] = useState<ThumbnailState>(
    getInitialState(item.image),
  );

  const onError = () => setThumbnailState(ThumbnailState.Empty);
  const onLoad = () => setThumbnailState(ThumbnailState.Loaded);

  return { onError, onLoad, thumbnailState };
}

function getInitialState(image: SuggestionItem['image']): ThumbnailState {
  if (!image) {
    return ThumbnailState.Hidden;
  } else if (image.name) {
    return isBrowser() ? ThumbnailState.Loading : ThumbnailState.Loaded;
  } else {
    return ThumbnailState.Empty;
  }
}
