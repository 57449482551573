import * as React from 'react';
import classnames from 'classnames';
import { IFormContainerResponsiveProps } from '../../../FormContainer.types';
import { FormContainerRoot } from '../../shared/FormContainerRoot';
import ResponsiveContainer from '../../../../../thunderbolt-core-components/ResponsiveContainer/viewer/ResponsiveContainer';
import style from './ResponsiveSkin.scss';

const ResponsiveSkin: React.FC<IFormContainerResponsiveProps> = props => {
  const {
    id,
    containerProps,
    onSubmit,
    children,
    containerRootClassName,
    onMouseEnter,
    onMouseLeave,
  } = props;
  const responsiveContainerProps = {
    id,
    ...containerProps,
    children,
  };
  const classNames = classnames(style.root, containerRootClassName);
  return (
    <FormContainerRoot
      id={id}
      className={classNames}
      onSubmit={onSubmit}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <ResponsiveContainer {...responsiveContainerProps} />
    </FormContainerRoot>
  );
};

export default ResponsiveSkin;
