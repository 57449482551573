export const TestIds = {
  submitImg: 'submit-image',
  trackingPixel: 'tracking-pixel-image',
} as const;

export const BUY_BUTTON_LABEL = 'Buy now via PayPal';
export const DONATE_BUTTON_LABEL = 'Donate via PayPal';

export const LOCALES: Record<
  string,
  { image: string; cc: string; pixel: string }
> = {
  da: { image: 'da_DK', cc: '/DK', pixel: 'da_DK' },
  de: { image: 'de_DE/DE', cc: '', pixel: 'de_DE' },
  en: { image: 'en_US', cc: '', pixel: 'en_US' },
  es: { image: 'es_ES/ES', cc: '', pixel: 'es_ES' },
  fr: { image: 'fr_FR/FR', cc: '', pixel: 'fr_FR' },
  it: { image: 'it_IT/IT', cc: '', pixel: 'it_IT' },
  ja: { image: 'ja_JP/JP', cc: '', pixel: 'ja_JP' },
  jp: { image: 'ja_JP/JP', cc: '', pixel: 'ja_JP' },
  nl: { image: 'nl_NL/NL', cc: '', pixel: 'nl_NL' },
  no: { image: 'no_NO', cc: '/NO', pixel: 'en_US' },
  pl: { image: 'pl_PL/PL', cc: '', pixel: 'pl_PL' },
  pt: { image: 'pt_BR', cc: '/BR', pixel: 'pt_BR' },
  ru: { image: 'ru_RU', cc: '/RU', pixel: 'en_US' },
  sv: { image: 'sv_SE', cc: '/SE', pixel: 'sv_SE' },
  tr: { image: 'tr_TR', cc: '', pixel: 'tr_TR' },
};
