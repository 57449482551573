import { withCompController } from '@wix/editor-elements-integrations/src/thunderbolt';
import type {
  IVerticalAnchorsMenuControllerProps,
  IVerticalAnchorsMenuMapperProps,
  IVerticalAnchorsMenuStateRefValues,
  IVerticalAnchorsMenuProps,
} from '../VerticalAnchorsMenu.types';

export default withCompController<
  IVerticalAnchorsMenuMapperProps,
  IVerticalAnchorsMenuControllerProps,
  IVerticalAnchorsMenuProps,
  IVerticalAnchorsMenuStateRefValues
>(({ stateValues, mapperProps }) => {
  const { currentUrl } = stateValues;
  return { ...mapperProps, currentPageHref: currentUrl };
});
