import React, { useContext, useState } from 'react';
import classnames from 'classnames';
import { ToolbarChildrenProps } from 'draft-js-inline-toolbar-plugin';
import { usePopper } from '../../../../../../providers/usePopper/usePopper';
import { useClickOutside } from '../../../../../../providers/useClickOutside/useClickOutside';
import styles from '../button.scss';
import StylesContext from '../../../StylesContext';
import { TestIds } from '../../../../constants';
import AlignButton from './AlignButton';
import {
  AlignButtonType,
  alignmentButtons,
  alignTypes,
  getActiveAlignment,
  preventBubblingUp,
  getStateWithNewTextAlign,
} from './common';

export default (props: ToolbarChildrenProps) => {
  const [showOptions, setShowOptions] = useState(false);

  const {
    ref: popperSourceElem,
    setRef: setPopperSourceElem,
    setPopper: setPopperTargetElem,
    styles: stylesPopper,
    attributes,
  } = usePopper<HTMLButtonElement>({
    placement: 'bottom',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, -25],
        },
      },
    ],
  });

  const stylesContext = useContext(StylesContext);
  const { setEditorState, getEditorState } = props;

  const hideOptions = () => {
    setShowOptions(false);
  };

  useClickOutside([popperSourceElem], hideOptions);

  const changeTextAlign = (
    e: React.MouseEvent<HTMLOrSVGElement>,
    newAlignment: AlignButtonType,
  ) => {
    preventBubblingUp(e);

    const newState = getStateWithNewTextAlign(newAlignment, getEditorState());
    hideOptions();
    setEditorState(newState);
  };

  const onClickHandler = (event: React.MouseEvent<any>) => {
    preventBubblingUp(event);
    if (event.currentTarget === popperSourceElem) {
      setShowOptions(!showOptions);
    }
  };

  const activeAlignment = getActiveAlignment(getEditorState());

  return (
    <div className={styles.buttonContainer} onMouseDown={preventBubblingUp}>
      <button
        type="button"
        ref={setPopperSourceElem}
        className={classnames(styles.button, {
          [styles.hovered]: stylesContext.hovered,
          [styles.focused]: stylesContext.focused,
          [styles.error]: stylesContext.error,
        })}
        onClick={onClickHandler}
        data-testid={TestIds.alignButton}
      >
        <div className={styles.buttonContent}>
          <AlignButton>{alignmentButtons[activeAlignment]}</AlignButton>
        </div>
      </button>
      <div
        ref={setPopperTargetElem}
        style={{
          ...stylesPopper.popper,
          visibility: showOptions ? 'visible' : 'hidden',
        }}
        {...attributes.popper}
        onClick={onClickHandler}
      >
        <div
          className={classnames(styles.alignMenuContainer, {
            [styles.hovered]: stylesContext.hovered,
            [styles.focused]: stylesContext.focused,
            [styles.error]: stylesContext.error,
          })}
        >
          {alignTypes.map(alignType => {
            return (
              <AlignButton
                key={alignType}
                onClick={e => changeTextAlign(e, alignType)}
                selected={activeAlignment === alignType}
                dataTestId={`${TestIds.alignButton}-${alignType}`}
              >
                {alignmentButtons[alignType]}
              </AlignButton>
            );
          })}
        </div>
      </div>
    </div>
  );
};
