import {
  createComponentMapperModel,
  withCompInfo,
} from '@wix/editor-elements-integrations';
import {
  RatingsInputDefinition,
  IRatingsInputMapperProps,
  RatingsInputCSSVars,
  RatingsInputCssCarmiData,
  RatingsInputPropsCarmiData,
  RatingsInputProperties,
  RatingsInputLabelPosition,
} from '../RatingsInput.types';
import { experimentNames } from './constants';

export const props = withCompInfo<
  IRatingsInputMapperProps,
  RatingsInputDefinition,
  RatingsInputPropsCarmiData
>()(
  ['compData', 'compProps', 'isMobileView', 'experiments'],
  ({ compData, compProps, isMobileView, experiments }, carmiProps) => {
    const a11yExperiment =
      experiments[experimentNames.INPUT_ELEMENTS_A11Y] === true &&
      !isMobileView &&
      !!compData.titleText;

    return {
      ...carmiProps,
      rtl: compProps.direction === 'rtl',
      labelPosition:
        isMobileView && compProps.labelPosition === 'side'
          ? 'top'
          : compProps.labelPosition,
      showTitle: compProps.showTitle && !a11yExperiment,
      title: compData.titleText,
      showLabels: compProps.showLabels,
      value: compData.value,
      labels: compData.labels,
      isDisabled: compProps.isDisabled,
      required: compProps.required,
      a11yLabelPosition: compProps.a11yLabelPosition,
      shapeSize: compProps.shapeSize,
      a11yExperiment,
    };
  },
);

const getIconsMarginCss = (
  compProps: RatingsInputProperties,
  labelPosition: RatingsInputLabelPosition,
) => {
  const { shapeSize, shapeSpacing } = compProps;
  const margin = `${
    labelPosition === 'side' ? Math.max(15, shapeSpacing) : shapeSize / 2
  }px`;

  if (labelPosition === 'top') {
    return { '--iconsMarginTop': margin };
  } else if (labelPosition === 'bottom') {
    return { '--iconsMarginBottom': margin };
  }

  if (compProps.direction === 'rtl') {
    return { '--iconsMarginLeft': margin };
  }
  return { '--iconsMarginRight': margin };
};

const getNotSideCss = (compProps: RatingsInputProperties, iconSize: string) => {
  const inputAlignment = {
    center: 'center',
    left: compProps.direction === 'rtl' ? 'flex-end' : 'flex-start',
    right: compProps.direction === 'rtl' ? 'flex-start' : 'flex-end',
  };

  return {
    '--labelsContainerWidth': iconSize,
    '--inputAlignItems': inputAlignment[compProps.labelAlignment],
    '--inputFlexDirection': 'column',
  };
};

const getA11yCss = (compProps: RatingsInputProperties) => {
  const { a11yLabelPosition, a11yLabelAlignment, labelPosition } = compProps;
  const css: Partial<RatingsInputCSSVars> = {
    '--a11yFlexDirection': a11yLabelPosition === 'side' ? 'row' : 'column',
    '--a11yLabelAlign': a11yLabelAlignment,
  };

  if (a11yLabelPosition === 'top') {
    css['--a11yLabelMargin'] = `0 0 ${compProps.labelMargin || 0}px 0`;
    if (a11yLabelAlignment === 'right') {
      css['--a11yLabelPadding'] = `0 ${compProps.labelPadding || 0}px 0 0`;
    } else if (a11yLabelAlignment === 'left') {
      css['--a11yLabelPadding'] = `0 0 0 ${compProps.labelPadding || 0}px`;
    }
  } else if (a11yLabelPosition === 'side') {
    css['--a11yLabelAlign'] = compProps.direction === 'rtl' ? 'right' : 'left';
    css['--inputAlignItems'] =
      labelPosition === 'top' ? 'flex-end' : 'flex-start';
  }

  return css;
};

export const css = withCompInfo<
  RatingsInputCSSVars,
  RatingsInputDefinition,
  RatingsInputCssCarmiData
>()(
  ['compProps', 'compData', 'isMobileView', 'experiments'],
  ({ compProps, compData, isMobileView, experiments }, carmiCss) => {
    const {
      showTitle,
      showLabels,
      shapeSize,
      shapeSpacing,
      labelAlignment,
      direction,
    } = compProps;
    const a11yExperiment =
      experiments[experimentNames.INPUT_ELEMENTS_A11Y] === true &&
      !isMobileView &&
      !!compData.titleText;
    const iconSize = `${shapeSize * 5 + shapeSpacing * 4}px`;
    const labelPosition =
      isMobileView && compProps.labelPosition === 'side'
        ? 'top'
        : compProps.labelPosition;
    const rtl = direction === 'rtl';
    const labelsContainerAlignment = {
      top: 'flex-end',
      bottom: 'flex-start',
      side: 'center',
    };
    let cssVars: RatingsInputCSSVars = {
      ...carmiCss,
      '--inputAlignItems': 'center',
      '--inputFlexDirection': 'row',
      '--iconsMinWidth': iconSize,
      '--iconsOrder': labelPosition === 'top' ? 2 : 0,
      '--labelsContainerAlignItems': labelsContainerAlignment[labelPosition],
      '--inputTextAlign': `${labelAlignment}`,
      '--iconSize': `${shapeSize}px`,
      [`--iconPadding${rtl ? 'Left' : 'Right'}`]: `${shapeSpacing}px`,
      '--direction': direction,
      height: 'auto',
    };

    if (labelPosition !== 'side') {
      cssVars = {
        ...cssVars,
        ...getNotSideCss(compProps, iconSize),
      };
    }

    if ((showTitle && !!compData.titleText && !a11yExperiment) || showLabels) {
      cssVars = {
        ...cssVars,
        ...getIconsMarginCss(compProps, labelPosition),
      };
    }

    if (a11yExperiment) {
      cssVars = {
        ...cssVars,
        ...getA11yCss(compProps),
      };
    }

    return cssVars;
  },
);

export default createComponentMapperModel({ props, css });
