import {
  withCompInfo,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';
import {
  ToggleSwitchDefinition,
  IToggleSwitchMapperProps,
  ToggleSwitchCSSVars,
  ToggleSwitchAlignment,
} from '../ToggleSwitch.types';
import { getLabelPadding } from '../../../core/commons/inputUtils';

function concatPercent(value: number): string {
  return value !== 0 ? `${value}%` : value.toString();
}

function isOuterKnob(knobSize: number, trackHeight: number): boolean {
  return knobSize > trackHeight;
}

function innerKnobWidth(
  knobSize: number,
  trackHeight: number,
  initialKnobWidth?: number,
): number {
  if (typeof initialKnobWidth !== 'undefined') {
    return 100 - initialKnobWidth - (trackHeight - knobSize) / 4;
  }
  return (trackHeight - knobSize) / 4;
}

function outerKnobWidth(initialKnobWidth: number): number {
  return 100 - initialKnobWidth;
}

function calcUncheckedLeftValue(
  knobSize: number,
  trackHeight: number,
  knobWidth: number,
  alignment: ToggleSwitchAlignment,
): number {
  let result: number;
  if (alignment === 'right') {
    result = isOuterKnob(knobSize, trackHeight)
      ? outerKnobWidth(knobWidth)
      : innerKnobWidth(knobSize, trackHeight, knobWidth);
  } else {
    result = isOuterKnob(knobSize, trackHeight)
      ? 0
      : innerKnobWidth(knobSize, trackHeight);
  }
  return result;
}

function calcCheckedLeftValue(
  knobSize: number,
  trackHeight: number,
  knobWidth: number,
  alignment: ToggleSwitchAlignment,
): number {
  let result: number;
  if (alignment === 'right') {
    result = isOuterKnob(knobSize, trackHeight)
      ? 0
      : innerKnobWidth(knobSize, trackHeight);
  } else {
    result = isOuterKnob(knobSize, trackHeight)
      ? outerKnobWidth(knobWidth)
      : innerKnobWidth(knobSize, trackHeight, knobWidth);
  }
  return result;
}

export const props = withCompInfo<
  IToggleSwitchMapperProps,
  ToggleSwitchDefinition
>()(
  ['compData', 'compProps', 'hasResponsiveLayout'],
  ({ compData, compProps, hasResponsiveLayout }) => {
    const { checked, value } = compData;
    const { isDisabled, label } = compProps;
    return {
      hasResponsiveLayout,
      isDisabled,
      checked,
      value,
      label,
    };
  },
);

export const css = withCompInfo<ToggleSwitchCSSVars, ToggleSwitchDefinition>()(
  ['compProps', 'compLayout', 'hasResponsiveLayout'],
  ({ compProps, compLayout, hasResponsiveLayout }) => {
    const {
      displayKnobIcons,
      trackHeight,
      knobSize,
      alignment,
      label,
      labelMargin,
      switchHeight,
    } = compProps;
    const knobWidth = knobSize / 2;

    const cssVars: ToggleSwitchCSSVars = {
      '--toggleIconDisplay': displayKnobIcons ? 'block' : 'none',
      '--innerLabelHeight': concatPercent(knobSize),
      '--innerLabelWidth': concatPercent(knobWidth),
      '--outerLabelHeight': concatPercent(trackHeight),
      '--labelMovementRangeChecked': concatPercent(
        calcCheckedLeftValue(knobSize, trackHeight, knobWidth, alignment),
      ),
      '--labelMovementRangeUnchecked': concatPercent(
        calcUncheckedLeftValue(knobSize, trackHeight, knobWidth, alignment),
      ),
      '--textAlign': alignment,
    };

    if (label) {
      return {
        ...cssVars,
        ...(hasResponsiveLayout ? {} : { height: 'auto' }),
        '--switchHeight': `${switchHeight}px`,
        '--switchWidth': `${switchHeight * 2}px`,
        '--labelPadding': getLabelPadding(compProps),
        '--labelMarginBottom': `${labelMargin}px`,
      };
    }

    const { height, width } = compLayout;
    const wrongAspectRatio = height && width ? height * 2 !== width : false;

    if (height && wrongAspectRatio && !hasResponsiveLayout) {
      return {
        ...cssVars,
        height: `${switchHeight || height}px`,
        width: `${(switchHeight || height) * 2}px`,
      };
    }

    return cssVars;
  },
);

export default createComponentMapperModel({ css, props });
