import { createComponentPreviewMapperModel } from '@wix/editor-elements-integrations';
import { composeMapper } from '@wix/editor-elements-preview-utils';
import type { PreviewWrapperProps } from '@wix/editor-elements-types/thunderboltPreview';
import { getSocialCompBlockingLayerProps } from '../../../preview-utils/socialComponents';
import {
  PayPalButtonDefinition,
  PayPalButtonMapperProps,
} from '../PayPalButton.types';
import { props as viewerPropsMapper } from './PayPalButton.mapper';

const props = composeMapper<
  PreviewWrapperProps<PayPalButtonMapperProps>,
  PayPalButtonDefinition
>()(
  viewerPropsMapper,
  { add: ['isSocialInteractionAllowed'] },
  (resolver, deps, carmiData) => {
    const viewerProps = resolver(deps, carmiData);

    return {
      ...viewerProps,
      previewWrapperProps: {
        ...getSocialCompBlockingLayerProps(
          deps.isSocialInteractionAllowed,
          true,
        ),
      },
    };
  },
);

export default createComponentPreviewMapperModel({
  props,
});
