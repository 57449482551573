import * as React from 'react';
import classNames from 'classnames';
import { SkinNavButtonProps } from '../../SliderGallery.types';
import { TestIds } from '../../constants';

const SliderGalleryDefaultButton: React.FC<SkinNavButtonProps> = props => {
  const {
    ariaLabel,
    extraClassName,
    direction,
    mouseEnter,
    mouseLeave,
    touchStart,
    keyDown,
    keyUp,
    ariaPressed,
    describedById,
  } = props;

  return (
    <button
      data-testid={
        direction === 'left'
          ? TestIds.swipeLeftHitArea
          : TestIds.swipeRightHitArea
      }
      className={classNames(extraClassName)}
      type="button"
      aria-label={ariaLabel}
      onMouseEnter={mouseEnter}
      onMouseLeave={mouseLeave}
      onTouchStart={touchStart}
      onKeyDown={keyDown}
      onKeyUp={keyUp}
      aria-pressed={ariaPressed}
      aria-describedby={describedById}
    />
  );
};

export default SliderGalleryDefaultButton;
