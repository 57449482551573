import * as React from 'react';
import {
  IImageZoomGalleryProps,
  ImageZoomImages,
} from '../TouchMediaZoom.types';

import { getSelectedImageIndex } from '../../ImageZoom/viewer/utils';
import { getDataAttributes } from '../../../../core/commons/utils';
import TouchMediaZoomBase from './TouchMediaZoomBase';

const TouchMediaZoom: React.FC<IImageZoomGalleryProps> = props => {
  const {
    id,
    className,
    uri,
    alt,
    name,
    width,
    height,
    displayMode,
    onClose,
    images,
    dataItemId: initialDataItemId = '',
    onImageChange,
  } = props;
  const imageProps = {
    id,
    containerId: id,
    uri,
    alt,
    name,
    width,
    height,
    displayMode,
  };

  const [dataItemId, setDataItemId] = React.useState(initialDataItemId);
  const imagesList = images ?? ([imageProps] as ImageZoomImages);
  const onImageChangeCallback = (newDataItemId: string) => {
    setDataItemId(newDataItemId);
    // TODO - make onImageChange required after TB side is merged
    onImageChange?.(newDataItemId);
  };

  return (
    <TouchMediaZoomBase
      id={id}
      {...getDataAttributes(props)}
      className={className}
      images={imagesList}
      selectedImageIndex={getSelectedImageIndex(imagesList, dataItemId)}
      onImageChangeCallback={onImageChangeCallback}
      onClose={onClose}
    />
  );
};

export default TouchMediaZoom;
